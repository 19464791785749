import {Fragment, useState, memo, useEffect} from "react";
import {Tab} from "@headlessui/react";

// Redux
import {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {setActualTab} from "store/user/userSlice";
import {
  setTabIndex,
  setLastVisitedPage,
  setSubMerchantTabIndex,
} from "store/tabs/tabsSlice";
import {
  setFilters,
  setFiltersLastSearch,
  setLimit,
  setLoyaltyProgram,
  setPage,
  setSorting,
  setStatus,
  setURLFilters,
  setURLSearch,
} from "store/filters/filtersSlice";
// import { setRefetch } from "store/table/currentTableDataSlice";

interface TabsTypes {
  children: any;
  tabsName?: string[] | string;
  setSelectedTab?: Function;
  primaryTabs?: boolean;
  secondaryTabs?: boolean;
  updateOn?: any;
  setCurrentTab?: number;
}

const tabs = [
  "Approved",
  "New Items",
  "Need to Approve",
  "Archived",
  "Actual",
  "Archived",
];

function Tabs({
  children,
  tabsName,
  primaryTabs,
  secondaryTabs,
  setSelectedTab,
  updateOn,
  setCurrentTab,
}: TabsTypes) {
  const dispatch = useDispatch();
  const tabsData = useSelector((state: RootState) => state.tabs);

  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (tabsData.tabs.tabIndex && primaryTabs)
      setSelectedIndex(+tabsData.tabs.tabIndex);
  }, []);

  useEffect(() => {
    if (tabsData.tabs.subMerchantTabIndex && secondaryTabs && updateOn) {
      setSelectedIndex(+tabsData.tabs.subMerchantTabIndex || 0);
    }
  }, [updateOn]);

  const resetValuesBtwTab = () => {
    dispatch(setPage("&page=1"));
    dispatch(setLimit("&limit=10"));
    dispatch(setURLFilters(""));
    dispatch(setURLSearch(""));
    dispatch(setFilters([]));
    dispatch(setFiltersLastSearch([]));
    dispatch(setStatus([]));
    dispatch(setSorting("ordering=id"));
    dispatch(setLoyaltyProgram(null));
    if (primaryTabs) dispatch(setLastVisitedPage(""));
  };

  const handleSelectTab = (tab: string, index: number, selected: boolean) => {
    if (tabs.includes(tab)) dispatch(setTabIndex(index.toString()));

    if (
      tab.includes("Details") ||
      tab.includes("(") ||
      tab.includes("Activity")
    ) {
      dispatch(setSubMerchantTabIndex(index.toString()));
    }
    tab && setSelectedTab && setSelectedTab(tab);
    dispatch(setActualTab(tab));
    setSelectedIndex(index);

    if (
      (tab === "Archived" ||
        tab === "Actual" ||
        tab === "Approved" ||
        tab === "New Items" ||
        tab === "Need to Approve") &&
      !selected
    ) {
      resetValuesBtwTab();
    }
  };

  const handleRenderTab = () => {
    if (typeof tabsName === "string" && tabsName === "default") {
      dispatch(setActualTab(tabsName));
      return <></>;
    }
    if (Array.isArray(tabsName)) {
      return tabsName?.map((tab, index) => (
        <Tab key={tab} as={Fragment}>
          {({selected}) => (
            <button
              className={`
                       text-sm [&:not(:first-child)]:ml-3 ease-in-out transition duration-100 hover:border-b-4 hover:border-primary-purple p-1 outline-none
                       ${
                         selected
                           ? "text-black border-b-4 border-primary-purple font-medium "
                           : "text-slate-400"
                       }
                       `}
              onClick={() => handleSelectTab(tab, index, selected)}
            >
              {tab}
            </button>
          )}
        </Tab>
      ));
    } else return <></>;
  };

  return (
    <Tab.Group selectedIndex={setCurrentTab ? setCurrentTab : selectedIndex}>
      <Tab.List>{handleRenderTab()}</Tab.List>
      <Tab.Panels>
        {Array.isArray(children) ? (
          children.map((children: any, key) => (
            <Tab.Panel key={key}>{children}</Tab.Panel>
          ))
        ) : (
          <Tab.Panel>{children}</Tab.Panel>
        )}
      </Tab.Panels>
    </Tab.Group>
  );
}

export default memo(Tabs);
