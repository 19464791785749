import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Link, useParams} from "react-router-dom";
import {toast} from "react-toastify";

// Components
import Tabs from "components/Common/Tabs";
import Title from "components/Common/Title";
import Button from "components/Common/Button";
import Badge from "components/Badge";
import Modal from "components/Modal";
import ArchiveModal from "components/Modal/ArchiveModal";
import RecoveryModal from "components/Modal/RecoveryModal";

// Pages
import MerchantDetails from "./Details";
import MerchantItems from "./Items";
import MerchantGrandPrize from "./GrandPrize";
import MerchantEmployee from "./Employee";
import MerchantActivity from "./Activity";

// Redux
import {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {
  useApproveChangesMerchantMutation,
  useChangeStatusMerchantMutation,
  useGetMerchantByIDQuery,
} from "store/api/merchantsApi";
import {setSubMerchantTabIndex} from "store/tabs/tabsSlice";

// Icons
import {ArrowBack} from "assets/Arrows";
import Archive from "assets/Archive";
import Publish from "assets/Publish";
import Recovery from "assets/Recovery";

// Utils
import {
  TABLE_MERCHANT_ITEMS_HEADER,
  TABLE_MERCHANT_ITEMS_SUBHEADER,
  TABLE_MERCHANT_EMPLOYEE_LIST,
  TABLE_MERCHANT_ACTIVITY,
  TABLE_MERCHANT_GRAND_PRIZE_ITEMS_HEADER,
  TABLE_MERCHANT_LOYALITY_HEADER,
} from "utils/merchantHeaders";
import routes from "utils/routesByRole";
import Save from "assets/Save";
import Loyality from "./Loyality";
import {
  useApproveChangesMutation,
  useGetDeclineReasonsQuery,
} from "store/api/itemApi";
import {Dialog} from "@headlessui/react";
import Select from "components/Common/Input/Select";
import Label from "components/Common/Label";
import Textarea from "components/Common/Textarea";
import {transformDate} from "utils/transformDate";

function MerchantProfile() {
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modal = useSelector((state: RootState) => state.modal);
  const user = useSelector((state: RootState) => state.user.user.user);
  const tabsData = useSelector((state: RootState) => state.tabs);

  const detailsRef = useRef<any>(null);

  const [hasLoyalityProgram, setHasLoyalityProgram] = useState(false);

  const [reason, setReason] = useState();
  const [declinedItems, setDeclinedItems] = useState<string[]>([]);
  const [approvedItems, setApprovedItems] = useState<string[]>([]);
  const [btnApprove, setBtnApprove] = useState<boolean>(true);

  const [inputError, setInputError] = useState(false);
  const [reasonDescription, setReasonDescription] = useState("");

  const [applyChanges] = useApproveChangesMerchantMutation();

  const [newDataCheckbox, setNewDataCheckbox] = useState<string[]>([]);

  const {
    data: dataMerchantByID,
    isLoading: isLoadingMerchantByID,
    isError: isErrorMerchantByIDX,
    refetch,
  } = useGetMerchantByIDQuery(id);

  const {
    data: declineReasons,
    isLoading: isLoadingDeclineReasons,
    isError: isErrorDeclineReasons,
    // @ts-ignore
  } = useGetDeclineReasonsQuery();

  const [
    setMerchantsStatus,
    {
      data: dataMerchantsStatus,
      isLoading: isLoadingMerchantsStatus,
      isError: isErrorMerchantsStatus,
      status: statusMerchants,
    },
  ] = useChangeStatusMerchantMutation();

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"});
  }, []);

  useEffect(() => {
    if (dataMerchantByID && dataMerchantByID?.status) {
      setHasLoyalityProgram(true);
    }
  }, [dataMerchantByID]);

  useEffect(() => {
    if (isLoadingMerchantByID || isErrorMerchantByIDX)
      dispatch(setOpenModal(true));
    else {
      dispatch(setOpenModal(false));
    }
  }, [isLoadingMerchantByID, isErrorMerchantByIDX]);

  useEffect(() => {
    if (statusMerchants === "fulfilled" && !isErrorMerchantsStatus) {
      refetch();
    }
  }, [statusMerchants, isErrorMerchantsStatus]);

  const handleChangeStatusMerchant = async (newStatus: string) => {
    if (id) {
      const toastPromise = toast.promise(
        setMerchantsStatus({id: parseInt(id), status: newStatus}),
        {
          pending: "Updating status",
        }
      );

      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while updating status</h2>
                <p className="text-xs"> {res?.error?.data?.code} </p>
              </div>
            );
          } else {
            toast.success("Status updated");
          }
        })
        .catch((err) => {
          toast.error("Error while updating status");
        });
    }
  };

  const handleFormatElement = (element: string) => {
    if (element.toLowerCase() === "ownerfirstname") {
      return "owner first name";
    }
    if (element.toLowerCase() === "ownerlastname") {
      return "owner last name";
    }
    if (element.toLowerCase() === "address2") {
      return "address 2";
    }
    if (element.toLowerCase() === "sizetype") {
      return "size";
    }
    if (element.toLowerCase() === "socialreview") {
      return "social review";
    }
    if (element.toLowerCase() === "workinghours") {
      return "working hours";
    }
    if (element.toLowerCase() === "image#profile") {
      return "profile photo";
    }
    if (element.toLowerCase() === "image#interior") {
      return "interior photo";
    }
    if (element.toLowerCase() === "image#logo_large") {
      return "logo photo";
    } else {
      return element;
    }
  };

  const handleOpenModal = (state: string) => {
    if (state === "archive") {
      dispatch(setType("archiveMerchant"));
      dispatch(setOpenModal(true));
    } else {
      dispatch(setType("recoveryMerchant"));
      dispatch(setOpenModal(true));
    }
  };

  const handleCloseModal = () => {
    dispatch(setType(""));
    dispatch(setOpenModal(false));
    setReasonDescription("");
  };

  const handleButtonClick = () => {
    // Access the child component method using the ref
    detailsRef.current.handleSubmit();
  };

  const handleDecline = () => {
    dispatch(setType("declineModal"));
    dispatch(setOpenModal(true));
  };

  const handleApprove = async () => {
    const data = {
      approvedFields: approvedItems,
      declinedFields: declinedItems,
    };

    const toastPromise = toast.promise(
      applyChanges({
        idMerchant: dataMerchantByID.id,
        data,
      }),
      {
        pending: "Approving merchant",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while approving merchant</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Merchant Approved");

          setNewDataCheckbox([]);
          refetch();
        }
      })
      .catch((err) => {
        toast.error("Error while approving merchant");
      });
  };

  const handleSendDeclineReason = async () => {
    if (!reason || !reasonDescription) {
      toast.error("Provide reason and description for decline");
      setInputError(true);
      return;
    }

    const data = {
      approvedFields: approvedItems,
      declinedFields: declinedItems,
    };

    const toastPromise = toast.promise(
      applyChanges({
        idMerchant: dataMerchantByID.id,
        data,
      }),
      {
        pending: "Declining merchant",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while declining merchant</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Merchant Declined");
          dispatch(setType(""));

          setNewDataCheckbox([]);
          dispatch(setOpenModal(false));
          refetch();
        }
      })
      .catch((err) => {
        toast.error("Error while decliging merchant");
      });
  };

  const handleRenderButton = () => {
    return (
      <div className="flex justify-center items-center">
        {tabsData.tabs.subMerchantTabIndex === "3" && (
          <div className="flex flex-col py-2.5 bg-[#EAE7FA] rounded-md mr-12 pl-4 pr-14">
            <p className="text-[rgb(141,149,158)] text-sm">Subscriber</p>
            <p className="text-2xl">{dataMerchantByID?.subscriberCount}</p>
          </div>
        )}
        {(dataMerchantByID?.status === "UNVERIFIED" ||
          dataMerchantByID?.status === "NEW") &&
        (dataMerchantByID?.hasOwnProperty("newData") ||
          dataMerchantByID?.images[0]?.hasOwnProperty("newName") ||
          dataMerchantByID?.images[1]?.hasOwnProperty("newName") ||
          dataMerchantByID?.images[2]?.hasOwnProperty("newName"))
          ? btnApprove // @ts-ignore
            ? routes[user.data.role].urlAllowed?.merchant?.includes(
                "decline"
              ) && (
                <Button variant="recovery" onClick={() => handleApprove()}>
                  <p>Approve & Publish</p>
                </Button>
              )
            : // @ts-ignore
              routes[user.data.role].urlAllowed?.merchant?.includes(
                "decline"
              ) && (
                <Button variant="danger" onClick={() => handleDecline()}>
                  <p>Decline</p>
                </Button>
              )
          : null}
        {dataMerchantByID?.status === "ARCHIVED" ||
        dataMerchantByID?.hasOwnProperty("newData") ||
        dataMerchantByID?.images[0]?.hasOwnProperty("newName") ||
        dataMerchantByID?.images[1]?.hasOwnProperty("newName") ||
        dataMerchantByID?.images[2]?.hasOwnProperty("newName")
          ? null
          : // @ts-ignore
            routes[user.data.role].urlAllowed?.merchant?.includes("edit") && (
              <Button variant="normal" onClick={() => handleButtonClick()}>
                <Save />
                <p className="ml-2">Save</p>
              </Button>
            )}
        {dataMerchantByID?.status === "ARCHIVED" ||
        dataMerchantByID?.hasOwnProperty("newData") ||
        dataMerchantByID?.images[0]?.hasOwnProperty("newName") ||
        dataMerchantByID?.images[1]?.hasOwnProperty("newName") ||
        dataMerchantByID?.images[2]?.hasOwnProperty("newName")
          ? null
          : // selectedTab === "Details" && (
            // @ts-ignore
            routes[user.data.role].urlAllowed?.merchant?.includes(
              "publish"
            ) && (
              <Button
                variant={
                  dataMerchantByID?.status !== "PUBLISHED"
                    ? "normal"
                    : "disabled"
                }
                onClick={() =>
                  dataMerchantByID?.status !== "PUBLISHED"
                    ? handleChangeStatusMerchant("PUBLISHED")
                    : () => {}
                }
              >
                <Publish />
                <p className="ml-2">Publish</p>
              </Button>
              // )
            )}
        {(dataMerchantByID?.hasOwnProperty("newData") ||
          dataMerchantByID?.images[0]?.hasOwnProperty("newName") ||
          dataMerchantByID?.images[1]?.hasOwnProperty("newName") ||
          dataMerchantByID?.images[2]?.hasOwnProperty("newName")) &&
        dataMerchantByID?.status !== "PUBLISHED"
          ? null
          : dataMerchantByID?.status === "ARCHIVED"
          ? null
          : // @ts-ignore
            routes[user.data.role].urlAllowed?.merchant?.includes(
              "archive"
            ) && (
              <Button
                variant="danger"
                onClick={() => handleOpenModal("archive")}
              >
                <Archive />
                <p className="ml-2">Archive Profile</p>
              </Button>
            )}

        {dataMerchantByID?.status === "ARCHIVED" &&
          // @ts-ignore
          routes[user.data.role].urlAllowed?.merchant?.includes("recover") && (
            <Button
              variant="recovery"
              onClick={() => handleOpenModal("recover")}
            >
              <Recovery />
              <p className="ml-2">Recover Profile</p>
            </Button>
          )}
      </div>
    );
  };

  const redirectTo = () => {
    dispatch(setSubMerchantTabIndex(""));
    navigate(-1);
  };

  const handleChangeBtnAllChecked = (value: boolean) => {
    if (value) {
      setBtnApprove(true);
    } else {
      setBtnApprove(false);
    }
  };

  const handleRenderOptionModal = () => {
    return declinedItems.map((option, key) => {
      if (
        option === "state" ||
        option === "city" ||
        option === "zip" ||
        option === "geoLocation"
      ) {
        return null;
      } else {
        return (
          <div
            key={key}
            className="bg-fourth-purple mx-1 my-1 rounded pl-3 pr-4 py-1 text-black relative cursor-default w-max text-sm capitalize"
          >
            {handleFormatElement(option)}
            <span
              id={option}
              // @ts-ignore
              onClick={(e) => handleRemoveElement(e.target.id)}
              className="text-xs text-gray-400 pr-1 ml-2 absolute top-0 right-0 cursor-pointer"
            >
              x
            </span>
          </div>
        );
      }
    });
  };

  return (
    <section className="mt-3">
      <div className="mb-5">
        <p
          onClick={() => redirectTo()}
          className="text-sm text-gray-400 flex hover:text-primary-purple cursor-pointer max-w-max"
        >
          <ArrowBack classes="h-5 w-5 mr-2" />
          Back to merchants list
        </p>
      </div>
      <div className="flex justify-between">
        <div>
          <Title classes="flex items-center">
            <p className="mr-3">
              {dataMerchantByID?.name || dataMerchantByID?.newData?.name}
            </p>
            <Badge title={dataMerchantByID?.status.toLocaleLowerCase()}></Badge>
          </Title>
          <p className="text-xs text-gray-500 mt-3">
            Created: <b>{transformDate(dataMerchantByID?.createdAt)}</b> /
            Modify date: <b>-</b> / Publish:{" "}
            <b>{dataMerchantByID?.status === "PUBLISHED" ? "Yes" : "No"}</b> /
            Subscribers: <b>{dataMerchantByID?.subscriberCount}</b> of total
            subscribers
          </p>
        </div>

        {handleRenderButton()}
      </div>
      <div className="mt-5">
        <Tabs
          secondaryTabs
          tabsName={
            dataMerchantByID?.loyaltyToggle
              ? [
                  `Details`,
                  `Items (${dataMerchantByID?.prizeCount || "-"})`,
                  `Grand Prize (${dataMerchantByID?.grandPrizeCount || "-"})`,
                  `Loyalty Program (${
                    dataMerchantByID?.loyaltyProgramCount || "-"
                  })`,
                  `Employee List (${dataMerchantByID?.userCount || "-"})`,
                  "Activity",
                ]
              : [
                  `Details`,
                  `Items (${dataMerchantByID?.prizeCount || "-"})`,
                  `Grand Prize (${dataMerchantByID?.grandPrizeCount || "-"})`,
                  `Employee List (${dataMerchantByID?.userCount || "-"})`,
                  "Activity",
                ]
          }
          updateOn={dataMerchantByID}
        >
          <MerchantDetails
            merchantInfo={dataMerchantByID}
            refetch={refetch}
            ref={detailsRef}
            handleChangeBtnAllChecked={handleChangeBtnAllChecked}
            setDeclinedItems={setDeclinedItems}
            setApprovedItems={setApprovedItems}
            newDataCheckbox={newDataCheckbox}
            setNewDataCheckbox={setNewDataCheckbox}
          />
          {
            // @ts-ignore
            routes[user.data.role].urlAllowed?.merchant?.includes("view") && (
              <MerchantItems
                merchantInfo={dataMerchantByID}
                headers={TABLE_MERCHANT_ITEMS_HEADER}
                subHeaders={TABLE_MERCHANT_ITEMS_SUBHEADER}
              />
            )
          }
          {
            // @ts-ignore
            routes[user.data.role].urlAllowed?.gp?.includes("view") && (
              <MerchantGrandPrize
                merchantInfo={dataMerchantByID}
                headers={TABLE_MERCHANT_GRAND_PRIZE_ITEMS_HEADER}
              />
            )
          }
          {dataMerchantByID?.loyaltyToggle
            ? // @ts-ignore
              routes[user.data.role].urlAllowed?.loyalty?.includes("view") && (
                <Loyality
                  id={id}
                  merchantInfo={dataMerchantByID}
                  headers={TABLE_MERCHANT_LOYALITY_HEADER}
                />
              )
            : // @ts-ignore
              routes[user.data.role].urlAllowed?.employee?.includes("view") && (
                <MerchantEmployee headers={TABLE_MERCHANT_EMPLOYEE_LIST} />
              )}
          {dataMerchantByID?.loyaltyToggle ? (
            // @ts-ignore
            routes[user.data.role].urlAllowed?.employee?.includes("view") && (
              <MerchantEmployee headers={TABLE_MERCHANT_EMPLOYEE_LIST} />
            )
          ) : (
            <MerchantActivity headers={TABLE_MERCHANT_ACTIVITY} />
          )}
          {dataMerchantByID?.loyaltyToggle && (
            <MerchantActivity headers={TABLE_MERCHANT_ACTIVITY} />
          )}
        </Tabs>
      </div>
      {modal.type === "archiveMerchant" && (
        <ArchiveModal
          elementsChecked={[dataMerchantByID.id]}
          setElementsChecked={() => {}}
          promise={setMerchantsStatus}
          type={"Merchant"}
        />
      )}
      {modal.type === "recoveryMerchant" && (
        <RecoveryModal
          elementsChecked={[dataMerchantByID.id]}
          setElementsChecked={() => {}}
          promise={setMerchantsStatus}
          type={"Merchant"}
        />
      )}
      {(isLoadingMerchantByID || isErrorMerchantByIDX) && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4 text-center">
            {isErrorMerchantByIDX ? (
              <div className="flex flex-col w-full justify-center items-center mt-5 px-4">
                <p className="">
                  An error occurred while trying to retrieve the merchant
                </p>
                <Button onClick={refetch} classes="font-semibold">
                  Try again
                </Button>
              </div>
            ) : (
              <p>Loading Merchant...</p>
            )}
          </div>
        </Modal>
      )}
      {modal.type === "declineModal" && (
        <Modal fnClean={() => handleCloseModal()}>
          <div className="flex flex-col bg-white text-black rounded w-96 pb-6 pt-3">
            <div className="flex justify-center items-center flex-col">
              <div className="flex justify-center items-start flex-col mb-8 px-5">
                <Dialog.Title className=" mb-4 text-black text-xl font-medium">
                  Provide decline reason
                </Dialog.Title>
                <Select
                  error={!reason && inputError ? true : false}
                  label="Provide reason"
                  classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 text-black w-full"
                  // @ts-ignore
                  onChange={(e: any) => setReason(e.target.value)}
                >
                  {isLoadingDeclineReasons && !isErrorDeclineReasons && (
                    <option value="" disabled>
                      Loading Decline Reasons
                    </option>
                  )}
                  {!isLoadingDeclineReasons &&
                    !isErrorDeclineReasons &&
                    declineReasons && (
                      <>
                        <option disabled selected>
                          Choose reason of decline
                        </option>
                        {declineReasons.map(
                          (reason: {description: string; id: number}) => (
                            <option
                              value={reason.id.toString()}
                              key={reason.id}
                            >
                              {reason.description}
                            </option>
                          )
                        )}
                      </>
                    )}
                </Select>
                {declinedItems.length > 0 && (
                  <div className="mt-5 w-full">
                    <Label>Problem Inputs</Label>
                    <div className="flex flex-wrap border border-gray-400 rounded p-2">
                      {handleRenderOptionModal()}
                    </div>
                  </div>
                )}
                <Textarea
                  label="Description"
                  placeholder="Enter a description"
                  error={!reasonDescription && inputError ? true : false}
                  classes="mt-5 w-full"
                  required={true}
                  value={reasonDescription}
                  onChange={(e: any) => {
                    setReasonDescription(e.target.value);
                  }}
                />
              </div>
              <hr className="w-full" />
              <div className="flex justify-between items-center w-full px-5 mt-3">
                <Button
                  onClick={() => handleCloseModal()}
                  variant="normal"
                  classes="text-center"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => handleSendDeclineReason()}
                  variant="add"
                  classes="text-center"
                >
                  Sent
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </section>
  );
}

export default MerchantProfile;
