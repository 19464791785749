import Archive from "assets/Archive";
import {ArrowBack} from "assets/Arrows";
import {EyeHide} from "assets/Eye";
import Publish from "assets/Publish";
import Recovery from "assets/Recovery";
import Button from "components/Common/Button";
import {useEffect, useState} from "react";
import Published from "./Tabs/Published";
import Archived from "./Tabs/Archived";
import {
  useArchiveCategoryLocationMutation,
  useChangePositionMutation,
  useCreateCategoryFromLocationMutation,
  useDeleteCategoryFromLocationMutation,
  useGetCategoriesFromLocationByIDQuery,
  usePublishCategoryLocationMutation,
  useRecoverCategoryLocationMutation,
  useUnpublishCategoryLocationMutation,
} from "store/api/locationApi";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store/store";
import DropdownCategory from "components/Common/DropdownCategory";
import {setResetElChecked} from "store/table/currentTableDataSlice";
import Modal from "components/Modal";
import {Dialog} from "@headlessui/react";
import {setOpenModal, setType} from "store/modal/modalSlice";
import Input from "components/Common/Input";
import Delete from "assets/Delete";
import {toast} from "react-toastify";
import {setSorting} from "store/filters/filtersSlice";
import DeleteModal from "components/Modal/DeleteModal";
import {Container} from "components/DragNDrop/Container";

function LocationCategories({id}: {id: string}) {
  const dispatch = useDispatch();
  const filters = useSelector((state: RootState) => state?.filters?.filters);
  const modal: any = useSelector((state: RootState) => state.modal);

  const [newCategoryName, setNewCategoryName] = useState("");
  const [actualTab, setActualTab] = useState("Published");
  const [params, setParams] = useState("?ordering=position");
  const [dropdown, openDropdown] = useState(false);
  const [elementsCheckedDropdown, setElementsCheckedDropdown] = useState<
    string[]
  >([]);
  const [elementsName, setElementsName] = useState<string[]>([]);
  const [elementsChecked, setElementsChecked] = useState<number[]>([]);

  const [typeActionCategory, setTypeActionCategory] = useState("");
  const [modalContent, setModalContent] = useState<any[]>([]);

  const [categoriesOrdered, setCategoriesOrdered] = useState<
    [{categoryId?: string; position?: number}]
  >([{}]);

  useEffect(() => {
    dispatch(setSorting("ordering=position"));
  }, []);

  const handleItemChecked = (items: number[]) => {
    setElementsChecked(items);
  };

  const {
    data: dataCategoryByLocation,
    isLoading: isLoadingCategoryByLocation,
    isError: isErrorCategoryByLocation,
    refetch,
  } = useGetCategoriesFromLocationByIDQuery({id, params});

  const [createCategoryMutation] = useCreateCategoryFromLocationMutation();
  const [publishCategoryLocationMutation] =
    usePublishCategoryLocationMutation();
  const [unpublishCategoryLocationMutation] =
    useUnpublishCategoryLocationMutation();
  const [archiveCategoryLocationMutation] =
    useArchiveCategoryLocationMutation();
  const [recoverCategoryLocationMutation] =
    useRecoverCategoryLocationMutation();
  const [deleteCategoryMutation] = useDeleteCategoryFromLocationMutation();
  const [changePositionMutation] = useChangePositionMutation();

  useEffect(() => {
    setParams(
      `?${filters.sorting}${filters.page}${filters.limit}${filters.urlSearch}${
        filters.urlFilters
      }&status=${
        actualTab === "Published"
          ? "PUBLISHED&status=NEW&status=ACTIVE"
          : "ARCHIVED"
      }`
    );
  }, [filters, actualTab]);

  const handleClickCheckbox = (e: any) => {
    const {id, name, checked} = e.target;

    if (id !== "all") {
      if (elementsCheckedDropdown.includes("all")) {
        setElementsCheckedDropdown([id]);
        setElementsName([name]);
      } else {
        setElementsCheckedDropdown([...elementsCheckedDropdown, id]);
        setElementsName([...elementsName, name]);
      }

      if (!checked) {
        setElementsCheckedDropdown(
          elementsCheckedDropdown.filter((item: any) => item !== id)
        );
        setElementsName(elementsName.filter((item: any) => item !== name));
      }
    } else {
      setElementsCheckedDropdown(["all"]);
      setElementsName(["All"]);

      if (!checked) {
        setElementsCheckedDropdown(
          elementsCheckedDropdown.filter((item: any) => item !== "all")
        );
        setElementsName(elementsName.filter((item: any) => item !== "All"));
      }
    }
  };

  const handleUnpublishCategoryFn = (e: any, type: string, option: string) => {
    if (option === "array") {
      if (type === "unpublish") {
        const names = e.map((id: any) => {
          return handleGetName(id);
        });

        const arrPublishFiltered = names.filter(
          (el: any) => el.isPublished === true
        );

        if (arrPublishFiltered.length > 0) {
          dispatch(setType("unpublishCategoryModal"));
          dispatch(setOpenModal(true));
          setTypeActionCategory("unpublish");
          setModalContent(arrPublishFiltered);
        } else {
          toast.info(`Category is already ${type}ed`);
        }
      } else if (type === "publish") {
        const names = e.map((id: any) => {
          return handleGetName(id);
        });

        const arrUnpublishFiltered = names.filter(
          (el: any) => el.isPublished === false
        );

        if (arrUnpublishFiltered.length > 0) {
          dispatch(setType("unpublishCategoryModal"));
          dispatch(setOpenModal(true));
          setTypeActionCategory("publish");
          setModalContent(arrUnpublishFiltered);
        } else {
          toast.info(`Category is already ${type}ed`);
        }
      }
    }
    if (option === "individual") {
      if (type === "unpublish" && e.isPublished === true) {
        setModalContent([e]);
        dispatch(setType("unpublishCategoryModal"));
        dispatch(setOpenModal(true));
      } else if (type === "publish" && e.isPublished === false) {
        setModalContent([e]);
        dispatch(setType("unpublishCategoryModal"));
        dispatch(setOpenModal(true));
      } else {
        toast.info(`This category is already ${type}`);
      }
    }
  };

  const handleGetName = (categoryId: number) => {
    return dataCategoryByLocation.find(
      (category: any) => category.categoryId === categoryId
    );
  };

  const handleClickDropdown = async (e: any, type: string) => {
    e.preventDefault();
    if (type === "save") {
      if (categoriesOrdered && categoriesOrdered.length > 0) {
        const toastPromise = toast.promise(
          changePositionMutation({dmaCode: id, data: categoriesOrdered}),
          {
            pending: `Changing position categories`,
          }
        );

        await toastPromise
          .then((res: any) => {
            if (res.error) {
              toast.error(
                <div>
                  <h2>Error while changing position</h2>
                  <p className="text-xs"> {res?.error?.data?.message} </p>
                </div>
              );
            } else {
              toast.success(`Positions changed`);
              dispatch(setOpenModal(false));
              dispatch(setType(""));
            }
          })
          .catch((err) => {
            toast.error("Error while changing position");
          })
          .finally(() => {
            openDropdown(false);
          });
      }

      return;
    }

    setElementsCheckedDropdown([]);
    setElementsName([]);
  };

  const handleUnpublish = () => {
    modalContent?.map(async (categoryData: any) => {
      const categoryId = categoryData.categoryId;

      const toastPromise = toast.promise(
        unpublishCategoryLocationMutation({dmaCode: id, categoryId}),
        {
          pending: `Unpublishing category ${
            categoryData ? categoryData.categoryName : categoryId
          }`,
        }
      );

      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>
                  Error while unpublishing category{" "}
                  {categoryData ? categoryData.categoryName : categoryId}
                </h2>
                <p className="text-xs"> {res?.error?.data?.message} </p>
              </div>
            );
          } else {
            toast.success(
              `Category ${
                categoryData ? categoryData.categoryName : categoryId
              } unpublished`
            );
            dispatch(setOpenModal(false));
            dispatch(setType(""));
            dispatch(setResetElChecked(true));
          }
        })
        .catch((err) => {
          toast.error("Error while unpublishing category");
        });
    });

    setTimeout(() => {
      dispatch(setType(""));
      dispatch(setOpenModal(false));
    }, 1000);
  };

  const handlePublish = () => {
    modalContent?.map(async (categoryData: any) => {
      const categoryId = categoryData.categoryId;

      const toastPromise = toast.promise(
        publishCategoryLocationMutation({dmaCode: id, categoryId}),
        {
          pending: `Publishing category ${
            categoryData ? categoryData.categoryName : categoryId
          }`,
        }
      );

      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>
                  Error while publishing category{" "}
                  {categoryData ? categoryData.categoryName : categoryId}
                </h2>
                <p className="text-xs"> {res?.error?.data?.message} </p>
              </div>
            );
          } else {
            toast.success(
              `Category ${
                categoryData ? categoryData.categoryName : categoryId
              } published`
            );
            dispatch(setOpenModal(false));
            dispatch(setType(""));
            dispatch(setResetElChecked(true));
          }
        })
        .catch((err) => {
          toast.error("Error while publishing category");
        });
    });

    setTimeout(() => {
      dispatch(setType(""));
      dispatch(setOpenModal(false));
    }, 1000);
  };

  const handleRenderButton = () => {
    const handleChangeStatus = async (type: string) => {
      const mutationByType =
        type === "archive"
          ? archiveCategoryLocationMutation
          : recoverCategoryLocationMutation;

      const toastPromise = toast.promise(
        mutationByType({
          dmaCode: parseInt(id),
          data: {categoryIds: elementsChecked},
        }),
        {
          pending: `${
            type === "archive" ? "Archiving" : "Recovering"
          } category`,
        }
      );

      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>
                  Error while {type === "archive" ? "archiving" : "recovering"}{" "}
                  category
                </h2>
                <p className="text-xs"> {res?.error?.data?.message} </p>
              </div>
            );
          } else {
            toast.success(
              `Categories ${type === "archive" ? "archived" : "recovered"}`
            );
            dispatch(setOpenModal(false));
            dispatch(setType(""));
            dispatch(setResetElChecked(true));
          }
        })
        .catch((err) => {
          toast.error("Error while changing status");
        });

      // handle archive button click
    };

    const handleDeleteCategory = () => {
      dispatch(setType("deleteCategoryModal"));
      dispatch(setOpenModal(true));
    };

    const handleAddCategory = () => {
      dispatch(setType("createCategoryModal"));
      dispatch(setOpenModal(true));
    };

    // https://admin-panel-dev.zazmicdemo.com/v1/retailer-prize/admin/location/1 Missing status in this request

    return (
      <div className="flex">
        {actualTab === "Published" ? (
          <>
            <Button
              variant={`${elementsChecked.length > 0 ? "add" : "disabled"}`}
              onClick={
                elementsChecked.length > 0
                  ? () => {
                      handleUnpublishCategoryFn(
                        elementsChecked,
                        "publish",
                        "array"
                      );
                    }
                  : undefined
              }
            >
              <Publish />
              <p className="ml-2">Publish</p>
            </Button>
            <Button
              variant={`${elementsChecked.length > 0 ? "normal" : "disabled"}`}
              onClick={
                elementsChecked.length > 0
                  ? () => {
                      handleUnpublishCategoryFn(
                        elementsChecked,
                        "unpublish",
                        "array"
                      );
                    }
                  : undefined
              }
            >
              <EyeHide />
              <p className="ml-2">Unpublish</p>
            </Button>

            {dataCategoryByLocation?.state === "ARCHIVED" ? (
              <Button
                variant={`${
                  elementsChecked.length > 0 ? "recovery" : "disabled"
                }`}
                onClick={
                  elementsChecked.length > 0
                    ? () => handleChangeStatus("recovery")
                    : undefined
                }
              >
                <Recovery />
                <p className="ml-2">Recovery</p>
              </Button>
            ) : (
              <Button
                variant={`${
                  elementsChecked.length > 0 ? "danger" : "disabled"
                }`}
                onClick={
                  elementsChecked.length > 0
                    ? () => handleChangeStatus("archive")
                    : undefined
                }
              >
                <Archive />
                <p className="ml-2">Archive</p>
              </Button>
            )}

            <Button variant="recovery" onClick={handleAddCategory}>
              +<p className="ml-2">Add Category</p>
            </Button>
          </>
        ) : (
          <>
            <Button
              variant={`${elementsChecked.length > 0 ? "danger" : "disabled"}`}
              onClick={
                elementsChecked.length > 0 ? handleDeleteCategory : undefined
              }
            >
              <Delete />
              <p className="ml-2">Delete</p>
            </Button>
            <Button
              variant={`${
                elementsChecked.length > 0 ? "recovery" : "disabled"
              }`}
              onClick={
                elementsChecked.length > 0
                  ? () => handleChangeStatus("recovery")
                  : undefined
              }
            >
              <Recovery />
              <p className="ml-2">Recovery</p>
            </Button>
          </>
        )}
      </div>
    );
  };

  const handleCloseModal = () => {
    setNewCategoryName("");
    dispatch(setOpenModal(false));
    dispatch(setType(""));
  };

  const handleAddNewCategory = async (e: any) => {
    e.preventDefault();

    const toastPromise = toast.promise(
      createCategoryMutation({
        id,
        data: {
          color: "#e3e3e389",
          name: newCategoryName,
        },
      }),
      {
        pending: "Creating category",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while creating category</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Category created");
          dispatch(setOpenModal(false));
          dispatch(setType(""));
          setNewCategoryName("");
        }
      })
      .catch((err) => {
        toast.error("Error while creating category");
      });
  };

  return (
    <>
      <div className="flex justify-between items-center mt-8 mb-2">
        <div className="rounded border-2 border-gray-200 flex justify-center items-center bg-white">
          <span
            className={`cursor-pointer h-full w-full px-4 py-2 flex rounded-l items-center text-sm ${
              actualTab === "Published"
                ? "bg-primary-purple text-white"
                : "hover:bg-tertiary-purple"
            }`}
            onClick={() => {
              setActualTab("Published");
              setElementsChecked([]);
              dispatch(setResetElChecked(true));
            }}
          >
            Published
          </span>

          <span
            className={`cursor-pointer  h-full w-full px-4 py-2 flex rounded-r items-center text-sm ${
              actualTab === "Archived"
                ? "bg-primary-purple text-white"
                : "hover:bg-tertiary-purple"
            }`}
            onClick={() => {
              setActualTab("Archived");
              setElementsChecked([]);
              dispatch(setResetElChecked(true));
            }}
          >
            Archived
          </span>
        </div>
        {actualTab === "Published" && (
          <div>
            <DropdownCategory
              openDropdown={openDropdown}
              dropdown={dropdown}
              elementsChecked={[]}
              hasLabel={false}
              placeholder="Change Categories Position"
              elementsName={[]}
              handleClickCheckbox={handleClickCheckbox}
              // dataCategories={dataCategories}
              customData={
                <Container
                  callback={(data: any) => {
                    setCategoriesOrdered(data);
                  }}
                  data={dataCategoryByLocation
                    ?.map(
                      ({position, categoryId, categoryName, ...props}: any) => {
                        return {
                          id: position,
                          categoryId,
                          text: `${categoryName}`,
                        };
                      }
                    )
                    ?.sort((a: any, b: any) => a.id - b.id)}
                />
              }
              isErrorCategories={isErrorCategoryByLocation}
              isLoadingCategories={isLoadingCategoryByLocation}
              handleClickDropdown={handleClickDropdown}
              hasSelectAll={false}
              hasId={true}
              hasClearAll={false}
              hasCheckbox={false}
            />
          </div>
        )}
        <div>{handleRenderButton()}</div>
      </div>

      {actualTab === "Published" ? (
        <Published
          data={dataCategoryByLocation}
          isLoading={isLoadingCategoryByLocation}
          isError={isErrorCategoryByLocation}
          refetch
          elementsChecked={elementsChecked}
          handleItemChecked={handleItemChecked}
          DMACode={id}
        />
      ) : (
        <Archived
          data={dataCategoryByLocation}
          isLoading={isLoadingCategoryByLocation}
          isError={isErrorCategoryByLocation}
          refetch
          elementsChecked={elementsChecked}
          handleItemChecked={handleItemChecked}
          DMACode={id}
        />
      )}

      {modal.type === "deleteCategoryModal" && (
        <DeleteModal
          elementsChecked={elementsChecked}
          setElementsChecked={setElementsChecked}
          dataSource={dataCategoryByLocation}
          matchWidhId="categoryId"
          pendingMsg="Deleting category"
          promise={deleteCategoryMutation}
          returnValue="categoryName"
          toastMessageId="categoryName"
          type=""
          extraArgs={{
            dmaCode: id,
          }}
        />
      )}

      {modal.type === "unpublishCategoryModal" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <Dialog.Overlay />
            <form>
              <Dialog.Title className="px-6 mb-2 text-black text-xl font-medium">
                Are you sure you want to {typeActionCategory} this category?
              </Dialog.Title>
              <div className="px-6 mb-5">
                {modalContent.map((element: any) => {
                  return <p>- {element?.categoryName}</p>;
                })}
              </div>

              <p className="text-sm px-6">
                This action will affect the customer application
              </p>
              <hr className="my-5" />
              <div className="px-6">
                <div className="w-100 flex justify-between items-center mt-4">
                  <Button
                    type="button"
                    variant="normal"
                    onClick={() => handleCloseModal()}
                  >
                    <p>Cancel</p>
                  </Button>
                  {typeActionCategory === "unpublish" ? (
                    <Button
                      variant={
                        modalContent[0]?.isPublished !== false
                          ? "add"
                          : "disabled"
                      }
                      type="button"
                      onClick={
                        modalContent[0]?.isPublished !== false
                          ? handleUnpublish
                          : () =>
                              console.log(
                                "This category is already unpublished"
                              )
                      }
                    >
                      <p>Unpublish</p>
                    </Button>
                  ) : (
                    <Button
                      variant={
                        modalContent[0]?.isPublished !== true
                          ? "add"
                          : "disabled"
                      }
                      type="button"
                      onClick={
                        modalContent[0]?.isPublished !== true
                          ? handlePublish
                          : () =>
                              console.log("This category is already published")
                      }
                    >
                      <p>Publish</p>
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )}

      {modal.type === "createCategoryModal" && (
        <Modal fnClean={() => setNewCategoryName("")}>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <Dialog.Overlay />
            <form>
              <Dialog.Title className="px-6 mb-4 text-black text-xl font-medium">
                Add new category
              </Dialog.Title>
              <hr />

              <div className=" px-6">
                <Input
                  label="Category Name"
                  required={true}
                  value={newCategoryName}
                  onChange={(e: any) => setNewCategoryName(e.target.value)}
                  placeholder="Enter category name"
                  classes="my-5"
                />

                <hr />
                <div className="w-100 flex justify-between items-center mt-4">
                  <Button
                    type="button"
                    variant="normal"
                    onClick={() => handleCloseModal()}
                  >
                    <p>Cancel</p>
                  </Button>
                  <Button
                    variant="add"
                    type="submit"
                    onClick={(e) => handleAddNewCategory(e)}
                  >
                    <p>Add</p>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </>
  );
}

export default LocationCategories;
