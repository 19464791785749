// @ts-nocheck
import { createApi } from '@reduxjs/toolkit/query/react'
import { store } from 'store/store'
import customFetchBase from './customFetchBase';

export const merchantsApi = createApi({
  reducerPath: 'merchantsApi',
  baseQuery: customFetchBase,
  tagTypes: ['Merchant', 'IndividualMerchant', 'GetMerchantName', 'GetMerchantArchivedName', 'MerchantItems', 'MerchantGrandPrize', 'EmployeeList'],
  endpoints: (builder) => ({
    getMerchantByID: builder.query({
      query: (id) => ({
        url: `/retailer-profile/admin/retailer/${id}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      providesTags: ["IndividualMerchant"]
    }),
    getItemsByMerchantID: builder.query({
      query: ({ id, params }) => ({
        url: `/retailer-prize/admin/retailer/${id}/prize-list${!params ? '' : `${params}`}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      providesTags: ["MerchantItems"]
    }),
    getMerchantInfoYelpGoogle: builder.query({
      query: (params) => ({
        url: `/retailer-profile/admin/retailer/info${!params ? '' : `?url=${params}`}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
    }),
    getSubItemsFromItemsByMerchantID: builder.query({
      query: ({ idMerchant, idPrize }) => ({
        url: `/retailer-prize/admin/weekly-report/retailer/${idMerchant}/prize/${idPrize}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
    }),
    getAllMerchants: builder.query<any, any>({
      query: (params) => ({
        url: `/retailer-profile/admin/retailer${!params ? '/' : `${params}`}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      providesTags: ["Merchant"]
    }),
    changeStatusMerchant: builder.mutation({
      query({ id, status }: { id: number, status: string }) {
        return {
          url: `/retailer-profile/admin/retailer/${id}/status`,
          method: 'POST',
          body: {
            "newStatus": status
          },
          headers: {
            'Auth-Token': store.getState().user.user.user.token
          }
        }
      },
      invalidatesTags: ['Merchant', 'GetMerchantName', 'GetMerchantArchivedName', 'IndividualMerchant']
    }),
    changeImageMerchant: builder.mutation({
      query({ data, id }: any) {
        return {
          url: `/retailer-profile/admin/retailer/${id}/image`,
          method: 'PUT',
          body: data,
          headers: {
            'Auth-Token': store.getState().user.user.user.token,
            'accept': 'application/json',
            // 'Content-Type': 'multipart/form-data'
          }
        }
      },
      invalidatesTags: ['IndividualMerchant']
    }),
    createMerchant: builder.mutation({
      query: (data) => ({
        url: `/retailer-profile/admin/retailer`,
        method: 'POST',
        body: { ...data },
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['Merchant', 'GetMerchantName']
    }),
    updateMerchant: builder.mutation({
      query: ({ id, data }) => ({
        url: `/retailer-profile/admin/retailer/${id}`,
        method: 'PATCH',
        body: { ...data },
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['Merchant', 'GetMerchantName', 'GetMerchantArchivedName']
    }),
    getAllMerchantsName: builder.query({
      query() {
        return {
          method: 'GET',
          url: `/retailer-profile/admin/retailer/name?limit=1000`,
          headers: {
            'Auth-Token': store.getState().user.user.user.token
          }
        }
      },
      providesTags: ['GetMerchantName']
    }),
    getAllMerchantsArchivedName: builder.query({
      query() {
        return {
          method: 'GET',
          url: `/retailer-profile/admin/retailer/archived/name?limit=1000`,
          headers: {
            'Auth-Token': store.getState().user.user.user.token
          }
        }
      },
      providesTags: ['GetMerchantArchivedName']
    }),
    getMerchantActivity: builder.query<any, any>({
      query: ({ id, params }) => ({
        url: `/action-log/admin/retailer/${id}${!params ? '' : `${params}`}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      })
    }),
    getMerchantGrandPrize: builder.query<any, any>({
      query: (id) => ({
        url: `/retailer-grand-prize/admin/retailer/${id}/prize-list?limit=50`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      providesTags: ["MerchantGrandPrize"]
    }),
    getMerchantEmployeeList: builder.query<any, any>({
      query: (id) => ({
        url: `/retailer/admin/retailer/${id}/user`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      providesTags: ['EmployeeList']
    }),
    updateEmployeeList: builder.mutation<any, any>({
      query: ({ idRetailer, idUser, data }) => ({
        url: `/retailer/admin/retailer/${idRetailer}/user/${idUser}`,
        method: 'PATCH',
        body: { ...data },
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['EmployeeList']
    }),
    sendInvite: builder.mutation<any, any>({
      query: ({ id, data }) => ({
        url: `/retailer/admin/retailer/${id}/user/invite`,
        method: 'POST',
        body: { ...data },
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['EmployeeList']
    }),
    deleteInvite: builder.mutation<any, any>({
      query: ({ idRetailer, idUser }) => ({
        url: `/retailer/admin/retailer/${idRetailer}/user/${idUser}/delete`,
        method: 'DELETE',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['EmployeeList']
    }),
    cancelInvite: builder.mutation<any, any>({
      query: ({ idRetailer, idUser }) => ({
        url: `/retailer/admin/retailer/${idRetailer}/user/${idUser}/cancel-invite`,
        method: 'PUT',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['EmployeeList']
    }),
    acceptImage: builder.mutation<any, any>({
      query: ({ idImage }: number) => ({
        url: `/retailer-profile/admin/image/${idImage}/accept`,
        method: 'POST',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['IndividualMerchant']
    }),
    declineImage: builder.mutation<any, any>({
      query: ({ idImage }: number) => ({
        url: `/retailer-profile/admin/image/${idImage}/decline`,
        method: 'POST',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['IndividualMerchant']
    }),
    generateQr: builder.mutation<any, any>({
      query: ({ idMerchant, typeQr }: {idMerchant: number, typeQr: string}) => ({
        url: `/retailer-profile/admin/retailer/${idMerchant}/qr?type=${typeQr}`,
        method: 'POST',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['IndividualMerchant']
    }),
    approveChangesMerchant: builder.mutation({
      query: ({ idMerchant, data }) => ({
        url: `/retailer-profile/admin/retailer/${idMerchant}/apply-changes`,
        body: { ...data },
        method: 'POST',
        headers: {
          'Auth-Token': store.getState().user.user.user.token,
        }
      }),
      invalidatesTags: ['IndividualMerchant']
    }),
    getTrendingPlaces: builder.query({
      query({ dmaCode }) {
        return {
          method: 'GET',
          url: `/retailer-profile/admin/dma/${dmaCode}/trending-retailer`,
          headers: {
            'Auth-Token': store.getState().user.user.user.token
          }
        }
      },
      providesTags: ['TrendingPlaces']
    }),
    changeTrendingPlaces: builder.mutation({
      query: ({ dmaCode, data }) => ({
        url: `/retailer-profile/admin/dma/${dmaCode}/trending-retailer/change-placement`,
        method: 'POST',
        body: [...data],
        headers: {
          'Auth-Token': store.getState().user.user.user.token,
        }
      }),
      invalidatesTags: ['TrendingPlaces']
    }),
    changeLoyalityProgram: builder.mutation({
      query: ({ id, data }) => ({
        url: `/retailer-profile/admin/retailer/${id}/loyalty`,
        method: 'PUT',
        body: { ...data },
        headers: {
          'Auth-Token': store.getState().user.user.user.token,
        }
      }),
    }),
  }),
})

export const {
  useChangeStatusMerchantMutation,
  useGetAllMerchantsQuery,
  useLazyGetAllMerchantsQuery,
  useGetMerchantByIDQuery,
  useLazyGetMerchantByIDQuery,
  useLazyGetMerchantInfoYelpGoogleQuery,
  useGetAllMerchantsNameQuery,
  useGetAllMerchantsArchivedNameQuery,
  useChangeImageMerchantMutation,
  useCreateMerchantMutation,
  useUpdateMerchantMutation,
  useGetItemsByMerchantIDQuery,
  useLazyGetItemsByMerchantIDQuery,
  useGetSubItemsFromItemsByMerchantIDQuery,
  useGetMerchantActivityQuery,
  useGetMerchantGrandPrizeQuery,
  useGetMerchantEmployeeListQuery,
  useUpdateEmployeeListMutation,
  useSendInviteMutation,
  useDeleteInviteMutation,
  useCancelInviteMutation,
  useAcceptImageMutation,
  useDeclineImageMutation,
  useGenerateQrMutation,
  useApproveChangesMerchantMutation,
  useGetTrendingPlacesQuery,
  useChangeTrendingPlacesMutation,
  useChangeLoyalityProgramMutation
} = merchantsApi