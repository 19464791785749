import {Fragment, memo, useCallback, useState} from "react";

// Components
import Badge from "components/Badge";
import ButtonOptionsTable from "./Elements/ButtonOptionsTable";
import TableSub from "../TableSub";
import CheckboxTable from "./Elements/CheckboxTable";

// Routes
import {Link} from "react-router-dom";

// Utils
import {transformDate, transformDateWithHour} from "utils/transformDate";
import removeUnderscore from "utils/removeUnderscore";
import {setLoyaltyProgram} from "store/filters/filtersSlice";
import {useDispatch} from "react-redux";
import {getNextDayEndDate} from "utils/addDays";

interface TableBodyTypes {
  tableData: any;
  hasSubTable?: boolean;
  subHeaders?: any;
  hasCheckbox?: boolean;
  origin?: string;
  handleClick?: any;
  isCheck?: any;
  show?: string[];
  sort?: any;
  showButtonOption?: boolean;
  options?: any;
  typeOptions?: string;
  dropdown?: boolean;
  setMerchantsStatus?: Function;
  additionalData?: any; // Works to pass information for example idMerchants to item's name
}

function TableBody({
  tableData,
  hasCheckbox = true,
  hasSubTable = false,
  subHeaders,
  origin,
  handleClick,
  isCheck,
  show,
  sort,
  showButtonOption = true,
  options,
  typeOptions,
  dropdown = false,
  setMerchantsStatus,
  additionalData,
}: TableBodyTypes) {
  const dispatch = useDispatch();
  const [idTableShow, setIdTableShow] = useState<number[]>([]);

  const handleTableData = useCallback((element: any) => {
    let sortedObject = Object.assign(sort, element);

    return Object.entries(sortedObject).map(([key, value]: any) => {
      if (Array.isArray(value)) {
        return;
      }

      // Badge for "active" - "published" - "completed", etc...
      if (show?.includes(key)) {
        if (
          (key === "status" && origin === "merchants") ||
          (key === "status" && origin === "admins") ||
          (key === "status" && origin === "merchant_employee_list") ||
          key === "exp_days" ||
          key === "state" ||
          key === "prizeState" ||
          (key === "role" && origin !== "merchant_employee_list")
        ) {
          return (
            <td key={key} className="py-4 px-4 font-medium">
              <Badge title={value} origin={origin} element={element} />
            </td>
          );
        }

        if (
          (key === "status" && origin === "categories") ||
          (key === "status" && origin === "categories_published")
        ) {
          return (
            <td key={key} className="py-4 px-4 font-medium">
              <Badge
                title={value}
                origin={origin}
                element={element}
                showIcon={false}
              />
            </td>
          );
        }

        // Badge to post fix day and check if the value is > 0
        if (key === "daysToExpire") {
          const val = value > 0 ? value : 0;
          return (
            <td key={key} className="py-4 px-4 font-medium">
              <Badge title={`${val.toString()} days`} />
            </td>
          );
        }

        if (key === "name" && origin === "merchants") {
          return (
            <td
              key={key}
              className="py-4 px-4 font-medium text-blue-300 underline underline-offset-1 merchant-id"
              id={`${element.id}`}
            >
              <Link id={`${element.id}`} to={`/merchants/${element.id}`}>
                {value}
              </Link>
            </td>
          );
        }

        // Create a link item name
        if (
          (key === "merchantName" && origin === "items") ||
          (key === "merchantName" && origin === "items_grand_prize")
        ) {
          return (
            <td
              key={key}
              className="py-4 px-4 font-medium text-blue-300 underline underline-offset-1 merchant-id"
              id={`${element.merchantId}`}
            >
              <Link
                id={`${element.merchantId}`}
                to={`/merchants/${element.merchantId}`}
              >
                {value}
              </Link>
            </td>
          );
        }

        if (key === "name" && origin === "categories") {
          return (
            <td
              key={key}
              className="py-4 px-4 font-medium text-blue-300 underline underline-offset-1 merchant-id"
              id={`${element.id}`}
            >
              <Link
                id={`${element.id}`}
                to={`/categories/${element.id}`}
                state={{...additionalData}}
              >
                {value}
              </Link>
            </td>
          );
        }

        if (key === "title" && origin === "loyalty_program") {
          return (
            <td
              key={Math.random()}
              className="py-4 px-4 font-medium underline text-blue-300 cursor-pointer"
              onClick={() => dispatch(setLoyaltyProgram(element))}
            >
              {value}
            </td>
          );
        }

        if (
          (key === "type" && origin === "categories") ||
          (key === "type" && origin === "categories_published")
        ) {
          if (value === "DEFAULT") {
            return (
              <td key={Math.random()} className="py-4 px-4 font-medium">
                Fixed
              </td>
            );
          }
          if (value === "CUSTOM") {
            return (
              <td key={Math.random()} className="py-4 px-4 font-medium">
                Custom
              </td>
            );
          }
          if (value === "LOGIC") {
            return (
              <td key={Math.random()} className="py-4 px-4 font-medium">
                Logic
              </td>
            );
          } else {
            return (
              <td key={Math.random()} className="py-4 px-4 font-medium">
                Unknown type
              </td>
            );
          }
        }

        if (key === "dmaName" && origin === "locations") {
          return (
            <td
              key={key}
              className="py-4 px-4 font-medium text-blue-300 underline underline-offset-1 merchant-id"
              id={`${element.dmaCode}`}
            >
              <Link
                id={`${element.dmaCode}`}
                to={`/location/${element.dmaCode}`}
                state={{
                  origin: "Categories",
                }}
              >
                {value}
              </Link>
            </td>
          );
        }

        // Used only for activity, formating date with hour
        if (key === "logDate" && origin === "merchant_activity") {
          return (
            <td key={key} className="py-4 px-4 font-medium">
              {transformDateWithHour(value)}
            </td>
          );
        }

        // Used to pass idMerchant and idItem to item profile
        // if ((key === "name" && origin === "merchant_items") || (key === "name" && origin === "merchant_grand_prize")) {
        if (
          (key === "name" && origin === "merchant_items") ||
          (key === "name" && origin === "merchant_grand_prize")
        ) {
          return (
            <td
              key={key}
              className="py-4 px-4 font-medium text-blue-300 underline underline-offset-1"
            >
              <Link
                to={
                  origin === "merchant_items"
                    ? `/items/${element?.prizeId}`
                    : `/grandprize/${element?.prizeId}`
                }
                state={{...additionalData, idItem: element?.prizeId}}
              >
                {value}
              </Link>
            </td>
          );
        }

        // Used to change type of prize or grand prize
        if (key === "prizeType") {
          if (value === "REGULAR") {
            return (
              <td key={Math.random()} className="py-4 px-4 font-medium">
                Prize
              </td>
            );
          }
          if (value === "OFFER") {
            return (
              <td key={Math.random()} className="py-4 px-4 font-medium">
                Offer
              </td>
            );
          }
          if (value === "REWARD") {
            return (
              <td key={Math.random()} className="py-4 px-4 font-medium">
                Reward
              </td>
            );
          }
          if (value === "GRAND") {
            return (
              <td key={Math.random()} className="py-4 px-4 font-medium">
                Grand Prize
              </td>
            );
          }
          if (value === "QR_CODE") {
            return (
              <td key={Math.random()} className="py-4 px-4 font-medium">
                QR. code
              </td>
            );
          }
          if (value === "SHIP_TO_ADDRESS") {
            return (
              <td key={Math.random()} className="py-4 px-4 font-medium">
                Shipped
              </td>
            );
          }
          if (value === "REDEMPTION_CODE") {
            return (
              <td key={Math.random()} className="py-4 px-4 font-medium">
                Red. code
              </td>
            );
          }
        }

        if (key === "role" || key === "userRole") {
          return (
            <td key={key} className="py-4 px-4 font-medium">
              {removeUnderscore(value)}
            </td>
          );
        }

        if (
          (key === "prizeId" && origin === "items") ||
          (key === "prizeId" && origin === "items_grand_prize")
        ) {
          return (
            <td
              key={key}
              className="py-4 px-4 font-medium text-blue-300 underline underline-offset-1"
            >
              <Link
                to={
                  origin === "items"
                    ? `/items/${element.prizeId}`
                    : `/grandprize/${element.prizeId}`
                }
                state={{
                  idMerchant: element?.merchantId,
                  idItem: element?.prizeId,
                }}
              >
                {value}
              </Link>
            </td>
          );
        }

        if (key === "action") {
          return (
            <td key={key} className="py-4 px-4 font-medium">
              {value.split("_").join(" ")}
            </td>
          );
        }

        if (key === "isPublished") {
          return (
            <td key={key} className="py-4 px-4 font-medium">
              {element.isPublished ? "Yes" : "No"}
            </td>
          );
        }

        if (
          key === "createdAt" ||
          key === "updatedAt" ||
          key === "lastActivityDate" ||
          key === "expiredAt" ||
          key === "endDate" ||
          key === "startDate" ||
          key === "archivedAt"
        ) {
          if (!value)
            return <td key={key} className="py-4 px-4 font-medium"></td>;

          if (key === "endDate") {
            return (
              <td key={key} className="py-4 px-4 font-medium">
                {transformDate(getNextDayEndDate(value))}
              </td>
            );
          }

          return (
            <td key={key} className="py-4 px-4 font-medium">
              {transformDate(value)}
            </td>
          );
        }

        if (key === "lastLogin") {
          if (!value)
            return <td key={key} className="py-4 px-4 font-medium"></td>;

          return (
            <td key={key} className="py-4 px-4 font-medium">
              {transformDate(value)}
            </td>
          );
        } else {
          return (
            <td key={key} className="py-4 px-4 font-medium">
              {value}
            </td>
          );
        }
      }
    });
  }, []);

  return (
    <tbody>
      {tableData?.map((element: any) => (
        <Fragment
          key={
            element.id ||
            element.prizeId ||
            element.categoryId ||
            (origin === "areas" && element.zipCode) ||
            element.dmaCode
          }
        >
          <tr
            id={
              element.id ||
              element.prizeId ||
              element.categoryId ||
              element.dmaCode
            }
            className={`bg-white ${
              idTableShow.includes(
                element.id ||
                  element.prizeId ||
                  element.categoryId ||
                  element.dmaCode
              ) &&
              origin === "merchant_items" &&
              "border-l-4 border-primary-purple"
            }`}
          >
            <CheckboxTable
              element={element}
              hasCheckbox={hasCheckbox}
              idTableShow={idTableShow}
              isCheck={isCheck}
              handleClick={handleClick}
            />

            {handleTableData(element)}

            {showButtonOption && (
              <ButtonOptionsTable
                element={element}
                options={options}
                typeOptions={typeOptions}
                origin={origin}
                dropdown={dropdown}
                idTableShow={idTableShow}
                setIdTableShow={setIdTableShow}
                setMerchantsStatus={setMerchantsStatus}
              />
            )}
          </tr>
          {hasSubTable && idTableShow.includes(element.prizeId) && (
            <TableSub
              subHeaders={subHeaders}
              element={element}
              additionalData={additionalData}
            />
          )}
        </Fragment>
      ))}
    </tbody>
  );
}

export default memo(TableBody);
