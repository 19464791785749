import {Suspense, lazy, useEffect, useMemo, useState} from "react";
import {withErrorBoundary} from "react-error-boundary";
import {toast} from "react-toastify";

// Components
import Table from "components/Table/Table";
import Filter from "components/Filter";
import Button from "components/Common/Button";
import Modal from "components/Modal";
import ErrorFallback from "components/ErrorFallback";

// Redux
import type {RootState} from "store/store";
import {setRefetchEndpoint} from "store/user/userSlice";
import {setMerchantsName} from "store/merchants/merchantsSlice";
import {useDispatch, useSelector} from "react-redux";
import {
  useChangeStatusMerchantMutation,
  useGetAllMerchantsNameQuery,
  useGetAllMerchantsQuery,
} from "store/api/merchantsApi";

// Utils
import {TABLE_MERCHANT_ACTUAL_HEADER} from "utils/merchantHeaders";
import FormSqueleton from "utils/FormSqueleton";

// Assets
import ArchiveModal from "components/Modal/ArchiveModal";
import routes from "utils/routesByRole";
import Profile from "assets/Profile";
import AddOffer from "assets/AddOffer";
import Archive from "assets/Archive";
import {setSorting} from "store/filters/filtersSlice";

const FilterFormMerchants = lazy(
  () => import("components/Filter/FilterFormMerchants")
);

function ActualMerchantsComponent() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user.user);
  const filters = useSelector((state: RootState) => state?.filters?.filters);
  const modal = useSelector((state: RootState) => state.modal);
  const tabsAndPage = useSelector((state: RootState) => state.tabs);

  const [elementsChecked, setElementsChecked] = useState<number[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [params, setParams] = useState("?ordering=id");

  useEffect(() => {
    dispatch(setSorting("ordering=id"));
  }, []);

  const {
    data: dataMerchants,
    isLoading: isLoadingMerchants,
    isError: isErrorMerchants,
    refetch,
  } = useGetAllMerchantsQuery(params);

  const [setMerchantsStatus] = useChangeStatusMerchantMutation();

  const {
    data: dataMerchantsName,
    isLoading: isLoadingMerchantsName,
    isError: isErrorMerchantsName,
  } = useGetAllMerchantsNameQuery();

  useEffect(() => {
    if (dataMerchantsName && dataMerchantsName.length) {
      dispatch(setMerchantsName(dataMerchantsName));
    }
  }, [dataMerchantsName]);

  useEffect(() => {
    if (user.refetchEndpoint) {
      setTimeout(() => {
        refetch();
      }, 300);
      dispatch(setRefetchEndpoint(false));
      // dispatch(merchantsApi.util.invalidateTags(["Merchant"]));
    }
  }, [user]);

  useEffect(() => {
    setParams(
      `?${filters.sorting}${filters.page}${filters.limit}${filters.urlSearch}${filters.urlFilters}`
    );
  }, [filters]);

  const handleItemChecked = (items: number[]) => {
    setElementsChecked(items);
  };

  const ITEMS = useMemo(
    () => [
      // @ts-ignore
      routes[user.data.role].urlAllowed?.merchant?.includes("view") && {
        title: "View Profile",
        svg: <Profile classes="mr-3" />,
        type: "redirectToMerchant",
      },
      // @ts-ignore
      routes[user.data.role].urlAllowed?.item?.includes("add") && {
        title: "Add Item",
        svg: <AddOffer classes="mr-3" />,
        type: "redirectToItems",
      },
      // @ts-ignore
      routes[user.data.role].urlAllowed?.merchant?.includes("archive") && {
        title: "Archive",
        svg: <Archive classes="mr-3" />,
        type: "archived",
      },
    ],
    []
  );

  return (
    <>
      <hr />
      <div className="mt-3">
        <Filter
          showFilter={showFilter}
          handleShowFilter={setShowFilter}
          elementsChecked={elementsChecked}
          setElementsChecked={setElementsChecked}
          setMerchantsStatus={setMerchantsStatus}
          optionsDropdownCheckbox={["new", "published", "unverified"]}
          optionsAllDropdownCheckbox="All Types"
          origin="merchants"
          searchBarPlaceholder="Search by Merchants name, location, ID, zip-code"
        />
        {showFilter && (
          <Suspense fallback={<FormSqueleton />}>
            <FilterFormMerchants handleShowFilter={setShowFilter} />
          </Suspense>
        )}
      </div>
      <Table
        data={
          dataMerchants !== undefined &&
          dataMerchants.retailers &&
          !user.refetchEndpoint
            ? dataMerchants?.retailers
            : []
        }
        loadingData={isLoadingMerchants}
        errorData={isErrorMerchants}
        refetch={refetch}
        results={dataMerchants !== undefined ? dataMerchants?.count : 1}
        origin="merchants"
        headers={TABLE_MERCHANT_ACTUAL_HEADER}
        show={[
          "id",
          "name",
          "zip",
          "createdAt",
          "prizeCount",
          "subscriberCount",
          "lastActivityDate",
          "status",
        ]}
        sort={{
          id: null,
          name: null,
          zip: null,
          createdAt: null,
          prizeCount: null,
          subscriberCount: null,
          lastActivityDate: null,
          status: null,
        }}
        itemOptions={ITEMS}
        typeOptions="group"
        handleItemChecked={handleItemChecked}
        countPagination={
          tabsAndPage.tabs.lastVisitedPage
            ? +tabsAndPage.tabs.lastVisitedPage
            : 0
        }
        urlPagination={{origin: "merchant", url: "?ordering=id"}}
        setMerchantsStatus={setElementsChecked}
        orderingByAPI={true}
      />
      {modal.type === "archiveMerchant" && (
        <ArchiveModal
          elementsChecked={elementsChecked}
          setElementsChecked={setElementsChecked}
          promise={setMerchantsStatus}
          type={"Merchant"}
        />
      )}
    </>
  );
}

const ActualMerchants = withErrorBoundary(ActualMerchantsComponent, {
  FallbackComponent: ErrorFallback,
  onError(error, info) {
    // Do something with the error
    // E.g. log to an error logging client here
  },
});

export default ActualMerchants;
