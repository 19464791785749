import {
  useEffect,
  useState,
  FocusEvent,
  Fragment,
  useImperativeHandle,
  forwardRef,
} from "react";
import difference from "lodash.difference";
import {toast} from "react-toastify";
import {Dialog, Switch} from "@headlessui/react";
import {jsPDF} from "jspdf";

// Types
import {IState, ICity} from "country-state-city";

// Components
import Input from "components/Common/Input";
import Dropzone from "components/Common/Input/Dropzone";
import Button from "components/Common/Button";
import Subtitle from "components/Common/Subtitle";
import Modal from "components/Modal";
import PlacesAutocomplete from "components/Common/Input/PlacesAutoComplete";

// Assets
import QRCode from "assets/QRCode";
import Download from "assets/Download";

// Redux
import {
  useAcceptImageMutation,
  useChangeImageMerchantMutation,
  useChangeLoyalityProgramMutation,
  useChangeStatusMerchantMutation,
  useDeclineImageMutation,
  useLazyGetMerchantInfoYelpGoogleQuery,
  useUpdateMerchantMutation,
} from "store/api/merchantsApi";
import Alert from "assets/Alert";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store/store";

// Utils
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {useGetFormattedAdress} from "hooks/useGetFormattedAddress";
import ChangeQRCode from "components/Modal/ChangeQRCode";
import {setImageModal, setOpenModal, setType} from "store/modal/modalSlice";
import routes from "utils/routesByRole";
import Select from "components/Common/Input/Select";
import DropdownCategory from "components/Common/DropdownCategory";
import {
  useGetCategoriesQuery,
  useGetCategoriesTreeQuery,
} from "store/api/categoriesApi";
import {
  findMatches,
  formatArrayToAMPM,
  getFilteredNames,
  transformTimeFormat,
  transformTimeFormatAMPM,
  updateWorkingHours,
} from "utils/workingHoursUtils";
import Tabs from "components/Common/Tabs";
import Search from "assets/Search";
import {ArrowNext} from "assets/Arrows";
import {Tooltip} from "react-tooltip";
import Checkbox from "components/Common/Checkbox";
import {addDays} from "utils/addDays";
import {getTodayDate} from "utils/getTodayDate";
import DatePicker from "components/Common/Input/DatePicker";
import {getRoundedDate} from "utils/diffenceDate";

interface MerchantProfileTypes {
  merchantInfo: {
    id: number;
    name: string;
    email: string;
    state: string;
    city: string;
    zip: string;
    address: string;
    address2: string;
    sizeType: string;
    isGp: boolean;
    status: string;
    socialReview: {
      url: string;
    };
    ownerFirstName: string;
    ownerLastName: string;
    website: string;
    images: any;
    phone: string;
    geoLocation: string;
    qrCode: any;
    workingHours: any[];
    categories: any[];
    placement: string;
    loyaltyToggle: boolean;
    loyaltyDuration: number;
    loyaltyStartDate: string;
    loyaltyEndDate: string;
    newData: {
      name: string;
      email: string;
      state: string;
      city: string;
      zip: string;
      address: string;
      address2: string;
      isGp: boolean;
      socialReview: {
        url: string;
      };
      images: any;
      geoLocation: string;
      ownerFirstName: string;
      ownerLastName: string;
      website: string;
      sizeType: string;
      phone: string;
      workingHours: any[];
      categories: any[];
      placement: string;
      loyaltyToggle: boolean;
      loyaltyDuration: number;
      loyaltyStartDate: string;
      loyaltyEndDate: string;
    };
  };
  refetch: any;
  handleChangeBtnAllChecked: any;
  setApprovedItems: any;
  setDeclinedItems: any;
  newDataCheckbox: any;
  setNewDataCheckbox: any;
}
const MerchantDetails = forwardRef(
  (
    {
      merchantInfo,
      refetch,
      handleChangeBtnAllChecked,
      setApprovedItems,
      setDeclinedItems,
      newDataCheckbox,
      setNewDataCheckbox,
    }: MerchantProfileTypes,
    ref: any
  ) => {
    const modal = useSelector((state: RootState) => state.modal);
    const user = useSelector((state: RootState) => state.user.user.user);

    const {ready: autoCompleteAddressReady} = usePlacesAutocomplete();

    const [getFormattedAddress, addressDetais] = useGetFormattedAdress();

    const dispatch = useDispatch();
    const [userData, setUserData] = useState({
      id: 0,
      name: "",
      email: "",
      state: "",
      city: "",
      zip: "",
      isGp: false,
      address1: "",
      address2: "",
      sizeType: "",
      socialReview: {url: ""},
      categories: [],
      phone: "",
      geoLocation: "",
      ownerFirstName: "",
      ownerLastName: "",
      website: "",
    });

    const [workingHours, setWorkingHours] = useState<any>([
      {
        day: 0,
        start: "",
        end: "",
        isOvernight: false,
        isClosed: true,
      },
      {
        day: 1,
        start: "",
        end: "",
        isOvernight: false,
        isClosed: true,
      },
      {
        day: 2,
        start: "",
        end: "",
        isOvernight: false,
        isClosed: true,
      },
      {
        day: 3,
        start: "",
        end: "",
        isOvernight: false,
        isClosed: true,
      },
      {
        day: 4,
        start: "",
        end: "",
        isOvernight: false,
        isClosed: true,
      },
      {
        day: 5,
        start: "",
        end: "",
        isOvernight: false,
        isClosed: true,
      },
      {
        day: 6,
        start: "",
        end: "",
        isOvernight: false,
        isClosed: true,
      },
    ]);

    const [extraOption, setExtraOption] = useState<{
      isGp: boolean;
      isInTrendingPlace: boolean;
      placement: string;
      isInLoyalityProgram: boolean;
      loyalityProgram: number;
      loyaltyStartDate: string | null;
      loyaltyEndDate: string | null;
    }>({
      isGp: false,
      isInTrendingPlace: false,
      placement: "",
      isInLoyalityProgram: false,
      loyalityProgram: 0,
      loyaltyStartDate: "",
      loyaltyEndDate: "",
    });

    const [hasBeenRequiredByUser, setHasBeenRequiredByUser] = useState(false);

    const [photosCategories, setPhotosCategories] = useState([]);
    const [dropdownSearchFilter, setDowpdownSearchFilter] = useState("");

    const [firstImage, setFirstImg] = useState("");
    const [secondImage, setSecondImg] = useState("");
    const [thirdImage, setThirdImg] = useState("");

    const [googleZipCode, setHasGoogleZipCode] = useState(true);
    const [addressAutoComplete, setAddressAutoComplete] = useState("");

    const [imageCropModalType, setImageCropModalType] = useState("default");

    const [newImageUpload, setNewImageUpload] = useState<string[]>([]);

    const [arrayImageToUpload, setArrayImageToUpload] = useState<any>([]);
    const [objectImages, setObjectImages] = useState<{
      logoLarge: any;
      profile: any;
      interior: any;
    }>({
      logoLarge: "",
      profile: "",
      interior: "",
    });

    const [states, setStates] = useState<{selected: string; values: IState[]}>({
      selected: "",
      values: [],
    });
    const [cities, setCities] = useState<{selected: string; values: ICity[]}>({
      selected: "",
      values: [],
    });
    const [typeQRCode, setTypeQRCode] = useState("");
    const [validQR, setValidQR] = useState<any>({});
    const [validQRLoyalty, setValidQRLoyalty] = useState<any>({});
    const [inputError, setInputError] = useState(false);

    const [dropdown, openDropdown] = useState(false);
    const [elementsChecked, setElementsChecked] = useState<string[]>([]);
    const [elementsName, setElementsName] = useState<string[]>([]);
    const [elementsParentName, setElementsParentName] = useState<any[]>([]);
    const [isCheckedForModal, setIsCheckedForModal] = useState(false);

    const [typeInputModalDropzone, setTypeInputModalDropzone] = useState("");

    const [isInputDisabled] = useState(() => {
      //  @ts-ignore
      return routes[user.data.role].urlAllowed?.merchant?.includes("edit")
        ? false
        : true;
    });

    const {
      data: dataCategories,
      isLoading: isLoadingCategories,
      isError: isErrorCategories,
      error,
      // @ts-ignore
    } = useGetCategoriesTreeQuery();

    const [
      getMerchantInfoYelpGoogle,
      {
        data: dataInfoYelpGoogle,
        isLoading: isLoadingInfoYelpGoogle,
        isError: isErrorInfoYelpGoogle,
      },
    ] = useLazyGetMerchantInfoYelpGoogleQuery();

    useEffect(() => {
      if (
        merchantInfo?.newData ||
        merchantInfo?.images[0]?.hasOwnProperty("newName") ||
        merchantInfo?.images[1]?.hasOwnProperty("newName") ||
        merchantInfo?.images[2]?.hasOwnProperty("newName")
      ) {
        let resultDeclinedItems: any = [];
        let resultApprovedItems: any = [];

        if (merchantInfo?.newData) {
          resultDeclinedItems = Object?.keys(merchantInfo?.newData).filter(
            (val) => newDataCheckbox?.includes(val)
          );

          resultApprovedItems = Object?.keys(merchantInfo?.newData).filter(
            (val) => !newDataCheckbox?.includes(val)
          );
        }

        const resultNewImages: any[] = [];

        merchantInfo?.images?.forEach((image: any) => {
          if (
            image.hasOwnProperty("newName") ||
            image.hasOwnProperty("newUrl")
          ) {
            if (image.type === "INTERIOR") {
              resultNewImages.push("image#INTERIOR");
            } else if (image.type === "PROFILE") {
              resultNewImages.push("image#PROFILE");
            } else {
              resultNewImages.push("image#LOGO_LARGE");
            }
          }
        });

        const resultDeclinedImages = resultNewImages.filter((image: any) =>
          newDataCheckbox.includes(image)
        );
        const resultApprovedImages = resultNewImages.filter(
          (image: any) => !newDataCheckbox.includes(image)
        );

        if (newDataCheckbox.length > 0) {
          handleChangeBtnAllChecked(false);
        } else {
          handleChangeBtnAllChecked(true);
        }

        setDeclinedItems(resultDeclinedItems.concat(resultDeclinedImages));
        setApprovedItems(resultApprovedItems.concat(resultApprovedImages));
      }
    }, [merchantInfo, newDataCheckbox]);

    useImperativeHandle(ref, () => ({
      async handleSubmit() {
        if (
          !userData.name ||
          !userData.email ||
          !userData.zip ||
          !userData.address1 ||
          !userData.sizeType ||
          elementsChecked.length === 0 ||
          !secondImage ||
          !thirdImage ||
          !userData.ownerFirstName ||
          !userData.ownerLastName
        ) {
          setInputError(true);
          toast.error("You must fill all the required fields");
          return;
        }

        setImageCropModalType("default");

        if (
          extraOption.isInLoyalityProgram !== merchantInfo?.loyaltyToggle ||
          extraOption.loyalityProgram !== merchantInfo?.loyaltyDuration
        ) {
          const toastPromiseLoyality = toast.promise(
            loyalityProgramMutation({
              id: userData?.id,
              data: {
                loyaltyToggle: extraOption.isInLoyalityProgram,
                // @ts-ignore
                loyaltyDuration: parseInt(extraOption.loyalityProgram),
              },
            }),
            {}
          );

          await toastPromiseLoyality
            .then((res: any) => {
              if (res.error) {
              } else {
              }
            })
            .catch((err) => {
              toast.error("Error while changing status item");
            });
        }

        const newWorkingHours = formatArrayToAMPM(workingHours);

        const arrCategoriesID = elementsChecked?.map((el: any, key: any) => {
          if (el === "all") {
            return dataCategories.map(
              (category: {id: number; name: string}) => {
                // @ts-ignore
                return {id: parseInt(category.id), name: category.name};
              }
            );
          } else {
            return {id: parseInt(el), name: elementsName[key]};
          }
        });

        let categoryArr = [];

        if (arrCategoriesID[0]?.length > 1) {
          categoryArr = [...arrCategoriesID[0]];
        } else {
          categoryArr = arrCategoriesID;
        }

        // Check if newData exist, if exist check if the checkbox is clicked, if not send the value on input.
        let data: any = {
          ...userData,
          city: merchantInfo?.newData?.address
            ? newDataCheckbox.includes("Address1.")
              ? userData.city
              : merchantInfo?.city
            : userData.city,
          // @ts-ignore
          state: merchantInfo?.newData?.address
            ? newDataCheckbox.includes("Address1.")
              ? userData.state
              : merchantInfo?.state
            : userData.state,
          zip: merchantInfo?.newData?.address
            ? newDataCheckbox.includes("Address1.")
              ? userData.zip
              : merchantInfo?.zip
            : userData.zip,
          name: merchantInfo?.newData?.name
            ? newDataCheckbox.includes("MerchantName")
              ? userData.name
              : merchantInfo?.name
            : userData.name,
          address: merchantInfo?.newData?.address
            ? newDataCheckbox.includes("Address1.")
              ? userData.address1
              : merchantInfo?.address
            : userData.address1,
          address2: merchantInfo?.newData?.address2
            ? newDataCheckbox.includes("Address2.")
              ? userData.address2
              : merchantInfo?.address2
            : userData.address2,
          sizeType: merchantInfo?.newData?.sizeType
            ? newDataCheckbox.includes("sizeType")
              ? userData.sizeType
              : merchantInfo?.sizeType
            : userData.sizeType,
          socialReview: {
            url:
              merchantInfo?.newData?.socialReview &&
              merchantInfo?.newData?.socialReview.hasOwnProperty("url")
                ? newDataCheckbox.includes("YelporGoogleLink")
                  ? userData?.socialReview?.url
                  : merchantInfo?.socialReview?.url
                : userData?.socialReview?.url,
          },
          geoLocation: merchantInfo?.newData?.geoLocation
            ? newDataCheckbox.includes("Address1.")
              ? userData.geoLocation
              : merchantInfo?.geoLocation
            : userData.geoLocation,

          categories: merchantInfo?.newData?.categories
            ? newDataCheckbox.includes("Categories")
              ? arrCategoriesID
              : merchantInfo?.categories
            : arrCategoriesID,

          isGp: extraOption.isGp,
          // isGp: merchantInfo?.newData?.geoLocation
          // ? newDataCheckbox.includes("Address1.")
          //   ? userData.geoLocation
          //   : merchantInfo?.geoLocation
          // : userData.geoLocation,
        };

        delete data.address1;

        if (extraOption.isInTrendingPlace) {
          data.placement = merchantInfo?.newData?.placement
            ? newDataCheckbox.includes("Placement")
              ? parseInt(extraOption.placement)
              : merchantInfo?.placement
            : parseInt(extraOption.placement);
        }

        // if (extraOption.isInLoyalityProgram) {
        //   data.loyaltyToggle = true;
        //   // @ts-ignore
        //   data.loyaltyDuration = parseInt(extraOption.loyalityProgram);
        // }

        const sendWorkingHours = workingHours.some(
          (day: any) => day.isClosed === false
        );

        if (sendWorkingHours) {
          data.workingHours = merchantInfo?.newData?.workingHours
            ? newDataCheckbox.includes("WorkingHour")
              ? newWorkingHours
              : merchantInfo?.workingHours
            : newWorkingHours;
        }

        // @ts-ignore
        delete data.id;

        const toastPromise = toast.promise(
          updateMerchant({id: userData.id, data}),
          {
            pending: "Updating Merchant",
          }
        );

        await toastPromise
          .then((res: any) => {
            if (res.error) {
              toast.error(
                <div>
                  <h2>Error while updating merchant</h2>
                  <p className="text-xs"> {res?.error?.data?.message} </p>
                </div>
              );
            } else {
              toast.success("Merchant Updated");

              const imageTypes = ["PROFILE", "INTERIOR", "LOGO_LARGE"];

              const differencesBtwArrays = difference(
                imageTypes,
                newDataCheckbox
              );

              // Accept image id
              if (newDataCheckbox.length) {
                newDataCheckbox.map((data: any) => {
                  merchantInfo?.images.find(
                    (image: {
                      imageId: number;
                      name: string;
                      type: string;
                      newName: string;
                      newUrl: string;
                      url: string;
                    }) => {
                      if (image.type === data) {
                        acceptImageMerchant({idImage: image.imageId});
                        setNewDataCheckbox([]);
                      }
                    }
                  );
                });

                // Reject image
                if (newDataCheckbox.length && differencesBtwArrays) {
                  differencesBtwArrays?.map((imageRecused) => {
                    merchantInfo?.images.find(
                      (image: {
                        imageId: number;
                        name: string;
                        type: string;
                        newName: string;
                        newUrl: string;
                        url: string;
                      }) => {
                        if (image.type === imageRecused) {
                          declineImageMerchant({idImage: image.imageId});
                        }
                      }
                    );
                  });
                }
              } else {
                differencesBtwArrays?.map((imageRecused) => {
                  merchantInfo?.images.find(
                    (image: {
                      imageId: number;
                      name: string;
                      type: string;
                      newName: string;
                      newUrl: string;
                      url: string;
                    }) => {
                      if (image.type === imageRecused) {
                        declineImageMerchant({idImage: image.imageId});
                      }
                    }
                  );
                });
              }

              // if()
            }
          })
          .catch((err) => {
            toast.error("Error while updating merchant");
          });

        arrayImageToUpload.map((imagesToUpload: any) => {
          updateImageMerchant(imagesToUpload);
        });
      },
    }));

    const [
      updateMerchant,
      {
        data: dataMerchantUpdated,
        isLoading: isLoadingMerchantUpdated,
        isError: isErrorMerchantUpdated,
        status: statusMerchantUpdated,
      },
    ] = useUpdateMerchantMutation();

    const [updateImageMerchant] = useChangeImageMerchantMutation();
    const [loyalityProgramMutation] = useChangeLoyalityProgramMutation();

    const [acceptImageMerchant] = useAcceptImageMutation();
    const [declineImageMerchant] = useDeclineImageMutation();

    useEffect(() => {
      if (dataCategories && merchantInfo && !isLoadingCategories) {
        const names: string[] = [];
        const checked: string[] = [];

        if (merchantInfo?.newData?.categories) {
          merchantInfo?.newData?.categories?.map(
            (category: {id: string; name: string}) => {
              checked.push(category.id.toString());
              names.push(category.name.toLowerCase());
            }
          );

          setElementsChecked(checked);
          setElementsName(names);
        } else {
          merchantInfo?.categories?.map(
            (category: {id: string; name: string}) => {
              checked.push(category.id.toString());
              names.push(category.name.toLowerCase());
            }
          );

          setElementsChecked(checked);
          setElementsName(names);
        }
      }
    }, [merchantInfo, dataCategories, isLoadingCategories]);

    useEffect(() => {
      if (!dataCategories) return;
      const res = elementsChecked.map((id: any, index: number) => {
        let rootParentElement;

        const currentElement = findItemById(dataCategories, id.toString());

        if (!currentElement?.parentId) {
          return {
            id: elementsChecked[index],
            root: currentElement?.name,
            rootId: currentElement?.id,
          };
        }

        const parentElement = findItemById(
          dataCategories,
          currentElement?.parentId?.toString()
        );

        if (parentElement?.parentId) {
          rootParentElement = findItemById(
            dataCategories,
            parentElement?.parentId?.toString()
          );
        }

        // Save the root parent element and the parent element nested
        if (rootParentElement) {
          return {
            id: elementsChecked[index],
            root: rootParentElement?.name,
            rootId: rootParentElement?.id,
            parent: parentElement?.name,
            parentId: parentElement?.id,
            child: currentElement?.name,
            childId: currentElement?.id,
          };
        } else {
          return {
            id: elementsChecked[index],
            parent: parentElement?.name,
            parentId: parentElement?.id,
            child: currentElement?.name,
            childId: currentElement?.id,
          };
        }
      });

      setElementsParentName(res);
    }, [elementsChecked, dataCategories]);

    useEffect(() => {
      //@ts-ignore
      if (!addressDetais?.address_components?.length) return;

      getFullAddress();
    }, [addressDetais]);

    const getFullAddress = async () => {
      //@ts-ignore
      const addressData = addressDetais.address_components;

      const getAddressData = (fieldName: string): string => {
        const data = addressData.find((item: any) =>
          item.types.includes(fieldName)
        )?.long_name;
        return data || "";
      };

      const stateAbbreviation = addressData.find((item: any) =>
        item.types.includes("administrative_area_level_1")
      ).short_name;

      const data = {
        street: getAddressData("route"),
        streetNumber: getAddressData("street_number"),
        state: getAddressData("administrative_area_level_1"),
        city: getAddressData("locality"),
        zipCode: getAddressData("postal_code"),
      };

      const description =
        data.streetNumber +
        " " +
        data.street +
        ", " +
        data.city +
        ", " +
        data.state;

      await getGeocode({address: description}).then((results) => {
        const {lat, lng} = getLatLng(results[0]);

        setHasGoogleZipCode(data.zipCode ? true : false);

        setStates({...states, selected: stateAbbreviation});

        setUserData({
          ...userData,
          address1:
            data.streetNumber + `${data.streetNumber ? " " : ""}` + data.street,
          state: data.state,
          city: data.city,
          zip: data.zipCode,
          geoLocation: `${lat},${lng}`,
        });
      });
    };

    const getGeoCodeAutocomplete = async ({data}: any) => {
      await getGeocode({address: addressAutoComplete}).then((results) => {
        const {lat, lng} = getLatLng(results[0]);

        setUserData({
          ...userData,
          address1: data.streetNumber + " " + data.street + ", " + data.city,
          state: data.state,
          city: data.city,
          zip: data.zipCode,
          geoLocation: `${lat},${lng}`,
        });
      });
    };

    const getYelpImagesFromSource = async (source: string) => {
      const toastPromise = toast.promise(
        getMerchantInfoYelpGoogle(encodeURIComponent(`${source}`)),
        {}
      );

      await toastPromise.then((res: any) => {
        if (res.error) {
          // toast.error(
          //   <div>
          //     <h2>Error while getting images from yelp</h2>
          //     <p className="text-xs"> {res?.error?.data?.message} </p>
          //   </div>
          // );
        } else {
          setPhotosCategories(res.data.photos);
        }
      });
    };

    const uniqueArray = (array: any[]) => {
      let result = array.filter((value: number, index: number, self: any) => {
        return self.indexOf(value) === index;
      });

      return result;
    };

    const findItemById = (items: any[], itemId: string): any | null => {
      for (const item of items) {
        if (item.id.toString() === itemId) {
          return item;
        }
        if (item.subItems && item.subItems.length > 0) {
          const foundItem = findItemById(item.subItems, itemId); // Recursively search in subItems
          if (foundItem) {
            return foundItem;
          }
        }
      }
      return null;
    };

    const handleClickCheckboxCategory = (e: any) => {
      const {id, name, checked} = e.target;

      if (id === "all") {
        if (checked) {
          setElementsChecked(["all"]);
          setElementsName(["All"]);
        } else {
          setElementsChecked([]);
          setElementsName([]);
        }
      } else {
        const subItemIds = [id];
        const subItemNames = [name];

        const toggleCheckbox = (items: any[]) => {
          items.forEach((item: any) => {
            subItemIds.push(item.id.toString());
            subItemNames.push(item.name);

            // if (item.subItems && item.subItems.length > 0) {
            //   toggleCheckbox(item.subItems); // Recursively call toggleCheckbox for subItems
            // }
          });
        };

        const findItemById = (items: any[], itemId: string): any | null => {
          for (const item of items) {
            if (item.id.toString() === itemId) {
              return item;
            }
            if (item.subItems && item.subItems.length > 0) {
              const foundItem = findItemById(item.subItems, itemId); // Recursively search in subItems
              if (foundItem) {
                return foundItem;
              }
            }
          }
          return null;
        };

        const category = findItemById(dataCategories, id);

        if (category) {
          toggleCheckbox([category]);
        }

        if (checked) {
          if (uniqueArray(elementsChecked).length >= 3) {
            toast.info("You can only select up to 3 categories");
            return;
          }
          setElementsChecked((prevElementsChecked: string[]) => {
            return uniqueArray([...prevElementsChecked, ...subItemIds]);
          });
          setElementsName((prevElementsName: string[]) => {
            return uniqueArray([...prevElementsName, ...subItemNames]);
          });
        } else {
          setElementsChecked((prevElementsChecked: string[]) => {
            return uniqueArray(
              prevElementsChecked.filter(
                (item: string) => !subItemIds?.includes(item)
              )
            );
          });
          setElementsName((prevElementsName: string[]) => {
            return uniqueArray(
              prevElementsName.filter(
                (item: string) => !subItemNames?.includes(item)
              )
            );
          });
        }
      }
    };
    const handleClickCheckbox = (e: any) => {
      const {id, checked} = e.target;

      if (id === "address") {
        // add address, zip, city, state, geoLocation to newDataCheckbox
        setNewDataCheckbox([
          ...newDataCheckbox,
          id,
          "zip",
          "city",
          "state",
          "geoLocation",
        ]);

        if (!checked) {
          // Filter and remove the address, zip, city, state, geoLocation from newDataCheckbox
          setNewDataCheckbox(
            newDataCheckbox.filter(
              (item: any) =>
                item !== id &&
                item !== "zip" &&
                item !== "city" &&
                item !== "state" &&
                item !== "geoLocation"
            )
          );
        }

        return;
      }

      setNewDataCheckbox([...newDataCheckbox, id]);

      if (!checked) {
        setNewDataCheckbox(newDataCheckbox.filter((item: any) => item !== id));
      }
    };

    const handleClickDropdown = (e: any, type: string) => {
      e.preventDefault();
      if (type === "save") {
        openDropdown(false);
        return;
      }

      setElementsChecked([]);
      setElementsName([]);
    };

    useEffect(() => {
      if (merchantInfo) {
        setUserData({
          id: merchantInfo?.id,
          name: merchantInfo?.newData?.name || merchantInfo?.name,
          email: merchantInfo?.newData?.email || merchantInfo?.email,
          state: merchantInfo?.newData?.state || merchantInfo?.state,
          city: merchantInfo?.newData?.city || merchantInfo?.city,
          zip: merchantInfo?.newData?.zip || merchantInfo?.zip,
          isGp: merchantInfo?.newData?.isGp || merchantInfo?.isGp,
          address1: merchantInfo?.newData?.address || merchantInfo?.address,
          address2: merchantInfo?.newData?.hasOwnProperty("address2")
            ? merchantInfo?.newData?.address2
            : merchantInfo?.address2,
          sizeType: merchantInfo?.newData?.sizeType || merchantInfo?.sizeType,
          socialReview:
            merchantInfo?.newData?.socialReview || merchantInfo?.socialReview,
          // {
          //   url:
          //     (merchantInfo?.newData?.socialReview?.hasOwnProperty("url") &&
          //       merchantInfo?.newData?.socialReview.url) ||
          //     merchantInfo?.socialReview?.url,
          // },
          phone: merchantInfo?.newData?.phone || merchantInfo?.phone,
          geoLocation:
            merchantInfo?.newData?.geoLocation || merchantInfo?.geoLocation,
          ownerFirstName:
            merchantInfo?.newData?.ownerFirstName ||
            merchantInfo?.ownerFirstName,
          ownerLastName:
            merchantInfo?.newData?.ownerLastName || merchantInfo?.ownerLastName,
          website: merchantInfo?.newData?.hasOwnProperty("website")
            ? merchantInfo?.newData?.website
            : merchantInfo?.website,
          // @ts-ignore
          categories:
            merchantInfo?.newData?.categories || merchantInfo?.categories,
        });

        const newWorkingHours = (
          merchantInfo?.newData?.workingHours ||
          merchantInfo?.workingHours ||
          workingHours
        ).map((day: any) => {
          return {
            day: day.day,
            start: day.start.includes(":")
              ? transformTimeFormatAMPM(day.start)
              : transformTimeFormat(day.start),
            end: day.end.includes(":")
              ? transformTimeFormatAMPM(day.end)
              : transformTimeFormat(day.end),
            isOvernight: day.isOvernight,
            isClosed: day.isClosed,
          };
        });

        const updatedWH = updateWorkingHours(workingHours, newWorkingHours);

        setWorkingHours(updatedWH ? updatedWH : workingHours);

        setExtraOption({
          ...extraOption,
          isGp: merchantInfo?.newData?.hasOwnProperty("isGp")
            ? merchantInfo?.newData?.isGp
            : merchantInfo?.isGp,
          placement: merchantInfo?.newData?.hasOwnProperty("placement")
            ? merchantInfo?.newData?.placement?.toString() === "0"
              ? ""
              : merchantInfo?.newData?.placement?.toString()
            : merchantInfo?.placement?.toString(),
          isInTrendingPlace: merchantInfo?.newData?.hasOwnProperty("placement")
            ? merchantInfo?.newData?.placement?.toString() === "0"
              ? false
              : !!merchantInfo?.newData?.placement
            : !!merchantInfo?.placement,
          isInLoyalityProgram: merchantInfo?.newData?.loyaltyToggle
            ? merchantInfo?.newData?.loyaltyToggle
            : merchantInfo?.loyaltyToggle,
          loyalityProgram: merchantInfo?.newData?.loyaltyDuration
            ? merchantInfo?.newData?.loyaltyDuration
            : merchantInfo?.loyaltyDuration || 0,
          loyaltyEndDate: merchantInfo?.newData?.loyaltyEndDate
            ? merchantInfo?.newData?.loyaltyEndDate
            : merchantInfo?.loyaltyEndDate,
        });

        getYelpImagesFromSource(
          merchantInfo?.newData?.socialReview?.url ||
            merchantInfo?.socialReview?.url
        );

        merchantInfo?.images.map(
          (image: {
            imageId: number;
            name: string;
            type: string;
            newName: string;
            newUrl: string;
            url: string;
          }) => {
            if (image.type.toLowerCase() === "logo_large") {
              setFirstImg(image?.newUrl || image?.url);
            } else if (image.type.toLowerCase() === "profile") {
              setSecondImg(image?.newUrl || image?.url);
            } else if (image.type.toLowerCase() === "interior") {
              setThirdImg(image?.newUrl || image?.url);
            }
          }
        );
      }

      if (merchantInfo?.qrCode && merchantInfo?.qrCode?.length > 0) {
        const activeQR = merchantInfo.qrCode.find(
          (qr: any) =>
            qr.status.toLowerCase() === "active" &&
            qr.qrType.toLowerCase() === "redeem"
        );

        const activeQRLoyalty = merchantInfo.qrCode.find(
          (qr: any) =>
            qr.status.toLowerCase() === "active" &&
            qr.qrType.toLowerCase() === "loyalty"
        );

        setValidQR(activeQR);
        setValidQRLoyalty(activeQRLoyalty);
      }
    }, [merchantInfo]);

    useEffect(() => {
      if (
        dataMerchantUpdated === null &&
        !isLoadingMerchantUpdated &&
        !isErrorMerchantUpdated
      ) {
        refetch();
      }
    }, [dataMerchantUpdated, isLoadingMerchantUpdated, isErrorMerchantUpdated]);

    useEffect(() => {
      if (!addressAutoComplete) return;
      //@ts-ignore
      getFormattedAddress(addressAutoComplete);
    }, [addressAutoComplete]);

    useEffect(() => {
      // console.log(objectImages);

      const arrFormData = [
        {...objectImages.logoLarge},
        {...objectImages.profile},
        {...objectImages.interior},
      ];

      setArrayImageToUpload(arrFormData);
    }, [objectImages]);

    useEffect(() => {
      if (!dataInfoYelpGoogle || !Object.keys(dataInfoYelpGoogle).length)
        return;

      const {photos} = dataInfoYelpGoogle;

      if (hasBeenRequiredByUser) {
        const {
          name,
          email,
          phoneNumber,
          address1,
          address2,
          state,
          city,
          zipCode,
          workingHours: workingHoursApi,
          location,
        } = dataInfoYelpGoogle;

        setUserData({
          ...userData,
          name: userData.name && !hasBeenRequiredByUser ? userData.name : name,
          email:
            userData.email && !hasBeenRequiredByUser ? userData.email : email,
          phone:
            userData.phone && !hasBeenRequiredByUser
              ? userData.phone
              : phoneNumber,
          address1:
            userData.address1 && !hasBeenRequiredByUser
              ? userData.address1
              : address1,
          address2,
          state,
          city,
          zip: zipCode,
          geoLocation: location,
        });

        let mappedWorkingHours = workingHours?.map((day: any) => {
          const matchingDay = workingHoursApi?.find(
            (item: any) => item.day === day.day
          );
          if (matchingDay) {
            // If the received working hours include the current day, transform the time format
            return {
              ...day,
              start: transformTimeFormat(matchingDay.start),
              end: transformTimeFormat(matchingDay.end),
              isClosed: false,
            };
          } else {
            // If the received working hours don't include the current day, keep the default values
            return day;
          }
        });

        setWorkingHours(mappedWorkingHours);
      }
      setPhotosCategories(photos);

      setHasBeenRequiredByUser(false);
    }, [dataInfoYelpGoogle]);

    const handleUploadPhoto = (data: any, type: any, showImage: any) => {
      let formData = new FormData();
      if (typeof data !== "string") {
        formData.append("image", data);
      } else {
        formData.append("imageUrl", data);
      }

      formData.append("name", data.name);
      formData.append("type", type);

      let imgUrl;

      if (typeof data !== "string") {
        imgUrl = URL.createObjectURL(data);
      } else {
        imgUrl = data;
      }

      showImage(imgUrl);

      if (type === "LOGO_LARGE") {
        setObjectImages({
          ...objectImages,
          logoLarge: {id: merchantInfo?.id, data: formData},
        });
      }
      if (type === "PROFILE") {
        setObjectImages({
          ...objectImages,
          profile: {id: merchantInfo?.id, data: formData},
        });
      }
      if (type === "INTERIOR") {
        setObjectImages({
          ...objectImages,
          interior: {id: merchantInfo?.id, data: formData},
        });
      }

      // updateImageMerchant({ id: merchantInfo?.id, data: formData });
    };

    const clearAddressDetails = (inputValue: string) => {
      setUserData({
        ...userData,
        address1: inputValue,
        state: "",
        city: "",
        zip: "",
        geoLocation: "",
      });
    };

    const renderQR = ({qrCode}: any) => {
      if (qrCode && Object.keys(qrCode).length > 0) {
        return (
          <img
            src={qrCode.url}
            className="object-contain border rounded-lg"
            width={230}
            height={230}
            alt="Merchant QR Code"
          />
        );
      } else {
        return (
          <div
            style={{width: "230px", height: "230px"}}
            className="flex flex-col justify-center items-center border rounded-lg"
          >
            <p className="mb-2 text-xs text-gray-500 text-center max-w-xs font-medium">
              <span>Your generated code will be displayed here</span>
            </p>
          </div>
        );
      }
    };

    function getDayString(day: number): string {
      switch (day) {
        case 0:
          return "Monday";
        case 1:
          return "Tuesday";
        case 2:
          return "Wednesday";
        case 3:
          return "Thursday";
        case 4:
          return "Friday";
        case 5:
          return "Saturday";
        case 6:
          return "Sunday";
        default:
          return "";
      }
    }

    const handleDownloadQR = ({qrCode}: any) => {
      var doc = new jsPDF();

      doc.addImage(qrCode.url, "PNG", 65, 65, 80, 80);
      doc.save(
        `${merchantInfo?.name?.split(" ")?.join("-")}${
          qrCode?.qrType === "LOYALTY" ? "-loyalty" : ""
        }-qr-code.pdf`
      );
    };

    const getYelpGoogleInfoMerchant = async () => {
      const toastPromise = toast.promise(
        getMerchantInfoYelpGoogle(
          encodeURIComponent(`${userData.socialReview.url}`)
        ),
        {
          pending: "Getting Merchant Information",
        }
      );

      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while getting merchant information</h2>
                <p className="text-xs"> {res?.error?.data?.message} </p>
              </div>
            );
          } else {
            dispatch(setType(""));
            dispatch(setOpenModal(false));
          }
        })
        .catch((err) => {
          toast.error("Error while getting merchant information");
        });
      // Fetch data
    };

    const handleSelectChange = (e: any, day: any) => {
      const selectedValue = e.target.value;
      const updatedWorkingHours = [...workingHours];
      const selectedDay = updatedWorkingHours.find((item) => item.day === day);
      selectedDay.isClosed = selectedValue === "closed";
      setWorkingHours(updatedWorkingHours);
    };

    const handleChangePlacement = (checked: any) => {
      setExtraOption((prev: any) => ({
        ...prev,
        placement: checked === false ? extraOption.placement : "",
        isInTrendingPlace: checked === false ? true : false,
      }));
    };

    const handleChangeLoyalityProgram = (checked: any) => {
      setExtraOption((prev: any) => ({
        ...prev,
        loyalityProgram: checked === false ? extraOption.loyalityProgram : "",
        isInLoyalityProgram: checked === false ? true : false,
      }));
    };

    return (
      <div className="flex mt-5 flex-wrap">
        <div className="bg-white p-5 xl:w-5/7 w-full">
          <form>
            <div className="gap-8 mb-6 flex flex-wrap max-w-full">
              <Subtitle>
                {" "}
                Does This Merchant Have An Account On Google Or Yelp?
              </Subtitle>
              <hr />
              <div className="w-full flex items-end">
                <Input
                  label={"Merchant link Yelp or Google"}
                  id="socialReview"
                  placeholder="Put the merchant’s link profile from Google or Yelp."
                  value={userData?.socialReview?.url}
                  disabled={isInputDisabled}
                  onChange={(e: any) =>
                    setUserData({
                      ...userData,
                      socialReview: {url: e.target.value},
                    })
                  }
                  error={userData?.socialReview?.url.length === 0 && inputError}
                  required={true}
                  classes="min-w-[60%] mr-6"
                  alert={
                    merchantInfo?.newData?.socialReview?.hasOwnProperty("url")
                      ? true
                      : false
                  }
                  classesCheckbox="checkbox-cross"
                  handleClickCheckbox={handleClickCheckbox}
                  checkedValues={newDataCheckbox}
                />
                <Button
                  type="button"
                  variant={
                    !userData?.socialReview?.url ||
                    userData?.socialReview?.url.length < 10 ||
                    isInputDisabled
                      ? "disabled"
                      : "add"
                  }
                  onClick={
                    !userData?.socialReview?.url ||
                    userData?.socialReview?.url.length < 10 ||
                    isInputDisabled
                      ? () => {}
                      : () => {
                          setHasBeenRequiredByUser(true);
                          dispatch(setType("showConfirmFetchYelpGoogleInfo"));
                          dispatch(setOpenModal(true));
                        }
                  }
                >
                  Download Data
                </Button>
              </div>
              <Subtitle> Provide Details</Subtitle>
              <hr />
              <div className="w-full flex gap-4">
                <div className="md:w-full lg:w-4/12">
                  <Input
                    label={"Merchant ID"}
                    value={userData?.id}
                    disabled={true}
                    readOnly={true}
                    classes={"mt-4"}
                  />
                  <Input
                    label={"Merchant Name"}
                    id="name"
                    alert={merchantInfo?.newData?.name ? true : false}
                    classesCheckbox="checkbox-cross"
                    value={userData?.name}
                    error={userData.name.length === 0 && inputError}
                    onChange={(e: any) =>
                      setUserData({...userData, name: e.target.value})
                    }
                    required={true}
                    disabled={isInputDisabled}
                    classes={"mt-4"}
                    handleClickCheckbox={handleClickCheckbox}
                    checkedValues={newDataCheckbox}
                  />
                  <Input
                    label={"Owner First Name"}
                    id="ownerFirstName"
                    alert={merchantInfo?.newData?.ownerFirstName ? true : false}
                    classesCheckbox="checkbox-cross"
                    value={userData?.ownerFirstName}
                    disabled={isInputDisabled}
                    error={userData.ownerFirstName.length === 0 && inputError}
                    onChange={(e: any) =>
                      setUserData({...userData, ownerFirstName: e.target.value})
                    }
                    required={true}
                    classes={"mt-4"}
                    handleClickCheckbox={handleClickCheckbox}
                    checkedValues={newDataCheckbox}
                  />
                  <Input
                    label={"Owner Last Name"}
                    id="ownerLastName"
                    alert={merchantInfo?.newData?.ownerLastName ? true : false}
                    classesCheckbox="checkbox-cross"
                    value={userData?.ownerLastName}
                    disabled={isInputDisabled}
                    error={userData.ownerLastName.length === 0 && inputError}
                    onChange={(e: any) =>
                      setUserData({...userData, ownerLastName: e.target.value})
                    }
                    required={true}
                    classes={"mt-4"}
                    handleClickCheckbox={handleClickCheckbox}
                    checkedValues={newDataCheckbox}
                  />
                </div>
                <div className="md:w-full lg:w-4/12">
                  <Input
                    label={"Email"}
                    id="email"
                    alert={merchantInfo?.newData?.email ? true : false}
                    classesCheckbox="checkbox-cross"
                    value={userData?.email}
                    disabled={isInputDisabled}
                    onChange={(e: any) =>
                      setUserData({...userData, email: e.target.value})
                    }
                    required={true}
                    error={userData.email.length === 0 && inputError}
                    classes={"mt-4"}
                    handleClickCheckbox={handleClickCheckbox}
                    checkedValues={newDataCheckbox}
                  />
                  <Input
                    label={"Business Phone Number"}
                    id="phone"
                    alert={merchantInfo?.newData?.phone ? true : false}
                    classesCheckbox="checkbox-cross"
                    value={userData?.phone}
                    disabled={isInputDisabled}
                    onChange={(e: any) => {
                      const inputValue = e.target.value;
                      setUserData({...userData, phone: inputValue});
                    }}
                    required={true}
                    error={userData.phone.length === 0 && inputError}
                    classes={"mt-4"}
                    handleClickCheckbox={handleClickCheckbox}
                    checkedValues={newDataCheckbox}
                  />
                  <Input
                    label={"Business Web Address"}
                    id="website"
                    alert={
                      merchantInfo?.newData?.hasOwnProperty("website")
                        ? true
                        : false
                    }
                    classesCheckbox="checkbox-cross"
                    value={userData?.website}
                    disabled={isInputDisabled}
                    onChange={(e: any) =>
                      setUserData({...userData, website: e.target.value})
                    }
                    classes={"mt-4"}
                    handleClickCheckbox={handleClickCheckbox}
                    checkedValues={newDataCheckbox}
                  />
                </div>
                <div className="md:w-full lg:w-4/12">
                  <PlacesAutocomplete
                    label={"Address 1."}
                    id={"address"}
                    placeholder="Enter First Address"
                    required
                    error={userData.address1.length === 0 && inputError}
                    classes={"mt-4"}
                    clearAddressDetails={clearAddressDetails}
                    userData={userData}
                    disabled={isInputDisabled}
                    setUserData={setUserData}
                    alert={merchantInfo?.newData?.address ? true : false}
                    classesCheckbox="checkbox-cross"
                    handleClickCheckbox={handleClickCheckbox}
                    checkedValues={newDataCheckbox}
                    setAddressAutoComplete={setAddressAutoComplete}
                    onBlur={(e: FocusEvent<HTMLInputElement>) => {
                      if (!autoCompleteAddressReady)
                        //@ts-ignore
                        getFormattedAddress(e.target.value);
                    }}
                  />
                  <Input
                    label={"Address 2."}
                    id={"address2"}
                    placeholder="Address line 2"
                    // error={userData.yelp.length === 0 && inputError}
                    onChange={(e: any) =>
                      setUserData({...userData, address2: e.target.value})
                    }
                    value={userData.address2}
                    disabled={isInputDisabled}
                    alert={
                      merchantInfo?.newData?.hasOwnProperty("address2")
                        ? true
                        : false
                    }
                    classesCheckbox="checkbox-cross"
                    handleClickCheckbox={handleClickCheckbox}
                    checkedValues={newDataCheckbox}
                    classes={"mt-4"}
                  />
                  <Select
                    label={"Approximate Retail Space (SQFT)"}
                    checkboxID={"sizeType"}
                    disabled={isInputDisabled}
                    classes="mt-4"
                    classesSelect="py-2 px-2 mr-3"
                    required={true}
                    error={
                      (userData.sizeType.length === 0 && inputError) ||
                      merchantInfo?.newData?.sizeType
                    }
                    onChange={(e: any) =>
                      setUserData({...userData, sizeType: e.target.value})
                    }
                    value={userData.sizeType}
                    alert={
                      merchantInfo?.newData?.hasOwnProperty("sizeType")
                        ? true
                        : false
                    }
                    classesCheckbox="checkbox-cross"
                    handleClickCheckbox={handleClickCheckbox}
                    checkedValues={newDataCheckbox}
                  >
                    <>
                      <option defaultValue="" disabled></option>
                      <option value="SMALL">
                        Less than 1,000 square feet (Small)
                      </option>
                      <option value="MEDIUM">
                        1,000 - 2,500 square feet (Medium)
                      </option>
                      <option value="LARGE">
                        Greater than 2,500 square feet (Large)
                      </option>
                    </>
                  </Select>
                  <Input
                    disabled
                    value={userData.state}
                    id="state"
                    label="State"
                    classes="mt-4 lg:basis-1/5 md:basis-1/2 basis-full"
                    classesSelect="border border-gray-400 p-2.5 pl-4"
                  />
                  <Input
                    disabled
                    value={userData.city}
                    id="city"
                    label="City / Town"
                    classes="mt-4"
                    classesSelect="border border-gray-400 p-2.5 pl-4"
                  />
                  <Input
                    max={99999}
                    min={0}
                    maxLength={5}
                    error={userData?.zip?.length === 0 && inputError}
                    label={"Zip Code"}
                    placeholder="-"
                    value={userData?.zip}
                    classes={"mt-4"}
                    disabled={googleZipCode}
                  />
                </div>
              </div>

              <Subtitle classes="mt-8 mb-2">Choose Categories</Subtitle>
              <hr />
              <div className="w-full">
                <DropdownCategory
                  hasLabel={true}
                  label="Categories"
                  openDropdown={openDropdown}
                  dropdown={dropdown}
                  elementsChecked={elementsChecked}
                  elementsName={elementsName}
                  checkboxID="categories"
                  handleClickCheckbox={handleClickCheckboxCategory}
                  dataCategories={getFilteredNames(
                    dataCategories,
                    dropdownSearchFilter,
                    ""
                  )}
                  isErrorCategories={isErrorCategories}
                  isLoadingCategories={isLoadingCategories}
                  classNameSelect="w-full"
                  error={error}
                  required={true}
                  isError={!elementsChecked.length && inputError}
                  handleClickDropdown={handleClickDropdown}
                  disabled={isInputDisabled}
                  alert={merchantInfo?.newData?.categories ? true : false}
                  classesCheckbox="checkbox-cross"
                  handleClickNewDataCheckbox={handleClickCheckbox}
                  checkedValues={newDataCheckbox}
                  showNames={false}
                  expandItems={dropdownSearchFilter}
                  hasSearchValue={{
                    show: true,
                    content: (
                      <div className="flex mb-3 justify-between">
                        <div className="flex items-center  w-full">
                          <label htmlFor="search">
                            <Search classes="h-6 w-6 mr-2" />
                          </label>
                          <input
                            id="search"
                            name="search"
                            type="text"
                            placeholder="Search category"
                            className="decoration-none outline-none w-full"
                            onChange={(e: any) =>
                              setDowpdownSearchFilter(e.target.value)
                            }
                            value={dropdownSearchFilter}
                          />
                        </div>
                      </div>
                    ),
                  }}
                />
                <div
                  className={`mt-3 gap-2 max-h-[400px] overflow-auto  ${
                    elementsChecked.length > 0
                      ? "border border-gray-400"
                      : "border-none"
                  } ${isInputDisabled && "bg-gray-200"} rounded-md`}
                >
                  {elementsParentName.map((element, index) => (
                    <div
                      className="flex justify-between gap-2 items-center border-b-2 border-b-slate-200 p-2"
                      key={index}
                    >
                      <Tooltip id="my-tooltip"></Tooltip>
                      <div className="flex flex-wrap">
                        {element?.root && (
                          <div
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={`ID:  ${element?.rootId}`}
                            className="flex items-center text-sm"
                          >
                            {element?.root} <ArrowNext classes="h-4 w-4" />
                          </div>
                        )}
                        {element?.parentId && (
                          <div
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={`ID:  ${element?.parentId}`}
                            className="flex items-center text-sm"
                          >
                            {element?.parent} <ArrowNext classes="h-4 w-4" />
                          </div>
                        )}
                        {element?.childId && (
                          <div
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={`ID:  ${element?.childId}`}
                            className="font-medium text-sm"
                          >
                            {element?.child}
                          </div>
                        )}
                      </div>
                      <Button
                        type="button"
                        onClick={() => {
                          if (isInputDisabled) return;
                          setElementsChecked((prevElementsChecked: string[]) =>
                            prevElementsChecked.filter(
                              (item: string) => item !== element.id
                            )
                          );
                          setElementsName((prevElementsChecked: string[]) =>
                            prevElementsChecked.filter(
                              (item: string) => item !== element.child
                            )
                          );
                        }}
                        classes={`${
                          isInputDisabled && "opacity-50"
                        } ml-6 text-blue-400 text-sm`}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="w-full">
                <div className="flex mt-8 mb-2 items-center justify-between">
                  <Subtitle>Working Hours</Subtitle>
                  {merchantInfo?.newData?.workingHours && (
                    <div className="flex">
                      <Alert classes="h-4 w-4 mr-2" />
                      <Checkbox
                        className="checkbox-cross"
                        id="workingHours"
                        type="checkbox"
                        name="workingHours"
                        onChange={(e: any) => handleClickCheckbox(e)}
                        isChecked={newDataCheckbox?.includes(
                          "workingHours".replace(/\s/g, "")
                        )}
                      />
                    </div>
                  )}
                </div>
                <hr
                  className={` ${
                    merchantInfo?.newData?.workingHours && "!border-yellow-400"
                  } mb-6`}
                />
                {workingHours.map((day: {day: number; isClosed: boolean}) => (
                  <div className="flex gap-3 items-center mb-3" key={day.day}>
                    <span className="text-xs font-semibold mr-3 min-w-[100px]">
                      {getDayString(day.day)}
                    </span>
                    <input
                      type="time"
                      className={`border-2 border-gray-200 outline-none px-1 rounded-sm ${
                        day.isClosed && "bg-gray-200"
                      }`}
                      value={workingHours[day.day]?.start || ""}
                      onChange={(e) => {
                        const newWorkingHours = [...workingHours];
                        newWorkingHours[day.day] = {
                          ...newWorkingHours[day.day],
                          start: e.target.value,
                        };
                        setWorkingHours(newWorkingHours);
                      }}
                      disabled={day.isClosed || isInputDisabled}
                    />
                    <p className="text-sm text-gray-400 mx-1">To</p>
                    <input
                      type="time"
                      className={`border-2 border-gray-200 outline-none px-1 rounded-sm ${
                        day.isClosed && "bg-gray-200"
                      }`}
                      value={workingHours[day.day]?.end || ""}
                      onChange={(e) => {
                        const newWorkingHours = [...workingHours];
                        newWorkingHours[day.day] = {
                          ...newWorkingHours[day.day],
                          end: e.target.value,
                        };
                        setWorkingHours(newWorkingHours);
                      }}
                      disabled={day.isClosed || isInputDisabled}
                    />

                    <select
                      key={day.day}
                      className={
                        day.isClosed
                          ? "text-red-400 text-xs font-semibold cursor-pointer"
                          : "text-green-400 text-xs font-semibold cursor-pointer"
                      }
                      disabled={isInputDisabled}
                      value={day.isClosed ? "closed" : "open"}
                      onChange={(e) => handleSelectChange(e, day.day)}
                    >
                      <option value="open">Open</option>
                      <option value="closed">Closed</option>
                    </select>
                  </div>
                ))}
              </div>

              {/* <div className="w-full ">
              <div className="lg:w-9/12 mt-4 mb-6 flex items-center justify-between">
                <div className="flex">
                  <Checkbox
                    id="grandprizecheckbox"
                    onChange={(e: any) => handleClickCheckboxGP(e)}
                    isChecked={userData.isGp}
                  />
                  <label
                    htmlFor="grandprizecheckbox"
                    className="text-sm font-medium ml-2"
                  >
                    Provide access to the merchant to create a grand prize.
                  </label>
                </div>
              </div>
            </div> */}
              <div className="w-full ">
                <Subtitle classes="mt-5 mb-2">Merchant Photo</Subtitle>
                <hr />
                <div className="flex justify-between mt-4">
                  <div className="flex flex-col justify-center items-center">
                    <Dropzone
                      label="Merchant exterior photo"
                      checkboxID="PROFILE"
                      required={true}
                      disabledInput={isInputDisabled}
                      handleUploadPhoto={handleUploadPhoto}
                      image={secondImage}
                      imageSetter={setSecondImg}
                      id="image#PROFILE"
                      type="PROFILE"
                      alert={merchantInfo?.images.find((image: any) => {
                        if (image.type.toLowerCase() === "profile") {
                          if (
                            image.hasOwnProperty("newName") ||
                            image.hasOwnProperty("newUrl")
                          ) {
                            return true;
                          }
                        }
                      })}
                      handleClickCheckbox={handleClickCheckbox}
                      checkedValues={newDataCheckbox}
                      classesBorder={`${
                        !secondImage && inputError && "!border-red-500"
                      }`}
                      classesCheckbox="checkbox-cross"
                      imageCropModalType={imageCropModalType}
                      setImageCropModalType={setImageCropModalType}
                      newImageUpload={newImageUpload}
                      setNewImageUpload={setNewImageUpload}
                      openModalOnClick="showImagesCategories"
                      setTypeInputModalDropzone={setTypeInputModalDropzone}
                    />
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <Dropzone
                      label="Merchant interior photo"
                      checkboxID="INTERIOR"
                      disabledInput={isInputDisabled}
                      handleUploadPhoto={handleUploadPhoto}
                      image={thirdImage}
                      required={true}
                      imageSetter={setThirdImg}
                      type="INTERIOR"
                      id="image#INTERIOR"
                      alert={merchantInfo?.images.find((image: any) => {
                        if (image.type.toLowerCase() === "interior") {
                          if (
                            image.hasOwnProperty("newName") ||
                            image.hasOwnProperty("newUrl")
                          ) {
                            return true;
                          }
                        }
                      })}
                      handleClickCheckbox={handleClickCheckbox}
                      checkedValues={newDataCheckbox}
                      classesBorder={`${
                        !thirdImage && inputError && "!border-red-500"
                      }`}
                      classesCheckbox="checkbox-cross"
                      imageCropModalType={imageCropModalType}
                      setImageCropModalType={setImageCropModalType}
                      newImageUpload={newImageUpload}
                      setNewImageUpload={setNewImageUpload}
                      openModalOnClick="showImagesCategories"
                      setTypeInputModalDropzone={setTypeInputModalDropzone}
                    />
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <Dropzone
                      label="Merchant logo"
                      handleUploadPhoto={handleUploadPhoto}
                      type="LOGO_LARGE"
                      disabledInput={isInputDisabled}
                      checkboxID="LOGO_LARGE"
                      imageSetter={setFirstImg}
                      image={firstImage}
                      id="image#LOGO_LARGE"
                      alert={merchantInfo?.images.find((image: any) => {
                        if (image.type.toLowerCase() === "logo_large") {
                          if (
                            image.hasOwnProperty("newName") ||
                            image.hasOwnProperty("newUrl")
                          ) {
                            return true;
                          }
                        }
                      })}
                      classesCheckbox="checkbox-cross"
                      handleClickCheckbox={handleClickCheckbox}
                      checkedValues={newDataCheckbox}
                      imageCropModalType={imageCropModalType}
                      setImageCropModalType={setImageCropModalType}
                      newImageUpload={newImageUpload}
                      setNewImageUpload={setNewImageUpload}
                      sizePlaceholder={"16:9 @ 1920 x 1080"}
                      sizesContainer={{
                        minHeight: "172px",
                        maxHeight: "220px",
                        minWidth: "306px",
                        maxWidth: "306px",
                      }}
                      sizes={{height: 1920, width: 1080}}
                      aspect={16 / 9}
                      openModalOnClick="showImagesCategories"
                      setTypeInputModalDropzone={setTypeInputModalDropzone}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full ">
                <Subtitle classes="mt-8 mb-2">Merchant Special Aspect</Subtitle>
                <hr />
                <div className="mt-6">
                  <div className="flex items-center gap-4 mb-3">
                    <Switch
                      defaultChecked={false}
                      checked={extraOption.isGp}
                      as={Fragment}
                    >
                      {({checked}) => (
                        <button
                          disabled={isInputDisabled}
                          className={`${
                            extraOption.isGp
                              ? "bg-primary-purple"
                              : "bg-gray-200"
                          } relative inline-flex h-7 w-12 mt-1 items-center rounded-full ${
                            merchantInfo?.newData?.hasOwnProperty("isGp") &&
                            "border-2 border-yellow-400"
                          }`}
                          onClick={() =>
                            setExtraOption((prev: any) => ({
                              ...prev,
                              isGp: checked === false ? true : false,
                            }))
                          }
                        >
                          <span className="sr-only">
                            Enable create grand prize
                          </span>
                          <span
                            className={`${
                              extraOption.isGp
                                ? "translate-x-6"
                                : "translate-x-1"
                            } inline-block h-5 w-5 transform rounded-full bg-white transition`}
                          />
                        </button>
                      )}
                    </Switch>
                    <p className="font-medium mr-2">
                      Do you want to provide access to create a grand prize?
                    </p>
                    {merchantInfo?.newData?.hasOwnProperty("isGp") && (
                      <div className="flex">
                        <Alert classes="h-4 w-4 mr-2" />
                        <Checkbox
                          className="checkbox-cross"
                          id="isGp"
                          type="checkbox"
                          name="isGp"
                          onChange={(e: any) => handleClickCheckbox(e)}
                          isChecked={newDataCheckbox?.includes(
                            "isGp".replace(/\s/g, "")
                          )}
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex items-center gap-4">
                    <Switch
                      defaultChecked={false}
                      checked={extraOption.isInTrendingPlace}
                      as={Fragment}
                    >
                      {({checked}) => (
                        <button
                          disabled={isInputDisabled}
                          className={`${
                            extraOption.isInTrendingPlace
                              ? "bg-primary-purple"
                              : "bg-gray-200"
                          } relative inline-flex h-7 w-12 mt-1 items-center rounded-full ${
                            merchantInfo?.newData?.hasOwnProperty(
                              "placement"
                            ) && "border-2 border-yellow-400"
                          }`}
                          onClick={() => handleChangePlacement(checked)}
                        >
                          <span className="sr-only">Enable trading place</span>
                          <span
                            className={`${
                              extraOption.isInTrendingPlace
                                ? "translate-x-6"
                                : "translate-x-1"
                            } inline-block h-5 w-5 transform rounded-full bg-white transition`}
                          />
                        </button>
                      )}
                    </Switch>
                    <p className="font-medium mr-2">
                      Do you want to show this merchant on a trending place?
                    </p>
                    {merchantInfo?.newData?.hasOwnProperty("placement") && (
                      <div className="flex">
                        <Alert classes="h-4 w-4 mr-2" />
                        <Checkbox
                          className="checkbox-cross"
                          id="placement"
                          type="checkbox"
                          name="placement"
                          onChange={(e: any) => handleClickCheckbox(e)}
                          isChecked={newDataCheckbox?.includes(
                            "placement".replace(/\s/g, "")
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center mt-6">
                  <Select
                    disabled={isInputDisabled || !extraOption.isInTrendingPlace}
                    id="placement"
                    classes="flex items-center"
                    classesSelect="py-2 px-2 mr-3"
                    classesCheckbox="checkbox-cross"
                    onChange={(e: any) =>
                      setExtraOption((prev: any) => ({
                        ...prev,
                        placement: e.target.value,
                      }))
                    }
                    value={extraOption.placement}
                  >
                    <>
                      <option defaultValue="" disabled></option>
                      <option value="10">10</option>
                      <option value="9">9</option>
                      <option value="8">8</option>
                      <option value="7">7</option>
                      <option value="6">6</option>
                      <option value="5">5</option>
                      <option value="4">4</option>
                      <option value="3">3</option>
                      <option value="2">2</option>
                      <option value="1">1</option>
                    </>
                  </Select>
                  <p className="font-medium mr-2">Placement (1-10)</p>
                </div>
                <div className="mt-6">
                  <div className="flex items-center gap-4 ">
                    <Switch
                      defaultChecked={false}
                      checked={extraOption.isInLoyalityProgram}
                      as={Fragment}
                    >
                      {({checked}: {checked: boolean}) => (
                        <button
                          disabled={isInputDisabled}
                          className={`${
                            extraOption.isInLoyalityProgram
                              ? "bg-primary-purple"
                              : "bg-gray-200"
                          } relative inline-flex h-7 w-12 mt-1 items-center rounded-full`}
                          onClick={() => {
                            if (checked) {
                              setIsCheckedForModal(checked);
                              dispatch(setType("showLoyaltyChangeModal"));
                              dispatch(setOpenModal(true));
                            } else {
                              handleChangeLoyalityProgram(checked);
                            }
                          }}
                        >
                          <span className="sr-only">
                            Enable Loyalty Program
                          </span>
                          <span
                            className={`${
                              extraOption.isInLoyalityProgram
                                ? "translate-x-6"
                                : "translate-x-1"
                            } inline-block h-5 w-5 transform rounded-full bg-white transition`}
                          />
                        </button>
                      )}
                    </Switch>
                    <p className="font-medium mr-2">
                      Do you want to provide access to Swiggle Loyalty Program?
                    </p>
                  </div>
                </div>
                <div className="flex gap-4 mt-6">
                  <Select
                    label="Months Availables"
                    id="loyalityProgram"
                    disabled={
                      isInputDisabled || !extraOption.isInLoyalityProgram
                    }
                    classes="w-40"
                    classesSelect="py-2 px-2 mr-3"
                    classesCheckbox="checkbox-cross"
                    onChange={(e: any) =>
                      setExtraOption((prev: any) => ({
                        ...prev,
                        loyalityProgram: e.target.value,
                        loyaltyEndDate: addDays(
                          getTodayDate(),
                          e.target.value * 30
                        ),
                      }))
                    }
                    value={
                      extraOption.loyalityProgram === 0
                        ? ""
                        : extraOption.loyalityProgram
                    }
                  >
                    <>
                      <option defaultValue="" disabled></option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </>
                  </Select>
                  <DatePicker
                    label="Loyalty program end date"
                    disabled={true}
                    value={getRoundedDate(extraOption) || ""}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="bg-white xl:ml-4 px-5 rounded xl:w-2/7 w-full h-max">
          <div className="mt-8 flex flex-col justify-center">
            <Subtitle>QR Code</Subtitle>
            {renderQR({qrCode: validQR})}
            <div className="flex flex-col w-fit gap-2 my-4">
              {
                // @ts-ignore
                routes[user.data.role].urlAllowed?.merchant?.includes(
                  "regenerate qrcode"
                ) && (
                  <Button
                    type="button"
                    variant={
                      merchantInfo?.status &&
                      merchantInfo?.status?.toLowerCase() === "published"
                        ? "normal"
                        : "disabled"
                    }
                    onClick={
                      merchantInfo?.status &&
                      merchantInfo?.status?.toLowerCase() === "published"
                        ? () => {
                            setTypeQRCode("REDEEM");
                            dispatch(setType("changeQRCode"));
                            dispatch(setOpenModal(true));
                          }
                        : () =>
                            toast.error(
                              "The merchant must be published to generate a new QR."
                            )
                    }
                  >
                    <QRCode classes="mr-2" />
                    Change QR Code
                  </Button>
                )
              }

              <Button
                type="button"
                variant={
                  validQR && Object.keys(validQR).length > 0
                    ? "normal"
                    : "disabled"
                }
                onClick={
                  validQR && Object.keys(validQR).length > 0
                    ? () => handleDownloadQR({qrCode: validQR})
                    : () => {}
                }
              >
                <Download classes="mr-2" />
                Download
              </Button>
            </div>
          </div>

          <div className="mt-8 flex flex-col justify-center">
            <Subtitle>Loyalty Program QR Code</Subtitle>
            {renderQR({qrCode: validQRLoyalty})}
            <div className="flex flex-col w-fit gap-2 my-4">
              {
                // @ts-ignore
                routes[user.data.role].urlAllowed?.merchant?.includes(
                  "regenerate qrcode"
                ) && (
                  <Button
                    type="button"
                    variant={
                      merchantInfo?.status &&
                      merchantInfo?.status?.toLowerCase() === "published" &&
                      merchantInfo?.loyaltyToggle
                        ? "normal"
                        : "disabled"
                    }
                    onClick={
                      merchantInfo?.status &&
                      merchantInfo?.status?.toLowerCase() === "published" &&
                      merchantInfo?.loyaltyToggle
                        ? () => {
                            setTypeQRCode("LOYALTY");
                            dispatch(setType("changeQRCode"));
                            dispatch(setOpenModal(true));
                          }
                        : () =>
                            toast.error(
                              "The merchant must be published and must have the loyalty program enabled to generate a new Loyalty QR."
                            )
                    }
                  >
                    <QRCode classes="mr-2" />
                    Change QR Code
                  </Button>
                )
              }

              <Button
                type="button"
                variant={
                  validQRLoyalty && Object.keys(validQRLoyalty).length > 0
                    ? "normal"
                    : "disabled"
                }
                onClick={
                  validQRLoyalty && Object.keys(validQRLoyalty).length > 0
                    ? () => handleDownloadQR({qrCode: validQRLoyalty})
                    : () => {}
                }
              >
                <Download classes="mr-2" />
                Download
              </Button>
            </div>
          </div>
        </div>

        {modal.type === "showImage" && (
          <Modal>
            <div className="flex flex-col bg-white text-black rounded">
              <Dialog.Overlay />
              <img
                src={modal?.imageModal}
                alt={`modal-${modal?.imageModal}`}
                className="image-modal-height"
              />
            </div>
          </Modal>
        )}
        {modal.type === "showConfirmFetchYelpGoogleInfo" && (
          <Modal>
            <div className="flex flex-col bg-white text-black rounded w-96 py-4">
              <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
                <p className="text-sm mt-4 text-center">
                  Are you sure you want to refetch the information of this
                  merchant?
                </p>
              </div>
              <hr />
              <div className="mt-8 px-3 flex justify-between items-center">
                <Button
                  variant="normal"
                  onClick={() => {
                    dispatch(setType(""));
                    dispatch(setOpenModal(false));
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    getYelpGoogleInfoMerchant();
                  }}
                >
                  Refetch
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {modal.type === "showLoyaltyChangeModal" && (
          <Modal>
            <div className="flex flex-col bg-white text-black rounded w-96 py-4">
              <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
                <p className="text-sm mt-4 text-center">
                  This action will affect the customer application. Are you sure
                  you want to change loyalty program?
                </p>
              </div>
              <hr />
              <div className="mt-8 px-3 flex justify-between items-center">
                <Button
                  variant="normal"
                  onClick={() => {
                    dispatch(setType(""));
                    dispatch(setOpenModal(false));
                  }}
                >
                  No
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    handleChangeLoyalityProgram(isCheckedForModal);
                    dispatch(setType(""));
                    dispatch(setOpenModal(false));
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {modal.type === "showImagesCategories" && (
          <Modal>
            <div className="flex flex-col bg-white text-black rounded p-6 gap-2">
              <Dialog.Overlay />
              <Tabs tabsName={["Upload File", "Yelp Images"]} primaryTabs>
                {/* First Table */}
                <div>
                  {typeInputModalDropzone === "exterior" && (
                    <Dropzone
                      required={true}
                      handleUploadPhoto={handleUploadPhoto}
                      image={secondImage}
                      imageSetter={setSecondImg}
                      id="4"
                      type="PROFILE"
                      classesBorder={`${
                        !secondImage && inputError && "!border-red-500"
                      }`}
                      typeItem="merchant"
                      imageCropModalType={imageCropModalType}
                      setImageCropModalType={setImageCropModalType}
                    />
                  )}
                  {typeInputModalDropzone === "interior" && (
                    <Dropzone
                      handleUploadPhoto={handleUploadPhoto}
                      image={thirdImage}
                      required={true}
                      imageSetter={setThirdImg}
                      type="INTERIOR"
                      id="5"
                      classesBorder={`${
                        !thirdImage && inputError && "!border-red-500"
                      }`}
                      typeItem="merchant"
                      imageCropModalType={imageCropModalType}
                      setImageCropModalType={setImageCropModalType}
                    />
                  )}
                  {typeInputModalDropzone === "logo" && (
                    <Dropzone
                      handleUploadPhoto={handleUploadPhoto}
                      type="LOGO_LARGE"
                      imageSetter={setFirstImg}
                      image={firstImage}
                      id="6"
                      required
                      classesBorder={`${
                        !firstImage && inputError && "!border-red-500"
                      }`}
                      typeItem="merchant"
                      imageCropModalType={imageCropModalType}
                      setImageCropModalType={setImageCropModalType}
                      sizePlaceholder={"logo \n 16:9 @ 1920 x 1080"}
                      sizesContainer={{
                        minHeight: "172px",
                        maxHeight: "220px",
                        minWidth: "306px",
                        maxWidth: "306px",
                      }}
                      sizes={{height: 1920, width: 1080}}
                      aspect={16 / 9}
                    />
                  )}
                </div>
                {/* Second Table */}
                <div className="flex flex-wrap gap-4 max-h-96 overflow-y-auto p-8">
                  {photosCategories && photosCategories.length > 0 ? (
                    photosCategories?.map((item: any, key: number) => (
                      <img
                        src={item}
                        className="aspect-square h-64 w-64 cursor-pointer"
                        onClick={() => {
                          if (typeInputModalDropzone === "exterior") {
                            handleUploadPhoto(item, "PROFILE", setSecondImg);
                            dispatch(setOpenModal(true));
                            dispatch(setType("cropImage"));
                            dispatch(setImageModal(secondImage));
                            setImageCropModalType &&
                              setImageCropModalType("PROFILE");
                          } else if (typeInputModalDropzone === "interior") {
                            handleUploadPhoto(item, "INTERIOR", setThirdImg);
                            dispatch(setOpenModal(true));
                            dispatch(setType("cropImage"));
                            dispatch(setImageModal(thirdImage));
                            setImageCropModalType &&
                              setImageCropModalType("INTERIOR");
                          } else if (typeInputModalDropzone === "logo") {
                            handleUploadPhoto(item, "LOGO_LARGE", setFirstImg);
                            dispatch(setOpenModal(true));
                            dispatch(setType("cropImage"));
                            dispatch(setImageModal(firstImage));
                            setImageCropModalType &&
                              setImageCropModalType("LOGO_LARGE");
                          } else {
                            return;
                          }
                        }}
                        alt={`category-${key}`}
                        key={key}
                      />
                    ))
                  ) : (
                    <div className="text-center w-full">No images</div>
                  )}
                </div>
              </Tabs>
            </div>
          </Modal>
        )}
        {modal.type === "changeQRCode" && (
          <ChangeQRCode userData={userData} typeQRCode={typeQRCode} />
        )}
      </div>
    );
  }
);

export default MerchantDetails;
