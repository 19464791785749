import {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {Dialog} from "@headlessui/react";
import {toast} from "react-toastify";

// Components
import Button from "components/Common/Button";
import Subtitle from "components/Common/Subtitle";
import Table from "components/Table/Table";
import Modal from "components/Modal";
import Input from "components/Common/Input";
import Label from "components/Common/Label";

// Assets
import {ArrowDown} from "assets/Arrows";
import Delete from "assets/Delete";
import AddPerson from "assets/AddPerson";

// Redux
import type {RootState} from "store/store";
import {useSelector, useDispatch} from "react-redux";
import {
  setEmployeeSelectedToDelete,
  setOpenModal,
  setIdSelectedToDelete,
  setType,
} from "store/modal/modalSlice";
import {
  useCancelInviteMutation,
  useDeleteInviteMutation,
  useGetMerchantEmployeeListQuery,
  useSendInviteMutation,
} from "store/api/merchantsApi";
import {setResetElChecked} from "store/table/currentTableDataSlice";

// Utils
import routes from "utils/routesByRole";
import Edit from "assets/Edit";
import Save from "assets/Save";

function MerchantEmployee({headers}: any) {
  const {id} = useParams();
  const dispatch = useDispatch();
  const modal: any = useSelector((state: RootState) => state.modal);
  const user = useSelector((state: RootState) => state.user.user.user);

  const [elementsChecked, setElementsChecked] = useState<number[]>([]);
  let [email, setEmail] = useState("");
  let [checked, setChecked] = useState("captain");

  const [sendForm, setSendForm] = useState(false);
  const [consentDelete, setConsentDelete] = useState("");
  const [inputError, setInputError] = useState(false);

  const {
    data: dataEmployeeList,
    isLoading: isLoadingEmployeeList,
    isError: isErrorEmployeeList,
    refetch,
  } = useGetMerchantEmployeeListQuery(id);

  const [sendInvite] = useSendInviteMutation();
  const [deleteInvitationMutation] = useDeleteInviteMutation();
  const [cancelInvitationMutation] = useCancelInviteMutation();

  const handleItemChecked = (items: number[]) => {
    setElementsChecked(items);
  };

  const handleCloseModal = () => {
    dispatch(setOpenModal(false));
    dispatch(setType(""));
  };

  const handleSendInvite = (e: any) => {
    e.preventDefault();

    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!email.trim() || !regex.test(email.trim())) {
      toast.error("You must enter a valid email");
      return;
    }

    sendInviteEmployee();
  };

  const sendInviteEmployee = async () => {
    const toastPromise = toast.promise(
      sendInvite({
        id: id,
        data: {
          email: email,
          userRole: checked.toUpperCase(),
        },
      }),
      {
        pending: "Sending Invitation",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while sending invitation</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Invitation sent successfully");
          setTimeout(() => {
            dispatch(setType(""));
            dispatch(setOpenModal(false));
            setEmail("");
            setChecked("captain");
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error while sending invitation");
      });
  };

  const handleDeleteInviteByChecked = () => {
    if (!id || !elementsChecked.length) return;

    dispatch(setType("deleteEmployee"));
    dispatch(setOpenModal(true));

    const employees = elementsChecked.map((el) => {
      return dataEmployeeList.filter((employee: any) => {
        if (employee.id === el) {
          return employee;
        }
      });
    });

    const elements = employees.map((el) => {
      return el[0];
    });

    dispatch(setEmployeeSelectedToDelete({element: elements, id: id}));
  };

  const handleSendInviteByChecked = () => {
    try {
      elementsChecked?.map(async (el, key) => {
        const email = document.getElementById(`${el}`)?.children[1]?.innerHTML;
        const role = document.getElementById(`${el}`)?.children[3]?.innerHTML;

        let definingRole = "";

        if (role?.toLowerCase() === "crew member") {
          definingRole = "CREW_MEMBER";
        } else if (role?.toLowerCase() === "officer") {
          definingRole = "OFFICER";
        } else if (role?.toLowerCase() === "captain") {
          definingRole = "CAPTAIN";
        }

        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (!email?.trim() || !regex.test(email?.trim())) {
          toast.error("You must enter a valid email");
          return;
        }

        const toastPromise = toast.promise(
          sendInvite({
            id: id,
            data: {
              email: email,
              userRole: definingRole,
            },
          }),
          {
            pending: "Sending Invitation",
          }
        );

        await toastPromise
          .then((res: any) => {
            if (res.error) {
              toast.error(
                <div>
                  <h2>Error while sending invitation</h2>
                  <p className="text-xs"> {res?.error?.data?.code} </p>
                </div>
              );
            } else {
              toast.success(`Invitation sent successfully`);
            }
          })
          .catch((err) => {
            toast.error(`Error while sending invitation`);
          });
      });
    } catch {
      toast.error(`Error while sending invitation`);
    }
  };

  useEffect(() => {
    if (modal?.employee?.element?.length) {
      modal?.employee?.element?.map((employee: any) => {
        if (sendForm && employee?.status?.toLowerCase() === "invited") {
          // console.log("cancel", employee);
          handleCancelInvitation(employee);
          setSendForm(false);
        } else if (sendForm && employee?.status?.toLowerCase() !== "invited") {
          // console.log("delete", employee);
          handleDeleteInvitation(employee);
          setSendForm(false);
        }
      });
    } else {
      if (
        modal?.employee?.element &&
        sendForm &&
        modal?.employee?.element?.status?.toLowerCase() === "invited"
      ) {
        handleCancelInvitation(modal.employee.element);
        setSendForm(false);
      } else if (
        modal?.employee?.element &&
        sendForm &&
        modal?.employee?.element?.status?.toLowerCase() !== "invited"
      ) {
        handleDeleteInvitation(modal.employee.element);
      }
    }
  }, [modal.employee, sendForm]);

  useEffect(() => {
    if (!modal.isOpen) {
      setSendForm(false);
      setConsentDelete("");
    }
  }, [modal.isOpen]);

  const handleCancelInvitation = async (employee: {
    createdAt: string;
    email: string;
    id: number;
    lastLogin: string;
    notificationTokens: null;
    retailerID: number;
    role: string;
    status: string;
  }) => {
    if (!employee)
      return toast.error("There was an error trying to deleting merchant");

    const toastPromise = toast.promise(
      cancelInvitationMutation({
        idRetailer: employee.retailerID,
        idUser: employee.id,
      }),
      {
        pending: "Canceling Invite",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while canceling invite</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Invite canceled successfully");
          dispatch(setType(""));
          dispatch(setOpenModal(false));
          dispatch(setResetElChecked(true));
        }
      })
      .catch((err) => {
        toast.error("Error while canceling invite");
      });
  };

  const handleDeleteInvitation = async (employee: {
    createdAt: string;
    email: string;
    id: number;
    lastLogin: string;
    notificationTokens: null;
    retailerID: number;
    role: string;
    status: string;
  }) => {
    if (!employee)
      return toast.error("There was an error trying to deleting merchant");

    const toastPromise = toast.promise(
      deleteInvitationMutation({
        idRetailer: employee.retailerID,
        idUser: employee.id,
      }),
      {
        pending: "Deleting Employee",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while deleting employee</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Employee deleted successfully");
          dispatch(setType(""));
          dispatch(setOpenModal(false));
          dispatch(setResetElChecked(true));
        }
      })
      .catch((err) => {
        toast.error("Error while deleting employee");
      });
  };

  const ITEMS = useMemo(
    () => [
      // @ts-ignore
      routes[user.data.role].urlAllowed?.employee?.includes("edit") && {
        title: "edit",
        svg: <Edit classes="" />,
      },
      // @ts-ignore
      routes[user.data.role].urlAllowed?.employee?.includes("delete") && {
        title: "delete",
        svg: <Delete classes="text-red-500" />,
        onclick: (e: any) => e,
      },
      // @ts-ignore
      routes[user.data.role].urlAllowed?.employee?.includes("save") && {
        title: "save",
        svg: <Save classes="" />,
        onclick: (e: any) => e,
      },
    ],
    []
  );

  return (
    <div>
      <div className="px-5 bg-white flex items-center rounded justify-between">
        <Subtitle classes={"py-5"}>Employee List</Subtitle>
        <div className="flex">
          {
            // @ts-ignore
            routes[user.data.role].urlAllowed?.employee?.includes("delete") && (
              <Button
                variant={`${
                  elementsChecked.length > 0 ? "danger" : "disabled"
                }`}
                onClick={() => handleDeleteInviteByChecked()}
              >
                <Delete />
                <p className="ml-2">Delete</p>
              </Button>
            )
          }
          {
            // @ts-ignore
            routes[user.data.role].urlAllowed?.employee?.includes("add") && (
              <Button
                variant={`${
                  elementsChecked.length > 0 ? "normal" : "disabled"
                }`}
                onClick={() => handleSendInviteByChecked()}
              >
                <AddPerson />
                <p className="ml-2">Send invite</p>
              </Button>
            )
          }
          {
            // @ts-ignore
            routes[user.data.role].urlAllowed?.employee?.includes("add") && (
              <Button
                variant="add"
                onClick={() => {
                  dispatch(setType("addEmployee"));
                  dispatch(setOpenModal(true));
                }}
              >
                <ArrowDown />
                <p className="ml-2">Add</p>
              </Button>
            )
          }
        </div>
      </div>
      <Table
        data={
          dataEmployeeList && dataEmployeeList.length ? dataEmployeeList : []
        }
        loadingData={isLoadingEmployeeList}
        errorData={isErrorEmployeeList}
        refetch={refetch}
        origin="merchant_employee_list"
        hasPagination={false}
        headers={headers}
        handleItemChecked={handleItemChecked}
        itemOptions={ITEMS}
        typeOptions="individual"
        show={["email", "createdAt", "role", "status", "lastLogin"]}
        sort={{
          email: null,
          createdAt: null,
          role: null,
          status: null,
          lastLogin: null,
        }}
      />

      {modal.type === "addEmployee" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <Dialog.Overlay />
            <form>
              <Dialog.Title className="px-6 mb-4 text-black text-xl font-medium">
                Add new employee
              </Dialog.Title>
              <hr />

              <div className="mt-8 px-6">
                <Input
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required={true}
                  placeholder="Enter email"
                />
                <div className="mt-6">
                  <div className="mb-6 flex ">
                    <div
                      onClick={() => setChecked("captain")}
                      className="w-6 h-4 border-2 rounded-full border-primary-purple flex justify-center items-center mr-2"
                    >
                      {checked === "captain" && (
                        <div
                          id="captain"
                          className="w-3 h-3 border-2 rounded-full bg-primary-purple"
                        ></div>
                      )}
                    </div>
                    <div>
                      <label
                        onClick={() => setChecked("captain")}
                        htmlFor="captain"
                        className="font-medium text-gray-600"
                      >
                        Captain
                      </label>
                      <p
                        id="helper-radio-text"
                        className="text-xs font-normal text-gray-400"
                      >
                        Can manage all aspects of the business (profile, new
                        prizes, redeem QR codes, and insights)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-6">
                  <div className="flex items-center mr-4">
                    <div
                      onClick={() => setChecked("officer")}
                      className="w-6 h-4 border-2 rounded-full border-primary-purple flex justify-center items-center mr-2"
                    >
                      {checked === "officer" && (
                        <div
                          id="officer"
                          className="w-3 h-3 border-2 rounded-full bg-primary-purple"
                        ></div>
                      )}
                    </div>
                    <div>
                      <label
                        onClick={() => setChecked("officer")}
                        htmlFor="officer"
                        className="font-medium text-gray-600"
                      >
                        Officers
                      </label>
                      <p
                        id="helper-radio-text"
                        className="text-xs font-normal text-gray-400"
                      >
                        Can publish new prizes and deals for consumers and
                        scan/redeem customer QR codes
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="flex items-center mr-4">
                    <div
                      onClick={() => setChecked("crew_member")}
                      className="w-5 h-4 border-2 rounded-full border-primary-purple flex justify-center items-center mr-2"
                    >
                      {checked === "crew_member" && (
                        <div
                          id="crew_member"
                          className="w-3 h-3 border-2 rounded-full bg-primary-purple"
                        ></div>
                      )}
                    </div>
                    <div>
                      <label
                        onClick={() => setChecked("crew_member")}
                        htmlFor="crew_member"
                        className="font-medium text-gray-600"
                      >
                        Crew Member
                      </label>
                      <p
                        id="helper-radio-text"
                        className="text-xs font-normal text-gray-400"
                      >
                        These are the employees that can only scan customer QR
                        codes.
                      </p>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="w-100 flex justify-between items-center mt-4">
                  <Button variant="normal" onClick={() => handleCloseModal()}>
                    <p>Cancel</p>
                  </Button>
                  <Button
                    variant="add"
                    type="submit"
                    onClick={(e: any) => handleSendInvite(e)}
                  >
                    <p>Sent invite</p>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )}
      {modal.type === "deleteEmployee" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <Dialog.Overlay />
            <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
              <div className="rounded-full h-20 w-20 bg-red-100 flex items-center justify-center mt-5">
                <Delete classes="text-red-500 h-10 w-10" />
              </div>
              <h1 className="text-red-500 mt-4">Delete</h1>
              <p className="text-sm mt-4 text-center">
                Are you sure you want to delete employee{" "}
                {Array.isArray(modal?.employee?.element)
                  ? modal?.employee?.element.map((el: any) => `${el.email}, `)
                  : modal?.employee?.element?.email}
              </p>
            </div>
            <Label classes="pl-6 mt-5 mb-0 ">Type DELETE to confirm</Label>
            <Input
              inputID="input-modal-delete-merchant"
              classes="px-5 font-normal mb-6"
              classesInput=""
              label=""
              value={consentDelete}
              onChange={(e) => setConsentDelete(e.target.value)}
              placeholder=""
              error={consentDelete !== "delete" && inputError}
            />

            <hr />

            <div className="mt-8 px-6 flex justify-between items-center">
              <Button
                variant="normal"
                onClick={() => {
                  dispatch(setType(""));
                  dispatch(setOpenModal(false));
                  dispatch(setIdSelectedToDelete(""));
                  dispatch(setResetElChecked(true));
                }}
              >
                Close
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  if (consentDelete.toLowerCase() !== "delete") {
                    return setInputError(true);
                  } else {
                    setSendForm(true);
                  }
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default MerchantEmployee;
