import {useEffect} from "react";
import {toast} from "react-toastify";

// Components
import Input from "components/Common/Input";
import Subtitle from "components/Common/Subtitle";
import Searchbar from "components/Common/Searchbar";

// Redux
import {
  useLazyGetMerchantByIDQuery,
  useGetAllMerchantsNameQuery,
} from "store/api/merchantsApi";
import {useDispatch} from "react-redux";
import {setMerchantsName} from "store/merchants/merchantsSlice";

function MerchantInfo({
  idMerchant,
  setMerchantID,
  setMerchantRewards,
  setBlockerGP,
  setBlockerReward,
}: any) {
  const dispatch = useDispatch();
  const [
    getMerchantByID,
    {data: dataMerchantByID, isLoading: isLoadingDataMerchantByID},
  ] = useLazyGetMerchantByIDQuery();

  const {
    data: dataMerchantsName,
    isLoading: isLoadingMerchantsName,
    isError: isErrorMerchantsName,
  } = useGetAllMerchantsNameQuery();

  useEffect(() => {
    if (dataMerchantsName && dataMerchantsName.length) {
      dispatch(setMerchantsName(dataMerchantsName));
    }
  }, [dataMerchantsName]);

  const handleCreateGrandPrize = async (id: any) => {
    const toastPromise = toast.promise(getMerchantByID(id), {
      pending: "Fetching merchant",
    });

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while fetching merchant</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          setBlockerGP(res.data.isGp);
          setBlockerReward(res.data.loyaltyToggle);
          setMerchantRewards(res.data);
          setMerchantID(res.data.id);
        }
      })
      .catch((err) => {
        toast.error("Error while fetching merchant");
      });
  };

  useEffect(() => {
    if (idMerchant && !isLoadingDataMerchantByID) {
      handleCreateGrandPrize(idMerchant);
    }
  }, [idMerchant]);

  const getID = (id: number) => {
    if (id) {
      handleCreateGrandPrize(id);
    }
  };

  return (
    <div className="bg-white xl:mr-4 px-5 rounded h-max search-info-merchant">
      <Subtitle classes="mt-4">Items</Subtitle>
      <hr className="mb-6" />
      <div className="pb-6">
        <div className="relative flex h-full  bg-white border border-gray-400 text-gray-500 rounded-md">
          <Searchbar
            placeholder="Id, Merchant Name"
            useTags={false}
            getIDMerchant={getID}
            classesContainer="w-full h-8"
          />
        </div>
        <hr className="my-5" />
        <Input
          label={"Merchant ID"}
          value={idMerchant || dataMerchantByID?.id}
          disabled={true}
          readOnly={true}
        />
        <Input
          label={"Merchant Name"}
          value={dataMerchantByID?.name || ""}
          readOnly={true}
          classes={"mt-4"}
        />
        <Input
          label={"Email"}
          value={dataMerchantByID?.email || ""}
          readOnly={true}
          classes={"mt-4"}
        />
        <Input
          label={"State"}
          value={dataMerchantByID?.state || ""}
          classes={"mt-4"}
          readOnly={true}
        />
        <Input
          label={"City / Town"}
          value={dataMerchantByID?.city || ""}
          classes={"mt-4"}
          readOnly={true}
        />
        <Input
          label={"Zip code"}
          value={dataMerchantByID?.zip || ""}
          readOnly={true}
          classes={"mt-4"}
        />
        <Input
          label={"Address 1."}
          value={dataMerchantByID?.address || ""}
          readOnly={true}
          classes={"mt-4"}
        />
      </div>
    </div>
  );
}

export default MerchantInfo;
