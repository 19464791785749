import React, {Fragment, useEffect, useState} from "react";
import {Switch} from "@headlessui/react";

// Components
import Subtitle from "components/Common/Subtitle";

// Assets
import AddOffer from "assets/AddOffer";

// Utils
import routes from "utils/routesByRole";

// Redux
import {useSelector} from "react-redux";
import {RootState} from "store/store";
import {findItemById} from "utils/workingHoursUtils";
import {useGetCategoriesTreeQuery} from "store/api/categoriesApi";
import Item from "./View/Item";

let initialItemData: any = {};

function Details({
  itemInfo,
  isLoadingItem,
  inputError,
  errorInItemsCount,
  setErrorInItemsCount,
  itemData,
  setItemData,
  setIsDirty,
  firstImage,
  setFirstImg,
  secondImage,
  setSecondImg,
  setDeclinedItems,
  setApprovedItems,
  handleChangeBtnAllChecked,
  objectImages,
  setObjectImages,
  elementsName,
  setElementsName,
  imageCropModalType,
  setImageCropModalType,
  newDataCheckbox,
  setNewDataCheckbox,
}: any) {
  const [itemsShop, setItemsShop] = useState("");
  const [itemsWheel, setItemsWheel] = useState("");
  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [elementsChecked, setElementsChecked] = useState<string[]>([]);
  const [elementsParentName, setElementsParentName] = useState<any[]>([]);
  const [dropdownSearchFilter, setDowpdownSearchFilter] = useState("");

  const [newImageUpload, setNewImageUpload] = useState<string[]>([]);

  const user = useSelector((state: RootState) => state.user.user.user);

  const {
    data: dataCategories,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
    error,
    // @ts-ignore
  } = useGetCategoriesTreeQuery();

  // useEffect(() => {
  //   if (itemInfo?.newData) {
  //     // get keys from the elements inside the newData obj
  //     const keys = Object.keys(itemInfo?.newData);
  //     const imageKeys: string[] = [];

  //     // get keys from
  //     itemInfo?.images?.forEach((image: any) => {
  //       if (image.hasOwnProperty("newName") || image.hasOwnProperty("newUrl")) {
  //         if (image.type === "BIG") {
  //           imageKeys.push("image#BIG");
  //         } else {
  //           imageKeys.push("image#SMALL");
  //         }
  //       }
  //     });

  //     setNewDataCheckbox(keys.concat(imageKeys));
  //   }
  // }, [itemInfo]);

  useEffect(() => {
    if (
      itemInfo?.newData ||
      itemInfo?.images[0]?.hasOwnProperty("newName") ||
      itemInfo?.images[1]?.hasOwnProperty("newName")
    ) {
      let resultDeclinedItems: any = [];
      let resultApprovedItems: any = [];

      if (itemInfo?.newData) {
        resultDeclinedItems = Object?.keys(itemInfo?.newData).filter((val) =>
          newDataCheckbox?.includes(val)
        );

        resultApprovedItems = Object?.keys(itemInfo?.newData).filter(
          (val) => !newDataCheckbox?.includes(val)
        );
      }

      const resultNewImages: any[] = [];

      itemInfo?.images?.forEach((image: any) => {
        if (image.hasOwnProperty("newName") || image.hasOwnProperty("newUrl")) {
          if (image.type === "BIG") {
            resultNewImages.push("image#BIG");
          } else {
            resultNewImages.push("image#SMALL");
          }
        }
      });

      const resultDeclinedImages = resultNewImages.filter((image: any) =>
        newDataCheckbox.includes(image)
      );
      const resultApprovedImages = resultNewImages.filter(
        (image: any) => !newDataCheckbox.includes(image)
      );

      if (newDataCheckbox.length > 0) {
        handleChangeBtnAllChecked(false);
      } else {
        handleChangeBtnAllChecked(true);
      }

      setDeclinedItems(resultDeclinedItems.concat(resultDeclinedImages));
      setApprovedItems(resultApprovedItems.concat(resultApprovedImages));
    }
  }, [itemInfo, newDataCheckbox]);

  useEffect(() => {
    if (parseInt(itemsWheel) + parseInt(itemsShop) === 100) {
      setErrorInItemsCount(false);
    }
  }, [itemsShop, itemsWheel]);

  useEffect(() => {
    setItemData({
      ...itemData,
      itemsShop,
      itemsWheel,
    });
  }, [itemsWheel, itemsShop]);

  useEffect(() => {
    if (dataCategories && itemInfo && !isLoadingItem && !isLoadingCategories) {
      const names: string[] = [];
      const checked: string[] = [];

      if (itemInfo?.newData?.categories) {
        itemInfo?.newData?.categories?.map(
          (category: {id: string; name: string}) => {
            checked.push(category.id.toString());
            names.push(category.name.toLowerCase());
          }
        );

        setElementsChecked(checked);
        setElementsName(names);
      } else {
        itemInfo?.categories?.map((category: {id: string; name: string}) => {
          checked.push(category.id.toString());
          names.push(category.name.toLowerCase());
        });

        setElementsChecked(checked);
        setElementsName(names);
      }
    }
  }, [itemInfo, dataCategories, isLoadingCategories]);

  useEffect(() => {
    if (!dataCategories) return;
    const res = elementsChecked.map((id: any, index: number) => {
      let rootParentElement;

      const currentElement = findItemById(dataCategories, id.toString());

      if (!currentElement?.parentId) {
        return {
          id: elementsChecked[index],
          root: currentElement?.name,
          rootId: currentElement?.id,
        };
      }

      const parentElement = findItemById(
        dataCategories,
        currentElement.parentId.toString()
      );

      if (parentElement?.parentId) {
        rootParentElement = findItemById(
          dataCategories,
          parentElement.parentId.toString()
        );
      }

      // Save the root parent element and the parent element nested
      if (rootParentElement) {
        return {
          id: elementsChecked[index],
          root: rootParentElement?.name,
          rootId: rootParentElement?.id,
          parent: parentElement?.name,
          parentId: parentElement?.id,
          child: currentElement?.name,
          childId: currentElement?.id,
        };
      } else {
        return {
          id: elementsChecked[index],
          parent: parentElement?.name,
          parentId: parentElement?.id,
          child: currentElement?.name,
          childId: currentElement?.id,
        };
      }
    });

    setElementsParentName(res);
  }, [elementsChecked, dataCategories]);

  useEffect(() => {
    // Check if itemData is different from its initial state
    const hasChanges = Object.keys(itemData).some((key) => {
      if (itemData[key] !== initialItemData[key] && key !== "categories") {
        return true;
      }
      return false;
    });

    const imageHasChange = newImageUpload.length > 0;

    setIsDirty(hasChanges || imageHasChange);
  }, [itemData, newImageUpload]);

  useEffect(() => {
    const arrCategoriesID = elementsChecked?.map((el: any) => {
      if (el === "all") {
        return dataCategories.map((category: {id: number; name: string}) => {
          // @ts-ignore
          return {id: parseInt(category.id)};
        });
      } else {
        return {id: parseInt(el)};
      }
    });

    let categoryArr = [];

    if (arrCategoriesID[0]?.length > 1) {
      categoryArr = [...arrCategoriesID[0]];
    } else {
      categoryArr = arrCategoriesID;
    }

    setItemData({
      ...itemData,
      categories: categoryArr,
    });
  }, [elementsChecked]);

  useEffect(() => {
    if (itemInfo) {
      const itemsShopValue = `${itemInfo?.percentShop}`
        ? parseInt(itemInfo?.percentShop)
        : 30;

      const itemsWheelValue = `${itemInfo?.percentShop}`
        ? 100 - parseInt(itemInfo?.percentShop)
        : 70;

      initialItemData = {
        prizeId: itemInfo?.prizeId || "",
        name: itemInfo?.newData?.name || itemInfo?.name || "",
        description: itemInfo?.newData?.hasOwnProperty("description")
          ? itemInfo?.newData?.description
          : itemInfo?.description,
        requiredStamps: itemInfo?.newData?.hasOwnProperty("requiredStamps")
          ? itemInfo?.newData?.requiredStamps
          : itemInfo?.requiredStamps,
        price: itemInfo?.newData?.price || itemInfo?.price || "",
        radius: itemInfo?.newData?.radius || itemInfo?.radius || "",
        offerDiscount:
          itemInfo?.newData?.offerDiscount || itemInfo?.offerDiscount || "",
        prizeCount: itemInfo?.newData?.prizeCount || itemInfo?.prizeCount || "",
        percentShop: `${itemInfo?.percentShop}` ? itemInfo?.percentShop : 30,
        expiredAt: itemInfo?.newData?.expiredAt || itemInfo?.expiredAt || "",
        categories: itemInfo?.newData?.categories || itemInfo?.categories || [],
        promotionType:
          itemInfo?.newData?.promotionType || itemInfo?.promotionType || "",
        perCustomerLimit:
          itemInfo?.newData?.perCustomerLimit ||
          itemInfo?.perCustomerLimit ||
          "",
        ageLimit: itemInfo?.newData?.ageLimit || itemInfo?.ageLimit || "",
        terms: itemInfo?.newData?.terms || itemInfo?.terms || "",
        state: itemInfo?.newData?.state || itemInfo?.state || "",
        itemsShop: itemsShopValue,
        itemsWheel: itemsWheelValue,
      };

      setItemData(initialItemData);

      setItemsShop(itemInfo?.newData?.percentShop || itemsShopValue.toString());
      if (itemInfo?.newData?.percentShop) {
        const diff = 100 - parseInt(itemInfo?.newData?.percentShop);
        setItemsWheel(diff.toString());
      } else {
        setItemsWheel(itemsWheelValue.toString());
      }
    }

    itemInfo?.images.map(
      (image: {
        imageId: number;
        name: string;
        type: string;
        newName: string;
        newUrl: string;
        url: string;
      }) => {
        if (image.type.toLowerCase() === "big") {
          setFirstImg(image?.newUrl || image?.url);
        } else if (image.type.toLowerCase() === "small") {
          setSecondImg(image?.newUrl || image?.url);
        }
      }
    );

    // setFirstImg(itemInfo?.images[0]?.url || "");
    // setSecondImg(itemInfo?.images[1]?.url || "");
  }, [itemInfo]);

  const handleClickNewDataCheckbox = (e: any) => {
    const {id, checked} = e.target;

    setNewDataCheckbox([...newDataCheckbox, id]);

    if (!checked) {
      setNewDataCheckbox(newDataCheckbox.filter((item: any) => item !== id));
    }
  };

  const handleClickCheckbox = (e: any) => {
    const {id, name, checked} = e.target;

    if (id !== "all") {
      if (elementsChecked.includes("all")) {
        setElementsChecked([id]);
        setElementsName([name]);
      } else {
        setElementsChecked([...elementsChecked, id]);
        setElementsName([...elementsName, name]);
      }

      if (!checked) {
        setElementsChecked(elementsChecked.filter((item: any) => item !== id));
        setElementsName(elementsName.filter((item: any) => item !== name));
      }
    } else {
      setElementsChecked(["all"]);
      setElementsName(["All"]);

      if (!checked) {
        setElementsChecked(
          elementsChecked.filter((item: any) => item !== "all")
        );
        setElementsName(elementsName.filter((item: any) => item !== "All"));
      }
    }
  };

  const handleUploadPhoto = (data: any, type: any, showImage: any) => {
    let formData = new FormData();
    formData.append("image", data);
    formData.append("name", data.name);
    formData.append("type", type);

    const imgUrl = URL.createObjectURL(data);
    showImage(imgUrl);

    if (type === "BIG") {
      setObjectImages({...objectImages, firstImage: formData});
    }
    if (type === "SMALL") {
      setObjectImages({...objectImages, secondImage: formData});
    }
    // setArrayImageToUpload([...arrayImageToUpload, { data: formData }])
  };

  // const handleClickCheckboxImage = (e: any) => {
  //   const {id, checked} = e.target;
  //   setNewDataCheckbox([...newDataCheckbox, id]);

  //   if (!checked) {
  //     setNewDataCheckbox(newDataCheckbox.filter((item: any) => item !== id));
  //   }
  // };

  const checkStatus = () => {
    if (itemData?.state) {
      if (itemData?.state.toLowerCase() === "active") {
        return (
          <p className="text-sm font-medium text-green-500 capitalize">
            {itemData?.state}
          </p>
        );
      } else if (itemData?.state.toLowerCase() === "paused") {
        return (
          <p className="text-sm font-medium text-gray-500 capitalize">
            {itemData?.state}
          </p>
        );
      } else if (itemData?.state.toLowerCase() === "archived") {
        return (
          <p className="text-sm font-medium text-red-500 capitalize">
            {itemData?.state}
          </p>
        );
      } else if (itemData?.state.toLowerCase() === "pending_review") {
        return (
          <p className="text-sm font-medium text-yellow-500">Pending Review</p>
        );
      } else if (itemData?.state.toLowerCase() === "declined") {
        return (
          <p className="text-sm font-medium text-red-500">{itemData?.state}</p>
        );
      } else {
        return <p className="text-sm font-medium text-purple-500">Complete</p>;
      }
    }
  };

  return (
    <div className="flex mt-5 flex-wrap">
      <div className="bg-white p-5 pr-0 xl:w-6/7 w-full">
        <form>
          <div className="flex items-center justify-between mb-5 pr-5">
            <Subtitle classes="mt-1">
              {itemInfo?.prizeType === "REWARD" ? "Reward" : "Item"} Details
            </Subtitle>
            <div className="flex">
              <div className="flex items-center pr-5 border-r-2 border-gray-200">
                {
                  // @ts-ignore
                  routes[user.data.role].urlAllowed?.item?.includes("edit") && (
                    <>
                      <p className="font-medium mr-2">Unlock inputs: </p>
                      <Switch defaultChecked={false} as={Fragment}>
                        {({checked}) => (
                          <button
                            className={`${
                              checked ? "bg-primary-purple" : "bg-gray-200"
                            } relative inline-flex h-7 w-12 mt-1 items-center rounded-full`}
                            onClick={() => setIsInputDisabled(checked)}
                          >
                            <span className="sr-only">
                              Enable notifications
                            </span>
                            <span
                              className={`${
                                checked ? "translate-x-6" : "translate-x-1"
                              } inline-block h-5 w-5 transform rounded-full bg-white transition`}
                            />
                          </button>
                        )}
                      </Switch>
                    </>
                  )
                }
              </div>
              <div className="flex items-center px-5 border-r-2 border-gray-200">
                {checkStatus()}
              </div>
              <div className="flex items-center px-5 border-r-2 border-gray-200">
                <div className="rounded px-3 py-1 bg-primary-white flex items-center">
                  <AddOffer classes="h-5 w-5 mr-1" />
                  <p className="text-sm font-medium capitalize">
                    {itemInfo?.prizeType === "REGULAR"
                      ? "Prize"
                      : itemInfo?.prizeType === "OFFER"
                      ? "Offer"
                      : "Reward"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <Item
            itemData={itemData}
            itemInfo={itemInfo}
            setItemData={setItemData}
            isInputDisabled={isInputDisabled}
            inputError={inputError}
            handleClickNewDataCheckbox={handleClickNewDataCheckbox}
            newDataCheckbox={newDataCheckbox}
            itemsShop={itemsShop}
            setItemsWheel={setItemsWheel}
            itemsWheel={itemsWheel}
            errorInItemsCount={errorInItemsCount}
            setElementsChecked={setElementsChecked}
            setElementsName={setElementsName}
            setItemsShop={setItemsShop}
            elementsChecked={elementsChecked}
            elementsName={elementsName}
            handleClickCheckbox={handleClickCheckbox}
            dataCategories={dataCategories}
            dropdownSearchFilter={dropdownSearchFilter}
            isErrorCategories={isErrorCategories}
            isLoadingCategories={isLoadingCategories}
            error={error}
            setDowpdownSearchFilter={setDowpdownSearchFilter}
            elementsParentName={elementsParentName}
            handleUploadPhoto={handleUploadPhoto}
            firstImage={firstImage}
            setFirstImg={setFirstImg}
            imageCropModalType={imageCropModalType}
            setImageCropModalType={setImageCropModalType}
            newImageUpload={newImageUpload}
            setNewImageUpload={setNewImageUpload}
            secondImage={secondImage}
            setSecondImg={setSecondImg}
          />
        </form>
      </div>
    </div>
  );
}

export default Details;
