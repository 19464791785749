import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import {Link, useLocation, useParams, useNavigate} from "react-router-dom";
import {Dialog} from "@headlessui/react";

// Components
import Stats from "./Tabs/Stats";
import Details from "./TabsGrandPrize/Details";
import Merchant from "./Tabs/Merchant";
import Tabs from "components/Common/Tabs";
import Title from "components/Common/Title";
import Button from "components/Common/Button";
import Modal from "components/Modal";
import Select from "components/Common/Input/Select";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {merchantsApi} from "store/api/merchantsApi";
import {itemApi, useApproveGPChangesMutation} from "store/api/itemApi";
import {RootState} from "store/store";
import {
  useChangeStatusGrandPrizeMutation,
  useCreateDeclineReasonsMutation,
  useGetDeclineReasonsQuery,
  useGetIndividualGrandPrizeByMerchantIDQuery,
  useUpdateGrandPrizeImageItemMutation,
  useUpdateGrandPrizeMutation,
} from "store/api/itemApi";

// Icons
import {ArrowBack} from "assets/Arrows";
import Archive from "assets/Archive";
import Publish from "assets/Publish";
import Alert from "assets/Alert";
import Recovery from "assets/Recovery";
import removeDuplicatesCategories from "utils/removeDuplicatesCategories";
import {setResetElChecked} from "store/table/currentTableDataSlice";
import routes from "utils/routesByRole";
import Save from "assets/Save";
import Label from "components/Common/Label";
import Textarea from "components/Common/Textarea";

function GrandPrizeDetails() {
  const {id} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modal: any = useSelector((state: RootState) => state.modal);
  const user = useSelector((state: RootState) => state.user.user.user);

  const [itemData, setItemData] = useState({
    prizeId: "",
    retailerId: "",
    name: "",
    price: "",
    prizeCount: "",
    description: "",
    startDate: "",
    endDate: "",
    prizeType: "",
    terms: "",
    status: "",
    state: "",
    purchaseUrl: "",
    discountCode: "",
    categories: [],
    winrateType: "",
    redemptionCodes: "",
    redemptionUrl: "",
    businessWebsite: "",
    businessEmail: "",
    shippingTime: "",
  });

  // Images
  const [objectImages, setObjectImages] = useState<{
    coverPhoto: any;
    regular1: any;
    regular2: any;
    regular3: any;
    regular4: any;
    regular5: any;
    regular6: any;
    regular7: any;
    regular8: any;
    video: any;
  }>({
    coverPhoto: "",
    regular1: "",
    regular2: "",
    regular3: "",
    regular4: "",
    regular5: "",
    regular6: "",
    regular7: "",
    regular8: "",
    video: "",
  });

  const [arrayImageToUpload, setArrayImageToUpload] = useState<any>([]);
  const [imageCropModalType, setImageCropModalType] = useState("default");

  const [coverGrandPrize, setCoverGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [firstRegularGrandPrize, setFirstRegularGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [secondRegularGrandPrize, setSecondRegularGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [thridRegularGrandPrize, setThirdRegularGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [fourthRegularGrandPrize, setFourthRegularGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [fifthRegularGrandPrize, setFifthRegularGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [sixthRegularGrandPrize, setSixthRegularGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [seventhRegularGrandPrize, setSeventhRegularGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [eighthRegularGrandPrize, setEighthRegularGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [videoGrandPrize, setVideoGrandPrize] = useState({
    src: "",
    description: "",
  });

  const [itemsTypeRedemption, setItemsTypeRedemption] = useState({
    redemptionCodes: "",
    redemptionUrl: "",
  });

  const [itemsTypeShipping, setItemsTypeShipping] = useState({
    businessWebsite: "",
    businessEmail: "",
    shippingTime: "",
  });

  const [selectedTab, setSelectedTab] = useState("");
  const [inputError, setInputError] = useState(false);

  const [reasonsInput, setReasonsInput] = useState<string[]>([]);

  const [reason, setReason] = useState();
  const [declinedItems, setDeclinedItems] = useState<string[]>([]);
  const [approvedItems, setApprovedItems] = useState<string[]>([]);
  const [btnApprove, setBtnApprove] = useState<boolean>(true);
  const [reasonDescription, setReasonDescription] = useState("");

  const [newDataCheckbox, setNewDataCheckbox] = useState<string[]>([]);

  // Mutations
  const [updateStatusMutation] = useChangeStatusGrandPrizeMutation();
  const [updateGrandPrize] = useUpdateGrandPrizeMutation();
  const [updateImageGrandPrize] = useUpdateGrandPrizeImageItemMutation();

  const {
    data: dataItem,
    isLoading: isLoadingItem,
    isError: isErrorItem,
    refetch,
  } = useGetIndividualGrandPrizeByMerchantIDQuery({
    idMerchant: location?.state?.idMerchant,
    idItem: location?.state?.idItem,
  });

  const {
    data: declineReasons,
    isLoading: isLoadingDeclineReasons,
    isError: isErrorDeclineReasons,
    // @ts-ignore
  } = useGetDeclineReasonsQuery();

  const [applyChanges] = useApproveGPChangesMutation();

  useEffect(() => {
    if (isLoadingItem || isErrorItem) dispatch(setOpenModal(true));
    else {
      dispatch(setOpenModal(false));
    }
  }, [isLoadingItem, isErrorItem]);

  useEffect(() => {
    const arrFormData = [
      {data: objectImages.video},
      {data: objectImages.coverPhoto},
      {data: objectImages.regular1},
      {data: objectImages.regular2},
      {data: objectImages.regular3},
      {data: objectImages.regular4},
      {data: objectImages.regular5},
      {data: objectImages.regular6},
      {data: objectImages.regular7},
      {data: objectImages.regular8},
    ];

    setArrayImageToUpload(arrFormData);
  }, [objectImages]);

  const handleSelectedTab = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleCloseModal = () => {
    dispatch(setType("declineModal"));
    dispatch(setOpenModal(false));
  };

  const handleUploadPhoto = (data: any, type: any, showImage: any) => {
    let imgUrl;

    if (typeof data !== "string") {
      imgUrl = URL.createObjectURL(data);
    } else {
      imgUrl = data;
    }

    showImage(imgUrl);

    if (type === "COVER_PHOTO") {
      showImage({src: imgUrl, description: coverGrandPrize.description});

      setObjectImages({
        ...objectImages,
        coverPhoto: {
          data: data,
          type: type,
          description: coverGrandPrize.description,
        },
      });
    }

    if (type === "VIDEO") {
      showImage({
        src: imgUrl,
        description: videoGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        video: {
          data: data,
          type: type,
          description: videoGrandPrize.description,
        },
      });
    }

    if (type === "REGULAR1") {
      showImage({
        src: imgUrl,
        description: firstRegularGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        regular1: {
          data: data,
          type: type,
          description: firstRegularGrandPrize.description,
        },
      });
    }

    if (type === "REGULAR2") {
      showImage({
        src: imgUrl,
        description: secondRegularGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        regular2: {
          data: data,
          type: type,
          description: secondRegularGrandPrize.description,
        },
      });
    }
    if (type === "REGULAR3") {
      showImage({
        src: imgUrl,
        description: thridRegularGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        regular3: {
          data: data,
          type: type,
          description: thridRegularGrandPrize.description,
        },
      });
    }
    if (type === "REGULAR4") {
      showImage({
        src: imgUrl,
        description: fourthRegularGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        regular4: {
          data: data,
          type: type,
          description: fourthRegularGrandPrize.description,
        },
      });
    }
    if (type === "REGULAR5") {
      showImage({
        src: imgUrl,
        description: fifthRegularGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        regular5: {
          data: data,
          type: type,
          description: fifthRegularGrandPrize.description,
        },
      });
    }
    if (type === "REGULAR6") {
      showImage({
        src: imgUrl,
        description: sixthRegularGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        regular6: {
          data: data,
          type: type,
          description: sixthRegularGrandPrize.description,
        },
      });
    }
    if (type === "REGULAR7") {
      showImage({
        src: imgUrl,
        description: seventhRegularGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        regular7: {
          data: data,
          type: type,
          description: seventhRegularGrandPrize.description,
        },
      });
    }
    if (type === "REGULAR8") {
      showImage({
        src: imgUrl,
        description: eighthRegularGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        regular8: {
          data: data,
          type: type,
          description: eighthRegularGrandPrize.description,
        },
      });
    }
  };

  const handleUpdateItem = async () => {
    if (
      !itemData.name ||
      !itemData.price ||
      !itemData.prizeCount ||
      !itemData.startDate ||
      !itemData.endDate ||
      !itemData.description ||
      !itemData.winrateType ||
      !itemData.terms ||
      (itemData.discountCode && !itemData.purchaseUrl) ||
      (!itemData.discountCode && itemData.purchaseUrl) ||
      !(
        (coverGrandPrize.src && coverGrandPrize.description) ||
        (videoGrandPrize.src && videoGrandPrize.description)
      ) ||
      (firstRegularGrandPrize.src && !firstRegularGrandPrize.description) ||
      (secondRegularGrandPrize.src && !secondRegularGrandPrize.description) ||
      (thridRegularGrandPrize.src && !thridRegularGrandPrize.description) ||
      (fourthRegularGrandPrize.src && !fourthRegularGrandPrize.description) ||
      (fifthRegularGrandPrize.src && !fifthRegularGrandPrize.description) ||
      (sixthRegularGrandPrize.src && !sixthRegularGrandPrize.description) ||
      (seventhRegularGrandPrize.src && !seventhRegularGrandPrize.description) ||
      (eighthRegularGrandPrize.src && !eighthRegularGrandPrize.description)
    ) {
      setInputError(true);
      toast.error("You must fill all the required fields");
      return;
    }

    if (
      itemData.purchaseUrl &&
      itemData.discountCode &&
      itemData.discountCode.length < 4
    ) {
      toast.error("The referral code cannot be shorter than 4 characters");
      return;
    }

    setImageCropModalType("default");

    let dataToSend = {
      prizeType: itemData.prizeType,
      name: itemData.name,
      description: itemData.description,
      prizeCount: parseInt(itemData.prizeCount),
      startDate: itemData.startDate,
      endDate: itemData.endDate,
      price: parseInt(itemData.price),
      categories: removeDuplicatesCategories(itemData.categories),
      winrateType: itemData.winrateType,
      purchaseUrl: itemData.purchaseUrl,
      discountCode: itemData.discountCode,
      terms: itemData.terms,
    };

    if (itemData.prizeType === "REDEMPTION_CODE") {
      const cleanupArr = itemsTypeRedemption.redemptionCodes
        .split(",")
        .map((el) => el.trim());

      dataToSend = {
        ...dataToSend,
        // @ts-ignore
        redemptionUrl: itemsTypeRedemption.redemptionUrl,
        redemptionCodes: cleanupArr,
        categories: removeDuplicatesCategories(itemData.categories),
        startDate: new Date(itemData.startDate)?.toISOString(),
        endDate: new Date(itemData.endDate)?.toISOString(),
        price: parseInt(itemData.price),
      };
    }

    if (itemData.prizeType === "QR_CODE") {
      dataToSend = {
        ...dataToSend,
        categories: removeDuplicatesCategories(itemData.categories),
        startDate: new Date(itemData.startDate)?.toISOString(),
        endDate: new Date(itemData.endDate)?.toISOString(),
      };
    }

    if (itemData.prizeType === "SHIP_TO_ADDRESS") {
      dataToSend = {
        ...dataToSend,
        // @ts-ignore
        ...itemsTypeShipping,
        categories: removeDuplicatesCategories(itemData.categories),
        startDate: new Date(itemData.startDate)?.toISOString(),
        endDate: new Date(itemData.endDate)?.toISOString(),
      };
    }

    const toastPromise = toast.promise(
      updateGrandPrize({
        // @ts-ignore
        idItem: id?.toString(),
        idMerchant: dataItem?.retailerId?.toString(),
        data: dataToSend,
      }),
      {
        pending: "Updating Grand Prize",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while updating item</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Item updated");

          const imagesUpload = arrayImageToUpload.map(
            (image: {data: any}, key: number) => {
              // if (!image.data.data) return;

              console.log(image, "image");

              let formData = new FormData();

              const imageType = image?.data?.type;

              if (
                imageType === "VIDEO" ||
                (videoGrandPrize.description && key === 0)
              ) {
                if (
                  image?.data &&
                  image?.data !== "" &&
                  typeof image?.data?.data !== "string"
                ) {
                  formData.append("media", image?.data?.data);
                } else {
                  formData.append("mediaUrl", videoGrandPrize.src);
                }

                formData.append(
                  "name",
                  image?.data?.data?.name ||
                    videoGrandPrize?.src?.split("/")?.pop()
                );
                formData.append("type", image?.data?.type || "VIDEO");
                formData.append("description", videoGrandPrize.description);

                // console.log("Send cover photo", formData);
                updateItemImages(
                  {data: formData},
                  key,
                  imageType ? imageType : "VIDEO"
                );
              } else if (
                imageType === "COVER_PHOTO" ||
                (coverGrandPrize.description && key === 1)
              ) {
                if (
                  image?.data &&
                  image?.data !== "" &&
                  typeof image?.data?.data !== "string"
                ) {
                  formData.append("media", image?.data?.data);
                } else {
                  formData.append("mediaUrl", coverGrandPrize.src);
                }
                formData.append(
                  "name",
                  image?.data?.data?.name ||
                    coverGrandPrize?.src?.split("/")?.pop()
                );
                formData.append("type", image?.data?.type || "COVER_PHOTO");
                formData.append("description", coverGrandPrize.description);

                updateItemImages(
                  {data: formData},
                  key,
                  imageType ? imageType : "COVER_PHOTO"
                );
              } else if (
                imageType === "REGULAR1" ||
                (firstRegularGrandPrize.description && key === 2)
              ) {
                if (
                  image?.data &&
                  image?.data !== "" &&
                  typeof image?.data?.data !== "string"
                ) {
                  formData.append("media", image?.data?.data);
                } else {
                  formData.append("mediaUrl", firstRegularGrandPrize.src);
                }
                formData.append(
                  "name",
                  image?.data?.data?.name ||
                    firstRegularGrandPrize?.src?.split("/")?.pop()
                );
                formData.append("type", image?.data?.type || "REGULAR1");
                formData.append(
                  "description",
                  firstRegularGrandPrize.description
                );

                updateItemImages(
                  {data: formData},
                  key,
                  imageType ? imageType : "REGULAR1"
                );
              } else if (
                imageType === "REGULAR2" ||
                (secondRegularGrandPrize.description && key === 3)
              ) {
                if (
                  image?.data &&
                  image?.data !== "" &&
                  typeof image?.data?.data !== "string"
                ) {
                  formData.append("media", image?.data?.data);
                } else {
                  formData.append("mediaUrl", secondRegularGrandPrize.src);
                }
                formData.append(
                  "name",
                  image?.data?.data?.name ||
                    secondRegularGrandPrize?.src?.split("/")?.pop()
                );
                formData.append("type", image?.data?.type || "REGULAR2");
                formData.append(
                  "description",
                  secondRegularGrandPrize.description
                );

                updateItemImages(
                  {data: formData},
                  key,
                  imageType ? imageType : "REGULAR2"
                );
              } else if (
                imageType === "REGULAR3" ||
                (thridRegularGrandPrize.description && key === 4)
              ) {
                if (
                  image?.data &&
                  image?.data !== "" &&
                  typeof image?.data?.data !== "string"
                ) {
                  formData.append("media", image?.data?.data);
                } else {
                  formData.append("mediaUrl", thridRegularGrandPrize.src);
                }
                formData.append(
                  "name",
                  image?.data?.data?.name ||
                    thridRegularGrandPrize?.src?.split("/")?.pop()
                );
                formData.append("type", image?.data?.type || "REGULAR3");
                formData.append(
                  "description",
                  thridRegularGrandPrize.description
                );

                updateItemImages(
                  {data: formData},
                  key,
                  imageType ? imageType : "REGULAR3"
                );
              } else if (
                imageType === "REGULAR4" ||
                (fourthRegularGrandPrize.description && key === 5)
              ) {
                if (
                  image?.data &&
                  image?.data !== "" &&
                  typeof image?.data?.data !== "string"
                ) {
                  formData.append("media", image?.data?.data);
                } else {
                  formData.append("mediaUrl", fourthRegularGrandPrize.src);
                }
                formData.append(
                  "name",
                  image?.data?.data?.name ||
                    fourthRegularGrandPrize?.src?.split("/")?.pop()
                );
                formData.append("type", image?.data?.type || "REGULAR4");
                formData.append(
                  "description",
                  fourthRegularGrandPrize.description
                );

                updateItemImages(
                  {data: formData},
                  key,
                  imageType ? imageType : "REGULAR4"
                );
              } else if (
                imageType === "REGULAR5" ||
                (fifthRegularGrandPrize.description && key === 6)
              ) {
                if (
                  image?.data &&
                  image?.data !== "" &&
                  typeof image?.data?.data !== "string"
                ) {
                  formData.append("media", image?.data?.data);
                } else {
                  formData.append("mediaUrl", fifthRegularGrandPrize.src);
                }
                formData.append(
                  "name",
                  image?.data?.data?.name ||
                    fifthRegularGrandPrize?.src?.split("/")?.pop()
                );
                formData.append("type", image?.data?.type || "REGULAR5");
                formData.append(
                  "description",
                  fifthRegularGrandPrize.description
                );

                // console.log("Send cover photo", formData);
                updateItemImages(
                  {data: formData},
                  key,
                  imageType ? imageType : "REGULAR5"
                );
              } else if (
                imageType === "REGULAR6" ||
                (sixthRegularGrandPrize.description && key === 7)
              ) {
                if (
                  image?.data &&
                  image?.data !== "" &&
                  typeof image?.data?.data !== "string"
                ) {
                  formData.append("media", image?.data?.data);
                } else {
                  formData.append("mediaUrl", sixthRegularGrandPrize.src);
                }
                formData.append(
                  "name",
                  image?.data?.data?.name ||
                    sixthRegularGrandPrize?.src?.split("/")?.pop()
                );
                formData.append("type", image?.data?.type || "REGULAR6");
                formData.append(
                  "description",
                  sixthRegularGrandPrize.description
                );

                // console.log("Send cover photo", formData);
                updateItemImages(
                  {data: formData},
                  key,
                  imageType ? imageType : "REGULAR6"
                );
              } else if (
                imageType === "REGULAR7" ||
                (seventhRegularGrandPrize.description && key === 8)
              ) {
                if (
                  image?.data &&
                  image?.data !== "" &&
                  typeof image?.data?.data !== "string"
                ) {
                  formData.append("media", image?.data?.data);
                } else {
                  formData.append("mediaUrl", seventhRegularGrandPrize.src);
                }
                formData.append(
                  "name",
                  image?.data?.data?.name ||
                    seventhRegularGrandPrize?.src?.split("/")?.pop()
                );
                formData.append("type", image?.data?.type || "REGULAR7");
                formData.append(
                  "description",
                  seventhRegularGrandPrize.description
                );

                // console.log("Send cover photo", formData);
                updateItemImages(
                  {data: formData},
                  key,
                  imageType ? imageType : "REGULAR7"
                );
              } else if (
                imageType === "REGULAR8" ||
                (eighthRegularGrandPrize.description && key === 9)
              ) {
                if (
                  image?.data &&
                  image?.data !== "" &&
                  typeof image?.data?.data !== "string"
                ) {
                  formData.append("media", image?.data?.data);
                } else {
                  formData.append("mediaUrl", eighthRegularGrandPrize.src);
                }
                formData.append(
                  "name",
                  image?.data?.data?.name ||
                    eighthRegularGrandPrize?.src?.split("/")?.pop()
                );
                formData.append("type", image?.data?.type || "REGULAR8");
                formData.append(
                  "description",
                  eighthRegularGrandPrize.description
                );

                // console.log("Send cover photo", formData);
                updateItemImages(
                  {data: formData},
                  key,
                  imageType ? imageType : "REGULAR8"
                );
              }
            }
          );

          Promise.all(imagesUpload).then(() => {
            toast.success("Media Updated");
          });

          // toast.promise(
          //   updateStatusMutation({
          //     // @ts-ignore
          //     idItem: id,
          //     idMerchant: dataItem?.retailerId?.toString(),
          //     status: "PENDING_REVIEW",
          //   }),
          //   {}
          // );
        }
      })
      .catch((err) => {
        toast.error("Error while updating item");
      });
  };

  const updateItemImages = async (
    imageToUpload: any,
    key: number,
    imageType: string
  ) => {
    // console.log(imageToUpload, key, imageType);
    const toastPromiseImage = toast.promise(
      updateImageGrandPrize({
        ...imageToUpload,
        idMerchant: dataItem?.retailerId?.toString(),
        idItem: id,
      }),
      {}
    );
    await toastPromiseImage
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while uplading image {key}</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          // const message = () => {
          //   if (imageType === "COVER_PHOTO" || imageType === "VIDEO")
          //     return imageType.toLowerCase();
          //   return imageType.toLowerCase() + " photo";
          // };
          // toast.success(`${message()} uploaded`);
        }
      })
      .catch((err) => {
        toast.error(`Error while uplading image ${key}`);
      });
  };

  const handlePublishItem = () => {
    if (dataItem?.state === "ARCHIVED") {
      changeStatusItem({
        idItem: id,
        idMerchant: dataItem?.retailerId,
        status: "PENDING_REVIEW",
      });
    } else if (
      dataItem?.state === "PENDING_REVIEW" ||
      dataItem?.state === "PAUSED"
    ) {
      changeStatusItem({
        idItem: id,
        idMerchant: dataItem?.retailerId,
        status: "ACTIVE",
      });
    }
  };

  const changeStatusItem = async ({
    idItem,
    idMerchant,
    status,
  }: {
    idItem: string | undefined;
    idMerchant: string;
    status: string;
  }) => {
    if (!idItem && idItem !== undefined && !idMerchant && !status) return;

    const toastPromise = toast.promise(
      updateStatusMutation({
        // @ts-ignore
        idItem: idItem?.toString(),
        idMerchant: idMerchant?.toString(),
        status: status.toUpperCase(),
      }),
      {}
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while changing item status</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          if (status === "ACTIVE") {
            toast.success("Item status changed");
            return;
          }

          const toastPromise = toast.promise(
            updateStatusMutation({
              // @ts-ignore
              idItem: idItem?.toString(),
              idMerchant: idMerchant?.toString(),
              status: status === "PAUSED" ? "ARCHIVED" : "PENDING_REVIEW",
            }),
            {
              pending: "Changing item status",
            }
          );

          (async () => {
            await toastPromise
              .then((res: any) => {
                if (res.error) {
                  toast.error(
                    <div>
                      <h2>Error while changing item status</h2>
                      <p className="text-xs"> {res?.error?.data?.message} </p>
                    </div>
                  );
                } else {
                  toast.success("Item status changed");
                  // Re fetch items to update UI
                  dispatch(
                    merchantsApi.util.invalidateTags(["MerchantGrandPrize"])
                  );
                  dispatch(
                    itemApi.util.invalidateTags(["IndividualGrandPrize"])
                  );
                }
              })
              .catch((err) => {
                toast.error("Error while changing item status");
              });

            // Re fetch items to update UI
          })();
        }
      })
      .catch((err) => {
        toast.error("Error while changing item status");
      })
      .finally(() => {
        dispatch(setResetElChecked(true));
        setTimeout(() => {
          dispatch(setType(""));
          dispatch(setOpenModal(false));
        }, 1000);

        dispatch(merchantsApi.util.invalidateTags(["MerchantGrandPrize"]));
        dispatch(itemApi.util.invalidateTags(["IndividualGrandPrize"]));
      });
  };

  const handleFormatElement = (element: string) => {
    if (element.toLowerCase() === "prizecount") {
      return "amount prizes";
    }
    if (element.toLowerCase() === "startdate") {
      return "start date";
    }
    if (element.toLowerCase() === "winratetype") {
      return "win rate type";
    }
    if (element.toLowerCase() === "enddate") {
      return "end date";
    }
    if (element.toLowerCase() === "prizetype") {
      return "prize type";
    }
    if (element.toLowerCase() === "purchaseurl") {
      return "purchase url";
    }
    if (element.toLowerCase() === "discountcode") {
      return "discount code";
    }
    if (element.toLowerCase() === "redemptioncodes") {
      return "redemption codes";
    }
    if (element.toLowerCase() === "redemptionurl") {
      return "redemption url";
    }
    if (element.toLowerCase() === "businesswebsite") {
      return "business website";
    }
    if (element.toLowerCase() === "businessemail") {
      return "business email";
    }
    if (element.toLowerCase() === "shippingtime") {
      return "shipping time";
    }
    if (element.toLowerCase() === "media#video") {
      return "video";
    }
    if (element.toLowerCase() === "media#cover_photo") {
      return "cover photo";
    }
    if (element.toLowerCase() === "media#regular1") {
      return "regular image 1";
    }
    if (element.toLowerCase() === "media#regular2") {
      return "regular image 2";
    }
    if (element.toLowerCase() === "media#regular3") {
      return "regular image 3";
    }
    if (element.toLowerCase() === "media#regular4") {
      return "regular image 4";
    }
    if (element.toLowerCase() === "media#regular5") {
      return "regular image 5";
    }
    if (element.toLowerCase() === "media#regular6") {
      return "regular image 6";
    }
    if (element.toLowerCase() === "media#regular7") {
      return "regular image 7";
    }
    if (element.toLowerCase() === "media#regular8") {
      return "regular image 8";
    }
    if (element.toLowerCase() === "media#video#description") {
      return "video description";
    }
    if (element.toLowerCase() === "media#cover_photo#description") {
      return "cover photo description";
    }
    if (element.toLowerCase() === "media#regular1#description") {
      return "regular image 1 description";
    }
    if (element.toLowerCase() === "media#regular2#description") {
      return "regular image 2 description";
    }
    if (element.toLowerCase() === "media#regular3#description") {
      return "regular image 3 description";
    }
    if (element.toLowerCase() === "media#regular4#description") {
      return "regular image 4 description";
    }
    if (element.toLowerCase() === "media#regular5#description") {
      return "regular image 5 description";
    }
    if (element.toLowerCase() === "media#regular6#description") {
      return "regular image 6 description";
    }
    if (element.toLowerCase() === "media#regular7#description") {
      return "regular image 7 description";
    }
    if (element.toLowerCase() === "media#regular8#description") {
      return "regular image 8 description";
    } else {
      return element;
    }
  };

  const handleSendDeclineReason = async () => {
    if (!reason || !reasonDescription) {
      toast.error("Provide reason and description for decline");
      setInputError(true);
      return;
    }

    const checkIfIsApproved = (fieldName: string) => {
      const isNotApproved = declinedItems.find(
        (reason) => reason === fieldName
      );

      return isNotApproved ? false : true;
    };

    const data = {
      description: reasonDescription,
      approvedFields: approvedItems,
      declinedFields: declinedItems,
      reasonId: parseInt(reason),
      fullInfo: [
        {
          fieldName: "prizeId",
          fieldValue: itemData.prizeId,
          isApproved: checkIfIsApproved("prizeId"),
        },
        {
          fieldName: "retailerId",
          fieldValue: itemData.retailerId,
          isApproved: true,
        },
        {
          fieldName: "name",
          fieldValue: itemData.name,
          isApproved: checkIfIsApproved("name"),
        },
        {
          fieldName: "price",
          fieldValue: itemData.price,
          isApproved: checkIfIsApproved("price"),
        },
        {
          fieldName: "prizeCount",
          fieldValue: itemData.prizeCount,
          isApproved: checkIfIsApproved("prizeCount"),
        },
        {
          fieldName: "description",
          fieldValue: itemData.description,
          isApproved: checkIfIsApproved("description"),
        },
        {
          fieldName: "startDate",
          fieldValue: itemData.startDate,
          isApproved: checkIfIsApproved("startDate"),
        },
        {
          fieldName: "endDate",
          fieldValue: itemData.endDate,
          isApproved: checkIfIsApproved("endDate"),
        },
        {
          fieldName: "prizeType",
          fieldValue: itemData.prizeType,
          isApproved: checkIfIsApproved("prizeType"),
        },
        {
          fieldName: "terms",
          fieldValue: itemData.terms,
          isApproved: checkIfIsApproved("terms"),
        },
        {
          fieldName: "purchaseUrl",
          fieldValue: itemData.purchaseUrl,
          isApproved: checkIfIsApproved("purchaseUrl"),
        },
        {
          fieldName: "discountCode",
          fieldValue: itemData.discountCode,
          isApproved: checkIfIsApproved("discountCode"),
        },
        {
          fieldName: "redemptionCodes",
          fieldValue: itemData.redemptionCodes,
          isApproved: checkIfIsApproved("redemptionCodes"),
        },
        {
          fieldName: "redemptionUrl",
          fieldValue: itemData.redemptionUrl,
          isApproved: checkIfIsApproved("redemptionUrl"),
        },
        {
          fieldName: "businessWebsite",
          fieldValue: itemData.businessWebsite,
          isApproved: checkIfIsApproved("businessWebsite"),
        },
        {
          fieldName: "businessEmail",
          fieldValue: itemData.businessEmail,
          isApproved: checkIfIsApproved("businessEmail"),
        },
        {
          fieldName: "shippingTime",
          fieldValue: itemData.shippingTime,
          isApproved: checkIfIsApproved("shippingTime"),
        },
        {
          fieldName: "categories",
          fieldValue: itemData.categories.join(" ,") || "",
          isApproved: checkIfIsApproved("categories"),
        },
        {
          fieldName: "winrateType",
          fieldValue: itemData.winrateType,
          isApproved: checkIfIsApproved("winrateType"),
        },
        {
          fieldName: "media#VIDEO",
          fieldValue: videoGrandPrize.src,
          isApproved: checkIfIsApproved("media#VIDEO"),
        },
        {
          fieldName: "media#COVER_PHOTO",
          fieldValue: coverGrandPrize.src,
          isApproved: checkIfIsApproved("media#COVER_PHOTO"),
        },
        {
          fieldName: "media#REGULAR1",
          fieldValue: firstRegularGrandPrize.src,
          isApproved: checkIfIsApproved("media#REGULAR1"),
        },
        {
          fieldName: "media#REGULAR2",
          fieldValue: secondRegularGrandPrize.src,
          isApproved: checkIfIsApproved("media#REGULAR2"),
        },
        {
          fieldName: "media#REGULAR3",
          fieldValue: thridRegularGrandPrize.src,
          isApproved: checkIfIsApproved("media#REGULAR3"),
        },
        {
          fieldName: "media#REGULAR4",
          fieldValue: fourthRegularGrandPrize.src,
          isApproved: checkIfIsApproved("media#REGULAR4"),
        },
        {
          fieldName: "media#REGULAR5",
          fieldValue: fifthRegularGrandPrize.src,
          isApproved: checkIfIsApproved("media#REGULAR5"),
        },
        {
          fieldName: "media#REGULAR6",
          fieldValue: sixthRegularGrandPrize.src,
          isApproved: checkIfIsApproved("media#REGULAR6"),
        },
        {
          fieldName: "media#REGULAR7",
          fieldValue: seventhRegularGrandPrize.src,
          isApproved: checkIfIsApproved("media#REGULAR7"),
        },
        {
          fieldName: "media#REGULAR8",
          fieldValue: eighthRegularGrandPrize.src,
          isApproved: checkIfIsApproved("media#REGULAR8"),
        },
        {
          fieldName: "media#VIDEO#description",
          fieldValue: videoGrandPrize.description,
          isApproved: checkIfIsApproved("media#VIDEO#description"),
        },
        {
          fieldName: "media#COVER_PHOTO#description",
          fieldValue: coverGrandPrize.description,
          isApproved: checkIfIsApproved("media#COVER_PHOTO#description"),
        },
        {
          fieldName: "media#REGULAR1#description",
          fieldValue: firstRegularGrandPrize.description,
          isApproved: checkIfIsApproved("media#REGULAR1#description"),
        },
        {
          fieldName: "media#REGULAR2#description",
          fieldValue: secondRegularGrandPrize.description,
          isApproved: checkIfIsApproved("media#REGULAR2#description"),
        },
        {
          fieldName: "media#REGULAR3#description",
          fieldValue: thridRegularGrandPrize.description,
          isApproved: checkIfIsApproved("media#REGULAR3#description"),
        },
        {
          fieldName: "media#REGULAR4#description",
          fieldValue: fourthRegularGrandPrize.description,
          isApproved: checkIfIsApproved("media#REGULAR4#description"),
        },
        {
          fieldName: "media#REGULAR5#description",
          fieldValue: fifthRegularGrandPrize.description,
          isApproved: checkIfIsApproved("media#REGULAR5#description"),
        },
        {
          fieldName: "media#REGULAR6#description",
          fieldValue: sixthRegularGrandPrize.description,
          isApproved: checkIfIsApproved("media#REGULAR6#description"),
        },
        {
          fieldName: "media#REGULAR7#description",
          fieldValue: seventhRegularGrandPrize.description,
          isApproved: checkIfIsApproved("media#REGULAR7#description"),
        },
        {
          fieldName: "media#REGULAR8#description",
          fieldValue: eighthRegularGrandPrize.description,
          isApproved: checkIfIsApproved("media#REGULAR8#description"),
        },
      ],
    };

    const toastPromise = toast.promise(
      applyChanges({
        idRetailer: dataItem.retailerId,
        idPrize: dataItem.prizeId,
        data,
      }),
      {
        pending: "Declining Grand Prize",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while declining grand prize</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Grand Prize Declined");
          dispatch(setType(""));
          setNewDataCheckbox([]);
          dispatch(setOpenModal(false));
          refetch();
        }
      })
      .catch((err) => {
        toast.error("Error while decliging grand prize");
      });
  };

  const handleApprove = async () => {
    const data = {
      description: "",
      approvedFields: approvedItems,
      declinedFields: declinedItems,
      reasonId: 0,
      fullInfo: [
        {
          fieldName: "prizeId",
          fieldValue: itemData.prizeId,
          isApproved: true,
        },
        {
          fieldName: "retailerId",
          fieldValue: itemData.retailerId,
          isApproved: true,
        },
        {fieldName: "name", fieldValue: itemData.name, isApproved: true},
        {
          fieldName: "price",
          fieldValue: itemData.price,
          isApproved: true,
        },
        {
          fieldName: "prizeCount",
          fieldValue: itemData.prizeCount,
          isApproved: true,
        },
        {
          fieldName: "description",
          fieldValue: itemData.description,
          isApproved: true,
        },
        {
          fieldName: "startDate",
          fieldValue: itemData.startDate,
          isApproved: true,
        },
        {
          fieldName: "endDate",
          fieldValue: itemData.endDate,
          isApproved: true,
        },
        {
          fieldName: "prizeType",
          fieldValue: itemData.prizeType,
          isApproved: true,
        },
        {
          fieldName: "terms",
          fieldValue: itemData.terms,
          isApproved: true,
        },
        {
          fieldName: "purchaseUrl",
          fieldValue: itemData.purchaseUrl,
          isApproved: true,
        },
        {
          fieldName: "discountCode",
          fieldValue: itemData.discountCode,
          isApproved: true,
        },
        {
          fieldName: "redemptionCodes",
          fieldValue: itemData.redemptionCodes,
          isApproved: true,
        },
        {
          fieldName: "redemptionUrl",
          fieldValue: itemData.redemptionUrl,
          isApproved: true,
        },
        {
          fieldName: "businessWebsite",
          fieldValue: itemData.businessWebsite,
          isApproved: true,
        },
        {
          fieldName: "businessEmail",
          fieldValue: itemData.businessEmail,
          isApproved: true,
        },
        {
          fieldName: "shippingTime",
          fieldValue: itemData.shippingTime,
          isApproved: true,
        },
        {
          fieldName: "categories",
          fieldValue: itemData.categories.join(" ,") || "",
          isApproved: true,
        },
        {
          fieldName: "winrateType",
          fieldValue: itemData.winrateType,
          isApproved: true,
        },
        {
          fieldName: "media#VIDEO",
          fieldValue: videoGrandPrize.src,
          isApproved: true,
        },
        {
          fieldName: "media#COVER_PHOTO",
          fieldValue: coverGrandPrize.src,
          isApproved: true,
        },
        {
          fieldName: "media#REGULAR1",
          fieldValue: firstRegularGrandPrize.src,
          isApproved: true,
        },
        {
          fieldName: "media#REGULAR2",
          fieldValue: secondRegularGrandPrize.src,
          isApproved: true,
        },
        {
          fieldName: "media#REGULAR3",
          fieldValue: thridRegularGrandPrize.src,
          isApproved: true,
        },
        {
          fieldName: "media#REGULAR4",
          fieldValue: fourthRegularGrandPrize.src,
          isApproved: true,
        },
        {
          fieldName: "media#REGULAR5",
          fieldValue: fifthRegularGrandPrize.src,
          isApproved: true,
        },
        {
          fieldName: "media#REGULAR6",
          fieldValue: sixthRegularGrandPrize.src,
          isApproved: true,
        },
        {
          fieldName: "media#REGULAR7",
          fieldValue: seventhRegularGrandPrize.src,
          isApproved: true,
        },
        {
          fieldName: "media#REGULAR8",
          fieldValue: eighthRegularGrandPrize.src,
          isApproved: true,
        },
        {
          fieldName: "media#VIDEO#description",
          fieldValue: videoGrandPrize.description,
          isApproved: true,
        },
        {
          fieldName: "media#COVER_PHOTO#description",
          fieldValue: coverGrandPrize.description,
          isApproved: true,
        },
        {
          fieldName: "media#REGULAR1#description",
          fieldValue: firstRegularGrandPrize.description,
          isApproved: true,
        },
        {
          fieldName: "media#REGULAR2#description",
          fieldValue: secondRegularGrandPrize.description,
          isApproved: true,
        },
        {
          fieldName: "media#REGULAR3#description",
          fieldValue: thridRegularGrandPrize.description,
          isApproved: true,
        },
        {
          fieldName: "media#REGULAR4#description",
          fieldValue: fourthRegularGrandPrize.description,
          isApproved: true,
        },
        {
          fieldName: "media#REGULAR5#description",
          fieldValue: fifthRegularGrandPrize.description,
          isApproved: true,
        },
        {
          fieldName: "media#REGULAR6#description",
          fieldValue: sixthRegularGrandPrize.description,
          isApproved: true,
        },
        {
          fieldName: "media#REGULAR7#description",
          fieldValue: seventhRegularGrandPrize.description,
          isApproved: true,
        },
        {
          fieldName: "media#REGULAR8#description",
          fieldValue: eighthRegularGrandPrize.description,
          isApproved: true,
        },
      ],
    };

    const toastPromise = toast.promise(
      applyChanges({
        idRetailer: dataItem.retailerId,
        idPrize: dataItem.prizeId,
        data,
      }),
      {
        pending: "Approving Grand Prize",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while approving grand prize</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Grand Prize Approved");
          setNewDataCheckbox([]);
          refetch();
        }
      })
      .catch((err) => {
        toast.error("Error while approving grand prize");
      });
  };

  const handleDecline = async () => {
    dispatch(setType("declineModal"));
    dispatch(setOpenModal(true));
  };

  const handleRenderButton = () => {
    return (
      <div className="flex">
        {dataItem?.state !== "PENDING_REVIEW" ? (
          dataItem?.state === "ARCHIVED" ? (
            // @ts-ignore
            routes[user.data.role].urlAllowed?.item?.includes("recover") && (
              <Button
                variant="recovery"
                onClick={() => {
                  dispatch(setType("recoverItem"));
                  dispatch(setOpenModal(true));
                }}
              >
                <Recovery />
                <p className="ml-2">Recovery</p>
              </Button>
            )
          ) : (
            // @ts-ignore
            routes[user.data.role].urlAllowed?.gp?.includes("archive") && (
              <Button
                variant="danger"
                onClick={() => {
                  dispatch(setType("archiveItem"));
                  dispatch(setOpenModal(true));
                }}
              >
                <Archive />
                <p className="ml-2">Archive Item</p>
              </Button>
            )
          )
        ) : (
          <></>
        )}
        {dataItem?.state === "NEW" ||
        (dataItem?.state === "PENDING_REVIEW" &&
          (dataItem?.hasOwnProperty("newData") ||
            dataItem?.medias?.some(
              (media: any) =>
                media.hasOwnProperty("newName") ||
                media.hasOwnProperty("newDescription")
            ))) ? (
          btnApprove ? ( // @ts-ignore
            routes[user.data.role].urlAllowed?.gp?.includes("decline") && (
              <Button variant="recovery" onClick={() => handleApprove()}>
                <p>Approve & Publish</p>
              </Button>
            )
          ) : (
            // @ts-ignore
            routes[user.data.role].urlAllowed?.gp?.includes("decline") && (
              <Button variant="danger" onClick={() => handleDecline()}>
                <p>Decline</p>
              </Button>
            )
          )
        ) : (
          <></>
        )}
        {dataItem?.state === "ARCHIVED" ||
        dataItem?.hasOwnProperty("newData") ||
        dataItem?.medias?.some(
          (media: any) =>
            media.hasOwnProperty("newName") ||
            media.hasOwnProperty("newDescription")
        )
          ? null
          : // @ts-ignore
            routes[user.data.role].urlAllowed?.gp?.includes("save") && (
              <Button variant="normal" onClick={() => handleUpdateItem()}>
                <Save />
                <p className="ml-2">Save</p>
              </Button>
            )}
        {dataItem?.state === "ARCHIVED" ||
        dataItem?.hasOwnProperty("newData") ||
        dataItem?.medias?.some(
          (media: any) =>
            media.hasOwnProperty("newName") ||
            media.hasOwnProperty("newDescription")
        )
          ? null
          : // @ts-ignore
            routes[user.data.role].urlAllowed?.gp?.includes("publish") && (
              <Button variant="add" onClick={() => handlePublishItem()}>
                <Publish />
                <p className="ml-2">Publish</p>
              </Button>
            )}
      </div>
    );
  };

  const handleRecoverItem = async (
    idItem: string | undefined,
    idMerchant: string
  ) => {
    let toastPromise;

    if (!idItem) return;

    toastPromise = toast.promise(
      updateStatusMutation({
        idItem,
        idMerchant,
        status: "PENDING_REVIEW",
      }),
      {
        pending: "Recovering Item",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while recovering item</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Item Recovered");
          setTimeout(() => {
            dispatch(setType(""));
            dispatch(setOpenModal(false));
          }, 2000);

          dispatch(merchantsApi.util.invalidateTags(["MerchantGrandPrize"]));
          dispatch(itemApi.util.invalidateTags(["IndividualGrandPrize"]));
        }
      })
      .catch((err) => {
        toast.error("Error while recovering item");
      });
  };

  const handleChangeBtnAllChecked = (value: boolean) => {
    if (value) {
      setBtnApprove(true);
    } else {
      setBtnApprove(false);
    }
  };

  return (
    <section className="mt-3">
      <div className="mb-5">
        <Link
          to={".."}
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          className="text-sm text-gray-400 flex hover:text-primary-purple max-w-max"
        >
          <ArrowBack classes="h-5 w-5 mr-2" />
          Back to item list
        </Link>
      </div>
      <div className="flex justify-between">
        <Title classes="flex items-center">
          {dataItem?.newData && <Alert classes="mr-3" />}{" "}
          {dataItem?.newData?.name || dataItem?.name}
        </Title>

        {handleRenderButton()}
      </div>
      <div className="mt-5">
        <Tabs
          tabsName={["Details", "Stats", "Merchants"]}
          setSelectedTab={handleSelectedTab}
        >
          <Details
            itemInfo={dataItem}
            isLoadingItem={isLoadingItem}
            itemData={itemData}
            setItemData={setItemData}
            itemsTypeRedemption={itemsTypeRedemption}
            setItemsTypeRedemption={setItemsTypeRedemption}
            itemsTypeShipping={itemsTypeShipping}
            setItemsTypeShipping={setItemsTypeShipping}
            objectImages={objectImages}
            setObjectImages={setObjectImages}
            inputError={inputError}
            setReasonsInput={setReasonsInput}
            imageCropModalType={imageCropModalType}
            setImageCropModalType={setImageCropModalType}
            handleUploadPhoto={handleUploadPhoto}
            coverGrandPrize={coverGrandPrize}
            setCoverGrandPrize={setCoverGrandPrize}
            firstRegularGrandPrize={firstRegularGrandPrize}
            setFirstRegularGrandPrize={setFirstRegularGrandPrize}
            secondRegularGrandPrize={secondRegularGrandPrize}
            setSecondRegularGrandPrize={setSecondRegularGrandPrize}
            thridRegularGrandPrize={thridRegularGrandPrize}
            setThirdRegularGrandPrize={setThirdRegularGrandPrize}
            fourthRegularGrandPrize={fourthRegularGrandPrize}
            setFourthRegularGrandPrize={setFourthRegularGrandPrize}
            fifthRegularGrandPrize={fifthRegularGrandPrize}
            setFifthRegularGrandPrize={setFifthRegularGrandPrize}
            sixthRegularGrandPrize={sixthRegularGrandPrize}
            setSixthRegularGrandPrize={setSixthRegularGrandPrize}
            seventhRegularGrandPrize={seventhRegularGrandPrize}
            setSeventhRegularGrandPrize={setSeventhRegularGrandPrize}
            eighthRegularGrandPrize={eighthRegularGrandPrize}
            setEighthRegularGrandPrize={setEighthRegularGrandPrize}
            videoGrandPrize={videoGrandPrize}
            setVideoGrandPrize={setVideoGrandPrize}
            handleChangeBtnAllChecked={handleChangeBtnAllChecked}
            setDeclinedItems={setDeclinedItems}
            setApprovedItems={setApprovedItems}
            newDataCheckbox={newDataCheckbox}
            setNewDataCheckbox={setNewDataCheckbox}
          />
          <Stats />
          <Merchant id={dataItem?.retailerId} />
        </Tabs>
      </div>
      {(isLoadingItem || isErrorItem) && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4 text-center">
            {isErrorItem ? (
              <div className="flex flex-col w-full justify-center items-center mt-5 px-4">
                <p className="">
                  An error occurred while trying to retrieve the item
                </p>
                <Button onClick={refetch} classes="font-semibold">
                  Try again
                </Button>
              </div>
            ) : (
              <p>Loading Item...</p>
            )}
          </div>
        </Modal>
      )}
      {modal.type === "showImage" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded items-center">
            <Dialog.Overlay />
            <img
              src={modal?.imageModal}
              alt={`modal-${modal?.imageModal}`}
              className="image-modal-height"
            />
          </div>
        </Modal>
      )}
      {modal.type === "recoverItem" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <>
              <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
                <div className="rounded-full h-20 w-20 bg-green-100 flex items-center justify-center mt-5">
                  <Recovery classes="text-green-500 h-10 w-10" />
                </div>
                <h1 className="text-green-500 mt-4">Recover</h1>
                <p className="text-sm mt-4 text-center">
                  Are you sure you want to recover <b>{dataItem.name}</b> ?
                </p>
              </div>
              <hr />
              <div className="mt-8 px-6 flex justify-between items-center">
                <Button
                  variant="normal"
                  onClick={() => {
                    dispatch(setType(""));
                    dispatch(setOpenModal(false));
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="recovery"
                  onClick={() => {
                    handleRecoverItem(id, dataItem?.retailerId);
                  }}
                >
                  Recover
                </Button>
              </div>
            </>
          </div>
        </Modal>
      )}
      {modal.type === "declineModal" && (
        <Modal fnClean={() => handleCloseModal()}>
          <div className="flex flex-col bg-white text-black rounded w-96 pb-6 pt-3">
            <div className="flex justify-center items-center flex-col">
              <div className="flex justify-center items-start flex-col mb-8 px-5">
                <Dialog.Title className=" mb-4 text-black text-xl font-medium">
                  Provide decline reason
                </Dialog.Title>
                <Select
                  error={!reason && inputError ? true : false}
                  label="Provide reason"
                  classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 text-black w-full"
                  // @ts-ignore
                  onChange={(e: any) => setReason(e.target.value)}
                >
                  {isLoadingDeclineReasons && !isErrorDeclineReasons && (
                    <option value="" disabled>
                      Loading Decline Reasons
                    </option>
                  )}
                  {!isLoadingDeclineReasons &&
                    !isErrorDeclineReasons &&
                    declineReasons && (
                      <>
                        <option disabled selected>
                          Choose reason of decline
                        </option>
                        {declineReasons.map(
                          (reason: {description: string; id: number}) => (
                            <option
                              value={reason.id.toString()}
                              key={reason.id}
                            >
                              {reason.description}
                            </option>
                          )
                        )}
                      </>
                    )}
                </Select>
                {declinedItems.length > 0 && (
                  <div className="mt-5 w-full">
                    <Label>Problem Inputs</Label>
                    <div className="flex flex-wrap border border-gray-400 rounded p-2">
                      {declinedItems.map((option, key) => (
                        <div
                          key={key}
                          className="bg-fourth-purple mx-1 my-1 rounded pl-3 pr-4 py-1 text-black relative cursor-default w-max text-sm capitalize"
                        >
                          {handleFormatElement(option)}
                          <span
                            id={option}
                            // @ts-ignore
                            onClick={(e) => handleRemoveElement(e.target.id)}
                            className="text-xs text-gray-400 pr-1 ml-2 absolute top-0 right-0 cursor-pointer"
                          >
                            x
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <Textarea
                  label="Description"
                  placeholder="Enter a description"
                  error={!reasonDescription && inputError ? true : false}
                  classes="mt-5 w-full"
                  required={true}
                  value={reasonDescription}
                  onChange={(e: any) => {
                    setReasonDescription(e.target.value);
                  }}
                />
              </div>
              <hr className="w-full" />
              <div className="flex justify-between items-center w-full px-5 mt-3">
                <Button
                  onClick={() => handleCloseModal()}
                  variant="normal"
                  classes="text-center"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => handleSendDeclineReason()}
                  variant="add"
                  classes="text-center"
                >
                  Sent
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {modal.type === "archiveItem" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <>
              <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
                <div className="rounded-full h-20 w-20 bg-red-100 flex items-center justify-center mt-5">
                  <Archive classes="text-red-500 h-10 w-10" />
                </div>
                <h1 className="text-red-500 mt-4">Archive</h1>
                <p className="text-sm mt-4 text-center">
                  Are you sure you want to archive <b>{dataItem.name}</b> ?
                </p>
              </div>
              <hr />
              <div className="mt-8 px-6 flex justify-between items-center">
                <Button
                  variant="normal"
                  onClick={() => {
                    dispatch(setType(""));
                    dispatch(setOpenModal(false));
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    changeStatusItem({
                      idItem: id,
                      idMerchant: dataItem?.retailerId,
                      status: "PAUSED",
                    });
                  }}
                >
                  Archive
                </Button>
              </div>
            </>
          </div>
        </Modal>
      )}
    </section>
  );
}

export default GrandPrizeDetails;
